<div class="footerwrapper">
	<div class="">
		<div [ngClass] = "((isLandingPage || isLoginPage || isRegisterPage || isForgotPage || isContactUsPage || isSubscribePage)?'footerImage':'footerImage footerException')">
			<div class="footerContent" *ngIf="isLandingPage || isLoginPage || isRegisterPage || isForgotPage || isContactUsPage || isSubscribePage">
				<a routerLink="/contact" tabindex="0">Contact us</a>     <span>|</span>   
				<a href="https://vimeo.com/663284411" target="_blank" tabindex="0">About us</a>    <span>|</span>  
				<a href="https://vimeo.com/895987001" target="_blank" tabindex="0">Tutorial Video</a></div>
			<p><span tabindex="0">Live Solutions, LLC &copy; 2024</span></p><!--&#64;-->
			<img src="assets/logo-white.png" alt="Livepad footer logo-white" tabindex="0" width="191" class="footer-logo"/>
		</div>
		<div class="">
		</div>
	</div>
</div>

