import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { LoginService } from './login.service';
import { Role } from '../user';

@Injectable({
  providedIn: 'root'
})
export class AdminAuthGuard implements CanLoad,CanActivate {
  constructor(
    public loginService: LoginService,
    public router: Router,
    private route: ActivatedRoute // private toastrServe:ToastrService,
  ) {}
  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      if (this.loginService.isUserRole) {
        if(this.loginService.isUserRole == Role.Admin || this.loginService.isUserRole == Role.SuperAdmin){
          return of(true);
        }else{
          this.router.navigate(['/dashboard']);
        }
      } else {
        this.router.navigate(['/login']);
        return of(false);
      }
  }
  

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      if (this.loginService.isUserRole) {
        if(this.loginService.isUserRole == Role.Admin || this.loginService.isUserRole == Role.SuperAdmin){
          return of(true);
        }else{
          this.router.navigate(['/dashboard']);
        }
      } else {
        this.router.navigate(['/login']);
        return of(false);
      }
  }
  
}
