import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[licenseNumber]'
})
export class licenseNumberDirective {
  
  private regex: RegExp = /^[a-zA-Z0-9 !"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]*$/; // Allow alphabets, numbers, and a variety of special characters

  constructor(private elementRef: ElementRef) {}

  @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
    const inputValue: string = this.elementRef.nativeElement.value.concat(event.key);
    if (inputValue && !String(inputValue).match(this.regex)) {
      event.preventDefault();
    }
  }

  @HostListener('paste', ['$event']) onPaste(event) {
    const clipboardData = (event.originalEvent || event).clipboardData.getData('text/plain');
    if (clipboardData) {
      if (!this.regex.test(clipboardData)) {
        event.preventDefault();
      }
    }
  }

  @HostListener('ngModelChange', ['$event']) onInputChange(event) {
    if (event) {
      const inputVal = event.replace(/[^a-zA-Z0-9 !"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/g, '').substring(0, 20);
      this.elementRef.nativeElement.value = inputVal;
    }
  }
}