import { Injectable, EventEmitter } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';


import { LoginService } from './login.service'
import { environment } from '../../environments/environment'
import { HttpClient } from '@angular/common/http';
import { ServiceErrors } from './service-errors'
import { Authorization } from '../authorization'

@Injectable({
  providedIn: 'root'
})
export class MlsService {

	authorization: Authorization

	constructor(private loginService: LoginService, private http: HttpClient) { 
		this.checkAuth()
	}

	checkAuth () {
		this.authorization = this.loginService.getAuthorization()
		if ( !this.authorization?.token ) { console.error("No auth token present") }
	}

	/**
 	 Fetch the list of MLS organizations that are under contract with us
	 This point is public. 
	 TODO: Secure
	*/
	fetchMlsList () {
		this.checkAuth();

		let query = `query { contractedMlsList { id mlsName } }`
	  	let body = { "query" : query } 
		let options = {
			headers: { Authorization: `Bearer ${this.authorization?.token}`},
		};

	  	return this.http
	  		.post(environment.graphApi, body,options)
	  		.pipe(
	  			catchError(ServiceErrors.handleError)

	  		)
	}
	fetchMlsListSync ():Promise<any> {
		return this.fetchMlsList().toPromise()
	}

	/**
 	 Fetch the list of searchable dropdowns by MLS. Each MLS has their own list. 
 	 This point is public. 
	 TODO: Secure
	*/
	fetchMlsDropdowns (mlsName) {
		this.checkAuth();

		let query = `query { mlsDropdowns(mlsName: "${mlsName}" ) { display searchKey showInHeaderSearch values { display searchValue } } }`
	  	let body = { "query" : query } 

		let options = {
			headers: { Authorization: `Bearer ${this.authorization?.token}` },
		};

	  	return this.http
	  		.post(environment.graphApi, body,options)
	  		.pipe(
	  			catchError(ServiceErrors.handleError)

	  		)
	}

}
