import { Mls } from './mls'
import { Address } from './address'
import { Agent } from './agent'

export interface User {
  id: number;
  name: string;
  email: string;
  password: string;
  agent: Agent;
  status?:number;
  usermls?:Usermls[];
  agentorglist:orgUserdetails[];
  // deactivated?:boolean|number;
  orguserdetails?:orgUserdetails[];
  checked?:Boolean;//for checkbox selection
  selectedMls: any[];
}
export interface orgUserdetails {
	roleId: number;
	orgId: number;
  organizations: Organization[]
}
export interface Usermls {
	id: number;
	mlsName: string;
}
export enum Role {
  SuperAdmin = 1,
  Admin = 2,
  Agent = 3
}

export interface Organization {
  orgmls: any;
	status: number;
	id: number;
	name: String; 
	NoOflicenses: number;
	NoOfadmins: number;
}

export interface UserRole {
	id: number;
	roleName: string;
}