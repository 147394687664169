import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { MatListModule } from '@angular/material/list';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';

import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Property } from '../propertySearch'
import { bathroomCalculation, addCommasToPrice, firstOf } from '../utils'
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-property-card',
  templateUrl: './property-card.component.html',
  styleUrls: ['./property-card.component.css']
})
export class PropertyCardComponent implements OnInit {

	environment = environment;

	@Input()
	property: Property

	// Expose to scope
	bathroomCalculation = bathroomCalculation
	addCommasToPrice = addCommasToPrice
	firstOf = firstOf

	constructor( 
		private router: Router) { 
	}

	ngOnInit(): void {
	}

	/**
 	 Open a new tab for this property if click on from here.
	*/
	viewProperty() {

		const propertyUrl = this.router.serializeUrl(
			this.router.createUrlTree([`/livepad/property/${this.property.listingKey}`])
		)

		window.open(propertyUrl, '_blank')
	}

	firstImage() {
		firstOf(this.property.propertyPhotos)?.url
	}
}
