import { Component, HostListener, OnInit, OnDestroy, EventEmitter, Output, Input } from '@angular/core';
// import { MatListModule } from '@angular/material/list';
// import { MatFormFieldModule } from '@angular/material/form-field';
// import { MatInputModule } from '@angular/material/input';
// import { MatCardModule } from '@angular/material/card';
// import { MatIconModule } from '@angular/material/icon';
// import { MatButtonModule } from '@angular/material/button';
// import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { ToursService } from '../services/tours.service'
import { ConfirmComponent } from '../confirm/confirm.component'
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { Router, ActivatedRoute, ParamMap } from '@angular/router';
// import { Property } from '../propertySearch'
import { Showing, RequestActions } from '../showing'
import { Tour } from '../tour'

import { bathroomCalculation, addCommasToPrice, showingRequestStatus, capitalized, dateFromEpochTime, epochTimeFromDate, firstOf, formatDateT } from '../utils'
// import { GoogleAnalyticsService } from '@app/services/google-analytics.service';
import * as moment from 'moment-timezone';


@Component({
  selector: 'app-showing-card',
  templateUrl: './showing-card.component.html',
  styleUrls: ['./showing-card.component.css']
})
export class ShowingCardComponent implements OnInit {
	isMobile: boolean;

	error: string

	@Input() propertyIndex: number;

	@Input()
	showing: Showing	

	@Input()
	tour: Tour

	datePickerValue: string;

	@Input()
	requestActions: RequestActions[]

	@Output()
	onDelete: EventEmitter<any> = new EventEmitter<any>()

	@Output()
	onRemoveClient: EventEmitter<any> = new EventEmitter<any>()

	@Output()
	onUpdateTime:  EventEmitter<any> = new EventEmitter<any>()

	@Output()
	onRequestShowing:  EventEmitter<any> = new EventEmitter<any>()

	// Add to scope
	capitalized = capitalized
	firstOf = firstOf

	constructor(
		private router: Router,
		// private route: ActivatedRoute,
		// private toursService: ToursService,
		private dialog: MatDialog,
		private toursService: ToursService,
		private snackBar: MatSnackBar ,
		// private gaService: GoogleAnalyticsService
	) { 
			this.isMobile = window.innerWidth < 768;
		}

	ngOnInit(): void {
		this.onResize();
	}
	@HostListener('window:resize', ['$event'])
	onResize(event?: Event): void {
	  this.isMobile = window.innerWidth < 768;
	}
  

	ngOnDestroy(): void {

	}

	deleteShowing(showing, tour) {
		// this.gaService.sendCtaClickEvent({page_name:`tours`},{cta_type: 'icon', cta_location: 'view_tour_details', cta_description:'delete',tour_id:tour?.id, property_id:showing?.property.listingKey});//cta 51
		this.onDelete?.emit( {showing, tour} )
	}

	onWrapperClicked(showing: any,tour) {
		if (showing.showingTime == null) {
		  this.openSnackBar('Please select the time before requesting.');
		} else {
		  // Handle the button click when it's not disabled
		  // Add your logic here
		  this.onRequested(showing,tour);
		}
	}
	  
	openSnackBar(message: string) {
		this.snackBar.open(message, 'Close', {
			duration: 3000, // Duration in milliseconds (3 seconds in this example)
			verticalPosition: 'top', // Change the vertical position if desired
			// Add any additional configuration options for the snackbar
		});
	}

	onRequested(showing,tour) {
		console.log("tour id is",tour.id);
		// this.gaService.sendCtaClickEvent({page_name:`tours`},{cta_type: 'icon', cta_location: 'view_tour_details', cta_description:'request_time?', tour_id:tour?.id, property_id:showing?.property.listingKey});//cta 50
		const latitude =showing?.property?.address?.latitude;
        const longitude =showing?.property?.address?.longitude;

		this.onRequestShowing?.emit(showing)

		// if ( latitude && longitude){
		// 	this.toursService
		// 	.fetchTimeZone(latitude,longitude)
		// 	.subscribe( response => {
		// 		let timeZone = response['data']['timeZone']
		// 		const timeZoneTime = moment().tz(timeZone).format('MM-DD-YYYY HH:mm:ss');
		// 		const currentTime = moment(showing.showingTime).format('MM-DD-YYYY HH:mm:ss');

		// 		if (timeZoneTime > currentTime) {
		// 				console.log("says hellow");
		// 				this.errorTimePassed(`Selected time does not match with the property timezone. please select other time.`);
		// 				return;
		// 		}
		// 		else{
		// 			this.onRequestShowing?.emit(showing)
		// 		}
				
		// 	})
		// }
		// else {
			// this.onRequestShowing?.emit(showing)
		// }
	}

	viewTourSummary(tour) {

		this.router.navigate([`tour-summary/${tour.id}`])

	}

	private showingTimeTimers = []

	onUpdateShowingTime(showing, tour, event) {
		console.log("tour and showing",tour,showing);
		this.datePickerValue = `${event.value}`;

		if(event.value !='') {

			// Validation check
			// let time = event.target.value.split(':')
			let time = new Date(this.datePickerValue)
			// this.gaService.sendCtaClickEvent({page_name:`tours`},{cta_type: 'icon', cta_location:'view_tour_details', cta_description:'clock_icon', selection_option:time.toString(), property_id:showing?.property?.listingKey, tour_id:tour?.id});//cta 49
			// .toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
			// let time = new Date(this.datePickerValue).toString().slice(16,24).split(':')

			let showingDateTime = dateFromEpochTime(tour.tourDate)
			showingDateTime.setHours(time.getHours())
			showingDateTime.setMinutes(time.getMinutes())
			showingDateTime.setSeconds(0)
			
			const tourData = formatDateT(tour.tourDate).split('T')[0];
			const currentDate = formatDateT(new Date()).split('T')[0];
			if(`${tourData}` < `${currentDate}`){
				this.datePickerValue='';
				showing.showingTime='';
				this.errorTimePassed(`Please choose a date in the future`);
				return;
			}
			if ( Number(showingDateTime) < Number(new Date()) ) {
				this.errorTimePassed("Please choose a time in the future")
				return 
			}
			else {
				const latitude =showing?.property?.address?.latitude;
				const longitude =showing?.property?.address?.longitude;

				if ( latitude && longitude){
					this.toursService
					.fetchTimeZone(latitude,longitude)
					.subscribe( response => {
						let timeZone = response['data']['timeZone']
						console.log("showingDateTime ------------",showingDateTime);
						console.log("showing time",showing.showingTime);
						const timeZoneTime = moment().tz(timeZone).format('MM-DD-YYYY HH:mm:ss');
						let currentTime;
						if(showing.showingTime){
							currentTime = moment(showing.showingTime).format('MM-DD-YYYY HH:mm:ss');
						}
						else{
							currentTime = moment(showingDateTime).format('MM-DD-YYYY HH:mm:ss');
						}

						if (timeZoneTime > currentTime) {
								console.log("says hellow");
								this.errorTimePassed(`Selected time is in the past. Please select a time in the future`);
								return;
						}
						else{
							// Received change event, now set a timeout for 1000ms.
							// If no other events happen in the next second, emit the event.
							// Otherwise it will be cancelled and we'll wait for the next event.
							if ( this.showingTimeTimers.length == 0 ) {

								this.showingTimeTimers.push(setTimeout( (showing, tour, event) => {

									this.onUpdateTime?.emit({ showing, tour, event })

									while ( this.showingTimeTimers.length > 0 ) {
										clearTimeout(this.showingTimeTimers.shift())
									}

									return

								}, 1000, showing, tour, event))

							// Wait for next change event
							} else {

								while ( this.showingTimeTimers.length > 0 ) {
									clearTimeout(this.showingTimeTimers.shift())
								}

							}
						}
						
					})
				} else {
					// Received change event, now set a timeout for 1000ms.
					// If no other events happen in the next second, emit the event.
					// Otherwise it will be cancelled and we'll wait for the next event.
					if ( this.showingTimeTimers.length == 0 ) {

						this.showingTimeTimers.push(setTimeout( (showing, tour, event) => {

							this.onUpdateTime?.emit({ showing, tour, event })

							while ( this.showingTimeTimers.length > 0 ) {
								clearTimeout(this.showingTimeTimers.shift())
							}

							return

						}, 1000, showing, tour, event))

					// Wait for next change event
					} else {

						while ( this.showingTimeTimers.length > 0 ) {
							clearTimeout(this.showingTimeTimers.shift())
						}

					}
				}
			}
		}

	}

	/**
 	 Return the latest confirmation state of this showing.
	*/
	// confirmedStyle(showing): any {

	// 	return showingRequestStatus(showing).style
	// }
	confirmedStyle(showing, datePickerValue: string): any {
		if (datePickerValue == ''){
			showing.showingTime = null;
			return showingRequestStatus(showing).style
		}
		return showingRequestStatus(showing).style
	}

	/**
	 Turn a single line of address into a double-lined address
	*/
	addressStreet(address:string) {
		return address.split(',')[0]
	}

	addressCity(address:string) {
		return address.split(',').slice(1).join(',')
	}


	errorTimePassed (message) {

	    const dialogRef = this.dialog.open(ConfirmComponent, {
			data: {
		        message: `${message}`,
		        buttonText: {
					ok: "OKAY"
		        },
		        list: []
			}
	    });

	    return dialogRef.afterClosed()
	}
	onImgClickEvent(event:Event,propertyId:string,tour){
		event.stopPropagation();
		// this.gaService.sendCtaClickEvent({page_name:`tours`},{cta_type: 'image', cta_location:'view_tour_details', cta_description:'property_image', property_id:propertyId, tour_id:tour?.id});//cta 48
	}
}
