import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';

import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

import { Showing, RequestActions } from '../showing'
import { Tour } from '../tour'
import { Property } from '../propertySearch'
import { Client } from '../client'
import { bathroomCalculation, addCommasToPrice, firstOf } from '../utils'


@Component({
  selector: 'app-tour-summary-card',
  templateUrl: './tour-summary-card.component.html',
  styleUrls: ['./tour-summary-card.component.css']
})
export class TourSummaryCardComponent implements OnInit {

  @Input()
  property: Property

  @Input()
  client: Client

  @Input()
  showing: Showing

  error: string = null

  // Expose to scope
  bathroomCalculation = bathroomCalculation
  addCommasToPrice = addCommasToPrice

  constructor(    
    private router: Router,
    private route: ActivatedRoute) { }

  ngOnInit(): void {
  }

  /**
   Return boolean if this property has been favorited by this client
  */
  isFavorite () {
    return firstOf(this.property.clientFavorites)?.favoriteStatus == 1
  }

  /**
   Fetch the top two comments
  */
  comments () {

    if (this.property.clientComments?.length < 1) {
      return ["No comments"]
    }

    return this.property.clientComments?.map( each => { return each.comments }).slice(0,3)
  }

  /**
   Fetch the top two client created media items
  */
  clientMedia () {
    return this.property.clientPhotos?.slice(0,3)
  }

    /**
   Show a star icon if there is a client attached to this property and
   fill them in. The icon name `star` also matches a css class for coloring.
  */
  getStarStatus(starIndex) {

    let lastRating = firstOf(this.property.propertyRatings)?.starRating ?? 0
    return Number(lastRating) >= starIndex ? "star" : "star_outline"    

  }

  favoriteStatus( ) {

    if ( firstOf(this.property.clientFavorites)?.favoriteStatus == 1 ) { return "favorite" }
    return "favorite_border"

  }

  favoriteLink() {
    return `./property/${this.property.listingKey}/client/${this.client.id}`
  }

  viewProperty() {

    this.router.navigate([`/property/${this.property.listingKey}/client/${this.client.id}`])
  }

}
