import { Component, OnInit, OnDestroy, Inject, Injectable } from '@angular/core';
import { FormControl, Validators, ReactiveFormsModule } from '@angular/forms';

import { LoginService } from '../services/login.service'
import { Router, Route, ActivatedRoute, ParamMap } from '@angular/router';
import { Authorization } from '../authorization'

@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.css']
})
export class BaseComponent implements OnInit {

  // Injected members via subclassed constructors
  protected loginService: LoginService
  protected router: Router
  protected route: ActivatedRoute

  constructor(
    loginService: LoginService,
    router: Router,
    route: ActivatedRoute) { 

    this.loginService = loginService
    this.router = router
    this.route = route

    if ( !this.loginService.getAuthorization()?.token ) {
      this.router.navigate(['/login'])
    }
  }

  ngOnInit(): void {

  }

}
