// auth.guard.ts
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
// import { LoginService } from './login.service';
import { LocalStorage } from '../localStorage'
import { Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class LoginAuthGuard implements CanActivate {

  constructor( private router: Router,private titleService: Title) {}

  canActivate(route: ActivatedRouteSnapshot,state: RouterStateSnapshot): boolean {
    if (Date.now()<LocalStorage.get('expired')) {
      // User is already authenticated, prevent access to the login page
      this.titleService.setTitle(LocalStorage.get('token')?'dashboard':state.url.split('/')[1]);
      this.router.navigate(['/dashboard']); // Redirect to another route
      return false;
    }
    if(!LocalStorage.get('token'))this.titleService.setTitle('login');
    return true; // Allow access to the login page
  }
}