<div class="content-container">
  <div style="position: relative;">
    <carousel [(activeSlide)]="selectedIndex" class="slider" [showIndicators]="false" id="carouselCaptions2">
      <slide *ngFor="let image of images">
        <img  [src]="image" [alt]="'Properties Image'+i" data-path="test">
      </slide>
    </carousel>
    <div class="carousel-indicators">
      <ng-container *ngFor="let image of images;let i=index">
        <span class="dot" [class.active]="i===selectedIndex" type="button" data-bs-target="#carouselCaptions2"
          [data-bs-slide-to]="i" [aria-current]="i==selectedIndex?true:false" (click)="currentSlide(i)"><b
            class="adafix">.</b></span>
      </ng-container>
    </div>
  </div>
  <div>&nbsp;</div>
  <div *ngIf="isVideo" style="text-align: center;">
    <span *ngFor="let video of videos">
      <video width = 900 controls>
        <source [src]="video" [type]="'video/mp4'">
      </video>&nbsp;
    </span>
    </div>  
  </div>