<mat-list>
	<mat-list-item>
		<button class="sidenav-item" aria-label="show dashboard" mat-button (click)="onDashboard()">
			<mat-icon class="sidenav-item-icon" aria-hidden="false" aria-label="show dashboard">space_dashboard</mat-icon>
			Dashboard
		</button>
	</mat-list-item>
	<mat-list-item>
		<button class="sidenav-item" aria-label="show tours" mat-button (click)="onTours()">
			<mat-icon class="sidenav-item-icon" aria-hidden="false" aria-label="show tours">directions_run</mat-icon>
			Tours
		</button>
	</mat-list-item>

	<mat-divider></mat-divider>
	<mat-list-item>
		<button class="sidenav-item" aria-label="show clients" mat-button (click)="onClients()">
			<mat-icon class="sidenav-item-icon" aria-hidden="false" aria-label="show clients">face</mat-icon>
			Clients
		</button>
	</mat-list-item>

	<mat-divider></mat-divider>

<!-- 
	<mat-list-item>
		<button class="sidenav-item" aria-label="show reports" mat-button (click)="onReports()">
			<mat-icon class="sidenav-item-icon" aria-hidden="false" aria-label="show reports">reports</mat-icon>
			Reports
		</button>
	</mat-list-item> -->

	<mat-divider></mat-divider>
    
    <mat-list-item>
	    <button class="sidenav-item" aria-label="show properties" mat-button (click)="onProperties()">
			<mat-icon class="sidenav-item-icon" aria-hidden="false" aria-label="show properties">add_business</mat-icon>
			Properties
	    </button>
	</mat-list-item>

	<mat-divider></mat-divider>

	<div class="spacer"></div>

    <mat-list-item>
	    <button class="sidenav-item" aria-label="sign out" mat-button (click)="onSignOut()">
			<mat-icon class="sidenav-item-icon" aria-hidden="false" aria-label="sign out">logout</mat-icon>
			Sign out
	    </button>
	</mat-list-item>

</mat-list>
