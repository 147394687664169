<!-- <div class="form-group" appearance="outline" autocomplete="off">
		<label class="mt-3" tabindex="0">Street Address</label>
		<input [disabled]="disabled" matInput placeholder="123 OverThere Rd." 
			type="text" name="userInput" [ngModel]="userInput" (keyup)="onChange($event)" autocomplete="off" class=" mt-3 inputLine form-control" id="streetAddress" aria-labelledby="streetAddress" style="background:transparent ;" tabindex="0">
</div>


<mat-card class="predicted-address-card" *ngIf="predictedPlaces.length > 0">
	<mat-card-content>
		<mat-selection-list class="places-autocomplete" [multiple]="false" (selectionChange)="onAddressSelection($event)">
		  <mat-list-option *ngFor="let place of predictedPlaces" [value]="place" class="predicted-place">
		  	{{place.description}}
		  	<mat-divider [inset]="true"></mat-divider>
		  </mat-list-option>
		</mat-selection-list>
	</mat-card-content>
</mat-card> -->
<div style="position:relative">
<div class="form-group" appearance="outline" autocomplete="off">
	<!-- <label tabindex="0">Street Address</label> -->
	<!-- (input)="onChange($event)" -->
	<input [disabled]="disabled" #addressInput
		type="text" name="userInput" [ngModel]="userInput"  autocomplete="off" class="form-control" id="streetAddress" aria-labelledby="streetAddress" tabindex="0" style="background:transparent;">
</div>

<mat-card class="predicted-address-card" *ngIf="predictedPlaces.length > 0 && suggestionListOpen">
<mat-card-content>
	<mat-selection-list class="places-autocomplete" [multiple]="false" (selectionChange)="onAddressSelection($event)"  role="list" style="overflow-y: auto;
    height:fit-content;max-height: calc(50vh - 100px);">
	  <mat-list-option style="height:auto"   role="listitem" *ngFor="let place of predictedPlaces;last as isLast" [value]="place" class="predicted-place">
		  {{place.place_name}}
		  <mat-divider [inset]="true" *ngIf="!isLast"></mat-divider>
	  </mat-list-option>
	</mat-selection-list>
</mat-card-content>
</mat-card>
</div>