<div class="content-container" style="padding-bottom: 30px;">
	<div class="title">
		<!-- <h1 class="floatText"><span tabindex="0">{{title}}</span></h1> -->

		<div class="small-buttons top-alignment" *ngIf="showSmallButtons">
			<a  class="editprofile icon-alignment" *ngIf="firstSmallIconName" (click)="onFirstButtonAction()" (keyup.enter)="onFirstButtonAction()" tabindex="0"
				><label>{{firstSmallIconName}}</label> <img src="./assets/add-icon.svg" alt="Plus Icon" class="add-icon">
			</a>
			<a  class="editprofile" *ngIf="secondSmallIconName" (click)="onSecondButtonAction()" tabindex="0"
				style="position: relative;">{{secondSmallIconName}}<i class="bi bi-plus plusicon"
					style="color:#0C4675;padding-left: 2px;"></i>
			</a>
		</div>
	</div>

<div class="title-bar">
  <h1 tabindex="0">{{title}}</h1>
  <!-- <div class="welcome-admin">Welcome | Agent Livepad Setup</div> -->
</div>

	<div *ngIf="!showSmallButtons" class="sub-title">
		<div align="center">
		  <!-- <div tabindex="0" class="normal-text">{{subtitle}}</div>
		  <div class="textBlue">
			  <a (click)="onFirstButtonAction()">{{firstSmallIconName}} <i class="bi bi-plus-square plusicon" ></i></a>
		  </div> -->
		  <div tabindex="0" [attr.class]="pageLabel=='client'?'text-wrap-md normal-text':'normal-text'" >{{subtitle}}</div>
		  	<div class="add-client-btn_wrapper">
	<a (click)="onFirstButtonAction()"  [attr.class]="pageLabel=='tours'?'add-tour-btn add-client-btn':'add-client-btn'">
  <!-- <img src="./assets/add-users-icon.png" width="44" height="44" alt="Add Client"> -->
  <img [src]="pageLabel=='tours'?'./assets/add-tour-icon.svg':'./assets/add-tour-icon.svg'" width="44" height="44" alt="Add Tours">
  <h2>{{firstSmallIconName}}</h2></a>
  </div>
		</div>
	</div>



	<div class="footer">
		<span class="left-align" *ngIf="error">{{error}}</span>
	</div>
</div>
