import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-membership-new',
  templateUrl: './membership-new.component.html',
  styleUrls: ['./membership-new.component.css'],
})
export class MembershipNewComponent {
  constructor(private router: Router) {}
  saveProfile() {
    this.router.navigate(['/saved-profile']);
  }
}
