import { Component, OnInit, Input } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { LoginService } from '../services/login.service';
import { User } from '../user';
import { FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { FormControl, Validators, ReactiveFormsModule } from '@angular/forms';
import { Address } from '../address'

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.css']
})
export class AddressComponent implements OnInit {

	@Input() address: Address = {
		fullAddress: null,
		streetNumber: null,
		streetName: null,
		unitNumber: null,
		zip: null,
		city: null,
		state: null,
		country: null
	}

	constructor() {
	}

	ngOnInit(): void {
	}

	camelToWords (camelCase) {
		var result = camelCase.replace( /([A-Z])/g, " $1" );
		return result.charAt(0).toUpperCase() + result.slice(1);
	}

	classForField ( field ) {
		if ( field == 'fullAddress' ) { return "full-address-bar" }
		return null
	}

	onEnter() {

	}

	getAddressKeys() {

		// if ( this.isCreating ) {
		// 	return []
		// }

		return [
			"streetNumber",
			"streetName",
			"unitNumber",
			"zip",
			"city",
			"state",
			"country"
		]
	}

}
