import { Component, OnInit, Input } from '@angular/core';
import { Agent } from '../agent'
import { firstOf } from '../utils'

@Component({
  selector: 'app-agent-card',
  templateUrl: './agent-card.component.html',
  styleUrls: ['./agent-card.component.css']
})
export class AgentCardComponent implements OnInit {

  @Input()
  agent: Agent

  constructor() { }

  ngOnInit(): void {
  }

  firstLicense() {
    return firstOf(this.agent.license)?.licenseNumber
  }

  agentAvatar() {
    return this.agent.picture?.url ?? './assets/avatar.jpg'
  }

}
