export class BasicObject {
	
	name: string;
	value: string|string[];

	constructor(name: string, value: string) {
		this.name = name;
		this.value = value;
	}
}

