import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class SeoService {
  current_slug!:string;
  constructor(private title: Title, private meta: Meta, @Inject(DOCUMENT) private dom: Document,private router:Router) {
    
  let currentUrl = this.router.url;
  let res_url = currentUrl.split("/");
  this.current_slug = res_url[1];
  // if(window.document.documentElement.lang && window.localStorage.getItem('lang')=='en'){
  //     this.title.setTitle('LivePad');
  // }
 }
 getBaseUrl(){
  return `${window.location.protocol}//${window.location.host}`;
 }
setMetaData(data:any){
  const pageTitle=capitalized(data.meta_name_title);
  this.title.setTitle(pageTitle);
  this.meta.updateTag({ name: 'Keywords', content: data.meta_name_keywords })
  this.meta.updateTag({ name: 'description', content: data.meta_name_description })
  this.meta.updateTag({ name: 'Author', content: data.meta_name_author })
  // { name: 'date', content: '2019-10-31', scheme: 'YYYY-MM-DD' },
}
setMetaPropertyData(data:any){
  // this.title.getTitle(data.meta_names_title);
  this.meta.updateTag({ property: 'og:title', content: data.meta_property_title })
  this.meta.updateTag({ property: 'og:site_name', content: data.meta_property_site_name })
  this.meta.updateTag({ property: 'og:description', content: data.meta_property_description })
  this.meta.updateTag({ property: 'og:type', content: data.meta_property_type })
  this.meta.updateTag({ property: 'og:image', content: data.meta_property_preview.data[0].attributes.url  ? this.getBaseUrl() +'/#/'+ data.meta_property_preview.data[0].attributes.url : '' });
  this.meta.updateTag({ property: 'og:url', content:  this.getBaseUrl() +'/#/'+this.current_slug });
  this.meta.updateTag({ property: 'og:local', content: localStorage.getItem('lang') == 'en' ? 'en_US':'en-US' });
  // { name: 'date', content: '2019-10-31', scheme: 'YYYY-MM-DD' },
}
updateTitle(title: string) {
   this.title.setTitle(title);
  // this.meta.updateTag({ name: 'Title', content: title });
}
updateAuthor(data: string){
  this.meta.updateTag({ name: 'Author', content: data })
}

updateOgUrl(url: string) {
  this.meta.updateTag({ name: 'og:url', content: url })
}

updateDescription(desc: string) {
  this.meta.updateTag({ name: 'description', content: desc })
}

setCanonicalURL(url?: string) {
  const canURL = url == undefined ? this.dom.URL : url;
  const link: HTMLLinkElement = this.dom.createElement('link');
  link.setAttribute('rel', 'canonical');
  this.dom.head.appendChild(link);
  link.setAttribute('href', canURL);
}
updateCanonicalUrl(url:string){
  const head = this.dom.getElementsByTagName('head')[0];
  let element = this.dom.querySelector(`link[rel='canonical']`) || null
  if (element==null) {
    element= this.dom.createElement('link') as HTMLLinkElement;
    head.appendChild(element);
  }
  element.setAttribute('rel','canonical')
  element.setAttribute('href',url);
}

UpdateHreflang( url: string){

  // let lang: any = localStorage.getItem('lang');
  const head = this.dom.getElementsByTagName('head')[0];
  let element = this.dom.querySelector(`link[rel='alternate']`) || null;
  
  if (element==null) {
    //  if(lang == 'en'){
      element= this.dom.createElement('link') as HTMLLinkElement;
    head.appendChild(element);
    element.setAttribute('rel','alternate')
    element.setAttribute('href',url);
    element.setAttribute('hreflang', 'en-US');
    // }
  }
}

}import { capitalized } from '@app/utils';

