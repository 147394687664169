function graphQlFormat ( obj ) {

	var retArray = []
	for ( var key in obj ) {

		let value = typeof(obj[key]) == "string" ? `"${obj[key]}"` : obj[key]
		if ( obj[key] instanceof Array ) {
			value = `[${value}]`
		}
		retArray.push(`${key}: ${value}`)
	}

	return retArray
}

export { graphQlFormat }
