<div class="container">
	
	<div class="image">
		<button (click)="previous()">
			<mat-icon prefix>chevron_left</mat-icon>
		</button>


		<img *ngIf="isImage( getActiveImage() )" [src]="getActiveImage().url " alt="Images">

		<video *ngIf="!isImage( getActiveImage() ) " controls >
			<source [src]="getActiveImage().url" type="video/{{ getActiveImage().type }}">
		</video>

		<button (click)="next()">
			<mat-icon suffix>chevron_right</mat-icon>
		</button>
	</div>
</div>