import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-big-ass-button',
  templateUrl: './big-ass-button.component.html',
  styleUrls: ['./big-ass-button.component.css']
})
export class BigAssButtonComponent implements OnInit {

	@Input()
	buttonTitle: string

	@Output()
	buttonAction: EventEmitter<any> = new EventEmitter<any>()

	@Input()
	buttonImage: string

	@Input()
	buttonStyle: any

	@Input()
	buttonImageStyle: any

	@Input()
	disabled: boolean

	constructor() { }

	ngOnInit(): void {
	}

	/**
 	 Apply disabled class if the input says it should be disabled
	*/
	disabledClass() {
		if ( this.disabled == true ) {
			return "disabled"
		}

		return ""
	}

	onClick(event) {

		if (this.buttonAction != null) {
			this.buttonAction.emit(event)
		}
	}

}
