import { Component } from '@angular/core';

@Component({
  selector: 'app-tour-history-new',
  templateUrl: './tour-history-new.component.html',
  styleUrls: ['./tour-history-new.component.css']
})
export class TourHistoryNewComponent {

}
