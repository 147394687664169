import { Component, OnInit } from '@angular/core';
import { LoginService } from '../services/login.service'
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { ToursService } from '../services/tours.service'


import { Tour } from '../tour'
import { Showing } from '../showing'
import { Property } from '../propertySearch'
import { ShowingConfirmationRequest } from '../showing'
import { BasicObject } from '../basicObject'
import { ShowingOrderUpdate } from '../showing'
import { unixTimestampToDate, isValidDate, formatTourDate, formatShowingDate, formatConfirmationTime } from '../utils'


@Component({
  selector: 'app-request-confirmation-list',
  templateUrl: './request-confirmation-list.component.html',
  styleUrls: ['./request-confirmation-list.component.css']
})
export class RequestConfirmationListComponent implements OnInit {

	/**
 	 The request
	*/
	showing: Showing

	/**
 	 Error if create client or other server request failed
	*/
	error: string = null

	constructor(
		private router: Router, 
		private loginService: LoginService, 
		private toursService: ToursService,
		private route: ActivatedRoute
		) { 

	}

	ngOnInit(): void {

		let showingId = this.route.snapshot.paramMap.get('showingId')


		// Get all the tours this agent has
		this.fetchShowingRequests(showingId)

	}

	/**
 	 Get this showing and all confirmation requests with it
	*/
	fetchShowingRequests(showingId) {

		this.toursService
			.fetchShowing( showingId )
			.subscribe(response => { 
	  
	  	  			// Capture server error messages
	  			if ( response["errors"] != undefined ) {
	  				this.error = response["errors"][0].message
	  				return
	  			}  

			    this.showing = response["data"].showing

		})
	}



}
