import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { PropertyService } from '../services/property.service'
import { LoginService } from '../services/login.service'
import { environment } from 'src/environments/environment';

// import { GoogleMapsModule } from '@angular/google-maps'
// import { MapsService } from '../services/maps.service'

import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { 
	// unixTimestampToDate, 
	// isValidDate, 
	// formatTourDate, 
	// formatShowingDate, 
	addCommasToPrice,
	bathroomCalculation,
	checkForAuthError,
	splitOnCapitalLetters

} from '../utils'

// import { Client } from '../client'
import { Property } from '../propertySearch'
// import { PropertyPhoto } from '../propertyPhoto'
import { ImageViewerComponent } from '../image-viewer/image-viewer.component'

// import { MatListModule } from '@angular/material/list';
// import { MatFormFieldModule } from '@angular/material/form-field';
// import { MatInputModule } from '@angular/material/input';
// import { MatCardModule } from '@angular/material/card';
// import { MatIconModule } from '@angular/material/icon';
// import { MatButtonModule } from '@angular/material/button';
// import { MatTabsModule } from '@angular/material/tabs';
// import { MatSelectModule } from '@angular/material/select';
// import { MatExpansionModule } from '@angular/material/expansion';
// import { MatTableModule } from '@angular/material/table';
// import { MatCheckboxModule } from '@angular/material/checkbox';
// import { MatGridListModule } from '@angular/material/grid-list';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
// import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
// import { MatDatepickerModule } from '@angular/material/datepicker';
// import { MatNativeDateModule } from '@angular/material/core';
// import { MatChipsModule } from '@angular/material/chips';
import { BaseComponent } from '../base/base.component'

import * as mapboxgl from 'mapbox-gl';
import * as MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';

import { ToursService } from '@app/services/tours.service';
import { ConfirmListComponent } from '@app/confirm-list/confirm-list.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ErrorModalComponent } from '@app/error-modal/error-modal.component';
import { ConfirmInputComponent } from '@app/confirm-input/confirm-input.component';
import { MatSnackBar } from '@angular/material/snack-bar';
// import { GoogleAnalyticsService } from '@app/services/google-analytics.service';


@Component({
  selector: 'app-property-details',
  templateUrl: './property-details.component.html',
  styleUrls: ['./property-details.component.css']
})
export class PropertyDetailsComponent extends BaseComponent implements OnInit {

	/**
 	 Passed in as a path parameter, /property-details/:listingKey
	*/

	environment = environment;
	
	listingKey: string = null

	property: Property = null

	clientId: string = null

	error: string = null

	imageGridConfig: any = {
		showThumbnails: true
	};

	/**
 	 Expose to scope
	*/
	bathroomCalculation = bathroomCalculation
	showSpinner: boolean = false;
	showDone: boolean;
	isAddToWishList:boolean=false;
	isWishlistBtnClicked:boolean=false;
	constructor(
		protected loginService: LoginService,
		protected router: Router,
		protected route: ActivatedRoute,
		private propertyService: PropertyService,
		// private mapsService: MapsService,
		private toursService: ToursService,
		private snackBar: MatSnackBar,
		private modalService: NgbModal,
		public dialog: MatDialog,
		// private gaService: GoogleAnalyticsService
	) { 

			super( loginService, router, route )

	}

	ngOnInit(): void {

		// Property detail pages will be public unless they are requesting client info.
		let clientIdCheck = this.route.snapshot.paramMap.get('clientId')

		// Get the existing agent information if it is accessible by this agent
		this.route
			.params
			.subscribe( (params) => {
				this.listingKey = params['listingKey']
				this.clientId = params['clientId']

				const excludedListingKeys = environment.excludedListingKeys;
				if (!excludedListingKeys.includes(this.listingKey)) {
					this.fetchSingleProperty();
				}

			})

	}


	onMapInit() {

	}

	/**
 	 Image viewer for listing photos
	*/
	onOpenImages(event) {

	    const dialogRef = this.dialog.open(ImageViewerComponent, { 
	    	data: {
	    		images: this.property.propertyPhotos,
	    		activeImage: event.key
	    	}
	    });

	    dialogRef.afterClosed().subscribe(result => {});
  
	}

	/**
 	 Image viewer for client taken photos and videos
	*/
	onOpenClientImages(event) {
	    const dialogRef = this.dialog.open(ImageViewerComponent, { 
	    	data: {
	    		images: this.property.clientPhotos,
	    		activeImage: event.key
	    	}
	    });

	    dialogRef.afterClosed().subscribe(result => {});
	}

	/**
 	 Fetch the property by listingKey 
	*/
	fetchSingleProperty() {
		const user = this.loginService.getUser();
		this.propertyService
			.fetchPropertyData( this.listingKey, this.clientId,user.id )
			// .fetchPropertyByListingKey( this.listingKey, this.clientId )
			.subscribe(response => { 
	  
	  	  			// Capture server error messages
	  			if ( response["errors"] != undefined ) {
	  				
					this.error = checkForAuthError( response["errors"], this.router )
					this.isAddToWishList =false;
	  				return
	  			}  

	  			if ( response["data"]["property"] == null ) {
					this.router.navigate(['/page-not-found'])
					return
	  			}

			    this.property = response["data"]["property"]
				// this.gaService.sendPageLoadEvent('property',this.property) ;
				if(this.property.wishlistFlag)this.isAddToWishList=true;
			    this.property.clientPhotos = this.expandClientPhotos(this.property.clientPhotos)
			    // this.onLoadMap( this.property.address.fullAddress, this.property)
				this.showAddress(this.property.address.fullAddress);

		})
	}

	/**
 	 Make the ClientPhotos into PropertyPhotos to better reuse the `image-grid` component.
	*/
	private expandClientPhotos(clientPhotos) {

		return clientPhotos?.map( x => {
			return {
				url: x.url,
				type: x.type,
				isTour: false,
				mediaKey: null,
				displayOrder: null,
				longDescription: null,
				category: null
			}
		})
	}


	/**
 	 Show a heart icon if there is a client attached to this property and
 	 fill it in if it is a favorite. The icon name `favorite` also matches a css class for coloring.
	*/
	getFavStatus() {

		if ( this.property?.clientFavorites != null && this.property?.clientFavorites.length > 0 ) {
			return this.property.clientFavorites[0].favoriteStatus == 1 ? "favorite" : "favorite_border"
		} 

		return "favorite_border"

	}

	/**
 	 Show a star icon if there is a client attached to this property and
 	 fill them in. The icon name `star` also matches a css class for coloring.
	*/
	getStarStatus(starIndex) {

		if ( this.property?.propertyRatings != null && this.property?.propertyRatings.length > 0 ) { 

			let lastRating = this.property.propertyRatings[0].starRating
			return Number(lastRating) >= starIndex ? "star" : "star_outline"
		}

		return "star_outline"

	}

	formatTimestamp(timestamp) {
		return new Date(Number(timestamp)).toDateString()
	}


	/**
 	 Add our dollar sign ( for now ) and
	*/
	formatPrice(price) {

		if ( price != null ) {
			return `$${addCommasToPrice(price)}`
		}

		return "$0"
	}

	/**
 	 Compare original price to current price and spit out an arrow up or down
	*/
	priceChangeArrow() {

		if ( this.property == null ) {
			return '-'
		}

		let listPrice = this.property?.propertyFeatures['featureData'].ListPrice
		let originalListPrice = this.property?.propertyFeatures['featureData'].OriginalListPrice

		if ( listPrice < originalListPrice ) {
			this.property.priceChange = -1
			return '▼';
			// return '⬇'
		} else if ( listPrice > originalListPrice ) {

			this.property.priceChange = 1
			return '▲';
			// return '⬆'
		} else {

			this.property.priceChange = 0
			return '-'
		}
	}

	/**
 	 What color should the price-change arrow be
	*/
	// priceChangeColor(property) {
	// 	switch ( property?.priceChange ) {
	// 		case -1:
	// 			return "red"
	// 			break
	// 		case 1: 
	// 			return "green"
	// 			break
	// 		case 0:
	// 		default:
	// 			return null

	// 	}
	// }
	priceChangeColor(property) {

        return {

            'text-default':property?.priceChange==0||property?.priceChange==undefined||property?.priceChange==null? true:false,

            'red': property?.priceChange==-1?true:false,

            'green': property?.priceChange==1?true:false,

        };

    }

	/**
 	 Simple check, splitting text by double spaces.
	*/
	// formatRemarks( remarks ) {
	// 	return remarks?.split('. ').map( each => { return each + ". " })
	// }
	formatRemarks(remarks) {
		if (!remarks) return '';
	  
		const formattedRemarks = remarks.replace(/\.\s+/g, ". ");
	  
		// Split the remarks into sentences
		const sentences = formattedRemarks.split('. ');
	  
		// Join sentences, adding a line gap after every 4 sentences
		const newLineGap = 4;
		// const result = sentences.reduce((accumulator, sentence, index) => {
		//   if ((index + 1) % newLineGap === 0 && index !== sentences.length - 1) {
		// 	return accumulator + sentence + '.<br><br>'; // Add line gap after 4 sentences
		//   }
		//   return accumulator + sentence + '. ';
		// }, '');
		const result = sentences.reduce((accumulator, sentence, index) => {
			// Check if it's not the last sentence
			if (index !== sentences.length - 1) {
				// Add line gap after every 4 sentences
				if ((index + 1) % newLineGap === 0) {
					return accumulator + sentence + '.<br><br>';
				} else {
					return accumulator + sentence + '. ';
				}
			} else {
				// If it's the last sentence, just add it without period
				return accumulator + sentence;
			}
		}, '');
	  
		return result;
	}

	/**
 	 Region formatting, using city region and country region as applicable
	*/
	formatRegion( featureData ) {

		let ret = ''
		if ( featureData?.CityRegion != null ) {
			ret += `${featureData.CityRegion}`
			if ( featureData?.CountryRegion != null ) {
				ret += ', '
			}
		}

		if ( featureData?.CountryRegion != null ) {
			ret += `${featureData.CountryRegion}`
		}

		return ret.length > 0 ? ret : '-'
	}

	propertyDetails(property) {

		let featureData = property?.propertyFeatures.featureData
		let garage = '-'
		// if ( featureData?.GarageYN != null && featureData?.GarageYN == "false" ) {
		// 	garage = "None"
		// } else {
		// 	// garage = `Yes / ${featureData?.GarageSpaces} spaces`
		// 	const garageSpaces = featureData?.GarageSpaces !== undefined ? `${featureData?.GarageSpaces} spaces` : "N/A";
		// 	garage = `Yes / ${garageSpaces}`;
		// }
		if (featureData?.GarageYN != null && featureData?.GarageYN == "false") {
			garage = "N/A";
		} else if (featureData?.GarageSpaces == undefined || featureData?.GarageSpaces === "********" || featureData?.GarageSpaces === 0) {
			garage = "N/A";
		} else {
			const garageSpaces = `${featureData?.GarageSpaces} spaces`;
			garage = `Yes / ${garageSpaces}`;
		}

		let hoa = "None"
		// if (featureData?.AssociationYN != null && featureData?.AssociationYN == "true") {
		if (featureData?.AssociationYN != null && featureData?.AssociationYN != '********' && (featureData?.AssociationYN === true || featureData?.AssociationYN === "true")) {
			if (featureData.AssociationFee) {
				hoa = `${this.formatPrice(featureData.AssociationFee)}`;
			} else {
				hoa = "None";
			}
		}

		let area = `${featureData?.CityRegion ?? ""}`

		// let agentCommission = featureData?.BuyerAgencyCompensation ?? "N/A";
		// let agentCommission = featureData?.BuyerAgencyCompensation ?? featureData?.BuyerBrokerageCompensation ?? "N/A";
		let agentCommission = 
		(featureData?.BuyerAgencyCompensation !== "********" && featureData?.BuyerAgencyCompensation) 
		?? (featureData?.BuyerBrokerageCompensation !== "********" && featureData?.BuyerBrokerageCompensation) 
		?? "N/A";

		if (agentCommission.includes('%')) {
			const floatValue = parseFloat(agentCommission);
			if (!isNaN(floatValue) && floatValue !== Math.floor(floatValue)) {
			  agentCommission = `${floatValue.toFixed(2)}%`;
			}
		}
		else {
			const floatValue = parseFloat(agentCommission);
			if (!isNaN(floatValue)) {
			  if (floatValue === Math.floor(floatValue)) {
				agentCommission = `${floatValue.toFixed(0)}%`;
			  } else {
				agentCommission = `${floatValue.toFixed(2)}%`;
			  }
			}
		}

		let view = 'N/A';
		if (property?.originatingMls === 'PCMLS' || property?.originatingMls === 'Park City Realtors') {
			const viewData = featureData?.View;
			if (viewData && viewData !== '********' && typeof viewData === 'object') {
				const trueViews = Object.keys(viewData).filter(viewKey => viewData[viewKey]);
				view = trueViews.length > 0 ? trueViews.join(', ') : 'N/A';
			} else {
				view = 'N/A';
			}
		} else {
			view = featureData?.View ? featureData?.View?.toString()?.split(',')?.join(', ') : 'N/A';
		}
		

		return {
			"HOA": hoa,
			"Year Built": featureData?.YearBuilt !== null && featureData?.YearBuilt !== '********' &&featureData?.YearBuilt !== undefined && featureData?.YearBuilt !== 0 ? featureData?.YearBuilt : "N/A",
			"Furnished": featureData?.Furnished !== null && featureData?.Furnished !== '********' && featureData?.Furnished !== undefined && featureData?.Furnished ? featureData?.Furnished :"N/A",
			"Garage": garage,
			"Property Type": splitOnCapitalLetters(featureData?.PropertyType),
			"View": view,
			"County": featureData?.CountyOrParish && featureData?.CountyOrParish !== '********'? featureData?.CountyOrParish :"N/A",
			// "Agent's Commission":agentCommission,
		}
	}

	ListAgentDetails(property) {

		let featureData = property?.propertyFeatures.featureData
		return {
			"Name":featureData?.ListAgentFullName ?? "N/A",
			"Email":featureData?.ListAgentEmail ?? "N/A",
			"Phone":featureData?.ListAgentPreferredPhone ?? "N/A"
		}
	}

	showAddress(address: string) {
		(mapboxgl as typeof mapboxgl).accessToken = environment.map.mapbox.apiKey;
		// Create a new map instance
		const map = new mapboxgl.Map({
		  attributionControl: false,
		  container: 'map',
		  style: 'mapbox://styles/mapbox/streets-v11', // Use your preferred map style
		  center: [0, 0], // Set the initial center of the map
		  zoom: 12 // Set the initial zoom level
		});
	  
		// Add the Mapbox Geocoder control for address search
		map.addControl(
		  new MapboxGeocoder({
			accessToken: mapboxgl.accessToken,
			mapboxgl: mapboxgl
		  })
		);
	  
		// Geocode the address to get the coordinates
		fetch(`https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(address)}.json?access_token=${mapboxgl.accessToken}`)
		  .then(response => response.json())
		  .then(data => {
			const coordinates = data.features[0].center;
	  
			// Add a marker at the address coordinates
			new mapboxgl.Marker().setLngLat(coordinates).addTo(map);
	  
			// Update the map center and zoom to show the marker
			map.setCenter(coordinates);
			map.setZoom(14);
		  })
		  .catch(error => {
			console.log('Geocoding error:', error);
		  });
	}

	/**
 	 When the map needs to load, first check if the property object has lat/long ( Trestle provides this ).
 	 If not, check for a cached google geooding response, and barring that we'll go off and fetch the geocoding info from Google.
	*/  
	onLoadMap( address: string, property: Property ) {

		if ( property.center != null ) { return }

		// Use Trestle provided lat/lng
		if ( property.center == null && property.propertyFeatures["featureData"]["Latitude"] != null ) {

			property.center = {
				lat: Number(property.propertyFeatures["featureData"]["Latitude"]),
				lng: Number(property.propertyFeatures["featureData"]["Longitude"])
			}

			return
		}


		// Otherwise use Google geocoding and check if already fetched once
		if ( property.geocode != null ) {
			return
		}


    	// this.mapsService
    	// 	.geocode(address)
    	// 	.subscribe( res => {
    	// 		if ( res["results"].length > 0 ) {
	    // 			property.geocode = res["results"][0]
	    // 			property.center = property.geocode.geometry.location
    	// 		}
    	// 	})

	}

	onAddToTour(tourAdditions) {
		let map = this.route.snapshot.queryParams
		// this.gaService.sendCtaClickEvent({page_name:`property-${this.property?.listingKey}`},{cta_type: 'button', cta_location:'property_details', cta_description:'add_to_tour', property_id:this.property?.listingKey});//cta 60
		this.showSpinner = true;

	
		// Get the agent's tours
		this.toursService.fetchTours().subscribe((tours) => {
		  // Assume for alpha stage that an error means logged out
		  if (tours['errors'] != null) {
			this.error = tours['errors'][0].message;
			return;
		  }
	
		  if (tours['data']['tours'].length === 0) {
			this.showCreateTourPopup(tourAdditions);
			return;
		  }

		  const filteredTours = tours['data']['tours'].filter((tour) => tour.tourCompleted === 0);
	
		  // Have him choose which
		  const dialogRef = this.dialog.open(ConfirmListComponent, {
			data: {
			  message: 'Please select a tour',
			  buttonText: {
				ok: 'SELECT',
				cancel: 'CANCEL',
				extra: 'CREATE NEW',
			  },
			//   list: tours['data']['tours'].map((x) => x.name),
			list: filteredTours.map((x) => x.name),
			  extraAction: () => {
				// An extra close call is probably not needed...
				dialogRef.close();
				this.showCreateTourPopup(tourAdditions);
			   },
			},
		  });
	
		  // Tour select action
		  dialogRef.afterClosed().subscribe((result) => {
			this.error = null;
			//cta 59
			if(result  && result?.cancel){
				// this.gaService.sendCtaClickEvent({page_name:`property-details`},{cta_type: 'button', cta_location:'select_tour_popup', cta_description:'cancel'});
			}
			if (result?.confirmed) {
			  let tourId = tours['data']['tours'].filter((x) => {
				return x.name == result.selection;
			  })[0]?.id;
			//   this.gaService.sendCtaClickEvent({page_name:`property-details`},{cta_type: 'button', cta_location:'select_tour_popup', cta_description:'select', selection_option:tourId});//cta 57
			  let propertyIdList = tourAdditions.id;
		  
			  this.toursService.fetchTourSummary(tourId).subscribe((response) => {
				const tourSummary = response['data']; // The tour summary data is now directly available in response.data
				// Process the tour summary here
				const showings = tourSummary['tours'][0]['showings'];
				const existingPropertyIds = showings.map((showing) => {
				  return showing['property']['id'];
				});
				const existingPropertyIdsStrings = existingPropertyIds.map(String); // Convert numbers to strings
		  
				const newPropertyIdList = [];
				const existingPropertyIdList = [];
				const existingPropertyAddressList = [];
				const newPropertyAddressList = [];
		  
				  const showing = showings.find((showing) => showing['property']['id'] === propertyIdList);
				  if (existingPropertyIdsStrings.includes(propertyIdList) && showing ) {
					const address = showing['property']['propertyFeatures']['featureData']['UnparsedAddress'];
					existingPropertyIdList.push(propertyIdList);
					existingPropertyAddressList.push(address);
				  } else {
					newPropertyIdList.push(propertyIdList);
				  }
	
				if (newPropertyIdList.length === 0) {
				  const errorMessage = ['Selected properties already exist.'];
				  this.openErrorModal(errorMessage, false);
				  this.showSpinner = false;
				  return;
				}
	
				if (newPropertyIdList.length !== 0 && existingPropertyIdList.length !== 0) {
				  const errorMessage = existingPropertyAddressList;
				  this.openErrorModal(errorMessage, true);
				  this.showSpinner = false;
				  return;
				}
		  
				this.toursService.addPropertiesToTour(newPropertyIdList, tourId).subscribe((showingTour) => {
				  this.showSpinner = false;
				  // Assume for alpha stage that an error means logged out
				  if (showingTour['errors'] != null) {
					this.error = showingTour['errors'][0].message;
					return;
				  }
				  this.navigateToTours();
				});
			  });
			} else {
			  this.showSpinner = false;
			}
		  });
		});
	}
	onAddToWishList(property){
		const user = this.loginService.getUser();
		this.isWishlistBtnClicked=true;
		// this.gaService.sendCtaClickEvent({page_name:`property-${this.property?.listingKey}`},{cta_type: 'button',cta_location:'property_details', cta_description:'add_to_wishlist',property_id:this.property?.listingKey})
		this.toursService.addToWishList(property,user.id).subscribe({next:(res)=>{
			if (res['errors'] != null) {
			this.isAddToWishList=false;
			this.error = res['errors'][0].message;
			return;
			}
			this.isAddToWishList=true
			this.snackBar.open(res['data']['addPropertyToWishList'].message , 'Close', {
				duration: 5000, // notification will automatically close after 5 seconds
				horizontalPosition: 'center', // position the notification at the right corner of the screen
				verticalPosition: 'top', // position the notification at the top of the screen
			  });
			this.fetchSingleProperty();
			this.isWishlistBtnClicked=false;
		},error:(err)=>{
			this.isAddToWishList=false;
			this.error = err['errors'][0].message;
		}})
	}
	showCreateTourPopup(tourAdditions) {
		// this.gaService.sendCtaClickEvent({page_name:`property-details`},{cta_type: 'button', cta_location:'select_tour_popup', cta_description:'create_new'});//cta 58
		const inputDialogRef = this.dialog.open(ConfirmInputComponent, {
		  data: {
			message: 'Please name your new tour',
			currentRoute:'property-details',
			buttonText: {
			  ok: 'CREATE',
			  cancel: 'CANCEL',
			},
			inputs: [
			  { name: 'Tour Name', value: null },
			  { name: 'Tour Description', value: null },
			],
		  },
		});
	  
		let runAfterClosed = (result) => {
		  if (result?.confirmed) {
			this.showSpinner = true;
	  
			let propertyIdList =
			  tourAdditions != null ? tourAdditions.id : null;

			// Create the new tour and get its id
			this.toursService.addTour(result.tour, propertyIdList, null).subscribe((response) => {
			  // Rest of your existing code for creating the new tour...
			  if(response['errors'] != null) {
				this.error = response['errors'][0].message;
				// this.gaService.sendErrorDisplayEvent({page_name:'please_name_your_new_tour',message:this.error,type:"form_validation"})
				return;
			  }
			  this.navigateToTours();
	  		});
	  
			this.showSpinner = false;
			this.showCheckmark(null);
		  }
		  else{
			this.showSpinner = false;
		  }
		};
	  
		runAfterClosed.bind(this);
	  
		inputDialogRef.afterClosed().subscribe(runAfterClosed);
	  }

	//   openErrorModal(errorMessage: string[], isExistingPropertyError: boolean) {
	// 	const modalRef = this.modalService.open(ErrorModalComponent);
	// 	modalRef.componentInstance.errorMessage = errorMessage;
	// 	modalRef.componentInstance.isExistingPropertyError = isExistingPropertyError;
	//   }
	openErrorModal(errorMessage: string[], isExistingPropertyError: boolean) {
		const dialogRef = this.dialog.open(ErrorModalComponent, {
		  data: {
			message: errorMessage,
			isExistingPropertyError:isExistingPropertyError,
			buttonText: {
			  // cancel: 'CANCEL',
			},
		  },
		  panelClass: 'custom-modal-box'
		});
		dialogRef.afterClosed()
	  }

	  navigateToTours() {
		this.router.navigate(['/tours']);
	  }
	
	  showCheckmark(next) {
		this.showDone = true;
		setTimeout(() => {
		  this.showDone = false;
	
		  if (next != null) {
			// Pass "self" context
			next(this);
		  }
		}, 3000);
	  }
	  removeWishlist(item){
		if(!item.wishlistId)return;
		this.isWishlistBtnClicked=true;
		// this.gaService.sendCtaClickEvent({page_name:`property-${this.property?.listingKey}`},{cta_type: 'button',cta_location:'property_details', cta_description:'removed_from_wishlist',property_id:this.property?.listingKey});
		this.toursService.removeWishList(item.wishlistId).subscribe({
		  next:(res)=>{
			if ( res["errors"] != undefined ) {
				this.error = checkForAuthError( res["errors"], this.router )
				return;
				} 
				this.isAddToWishList=false;
				this.isWishlistBtnClicked=false;
			  this.snackBar.open(res['data']['deletePropertyWish'].message , 'Close', {
				duration: 5000, // notification will automatically close after 5 seconds
				horizontalPosition: 'center', // position the notification at the right corner of the screen
				verticalPosition: 'top', // position the notification at the top of the screen
			  });
		  },error:(err)=>{
			this.error = err
		  }
		})
	  }
	  onArrowClick(data){
		// this.gaService.sendCtaClickEvent({page_name:`property-${this.property?.listingKey}`},{...data,property_id:this.property?.listingKey})
	  }

}
