<div class="bodyContent">
    <!-- <div style="position: relative;"> -->
    <div class="client" style="position: relative;">
        <img src=" assets/home-icon.svg" alt="Image" class="homelog" style="position: relative;">
        <span class="floatText" style="position: relative;">My Clients</span>
        <span class="editprofile" style="position: relative;">ADD CLIENT <i class="bi bi-plus-square plusicon"
                style="color:#0C4675;padding-left: 2px;"></i></span>
        <span class="line"></span>
    </div>
</div>

<div class="contentBox">
    <div class="filter">

        <div class="row">
            <div class="col-md-2">
                <input type="text" class=" mt-3 inputLine form-control" placeholder="Filter"
                    style="background:transparent ;" />
            </div>
            <div class="col-md-2 actions">
                <img src=" assets/trash.svg" alt="Image">
                <img src=" assets/trash.svg" style="padding-left: 10px;" alt="Image">
            </div>
        </div>


        <div class="row filterHead">
            <div class="col-md-1">
                <label class="container">
                    <input type="checkbox">
                    <span class="checkmark"></span>
                </label>
            </div>

            <div class="col-md-3">
                User Name
                <img src="assets/arrow.svg" alt="" style="padding-left: 40px;">
            </div>

            <div class="col-md-2">
                Email ID
                <img src="assets/arrow.svg" alt="" style="padding-left: 40px;">
            </div>

            <div class="col-md-2">
                Effective Date
                <img src="assets/arrow.svg" alt="" style="padding-left: 40px;">
            </div>

            <div class="col-md-2">
                Timframe
                <img src="assets/arrow.svg" alt="" style="padding-left: 40px;">
            </div>

            <div class="col-md-1">
                Tour
            </div>

            <div class="col-md-1">
                Delete
            </div>
        </div>


        <div class="row  filterBody" style="margin-top: -10px;">
            <div class="col-md-1">
                <label class="container">
                    <input type="checkbox">
                    <span class="checkmark"></span>
                </label>
            </div>

            <div class="col-md-3">
                <img src="assets/avatar.jpg" alt="" width="15%" style="border-radius: 50%;">
                <label style="padding-left: 40px;"> Jane Doe</label>
            </div>

            <div class="col-md-2">
                jdoe2&#64;email.com
            </div>

            <div class="col-md-2">
                9.30am 01/01/20
            </div>

            <div class="col-md-2">
                Immediately
                <img src="assets/avatar.jpg" alt="" width="8%" style="border-radius: 50%;">
            </div>

            <div class="col-md-1">
                <img src="assets/trash.svg" alt="" width="30%">
            </div>

            <div class="col-md-1">
                <img src="assets/trash.svg" alt="" width="30%">
            </div>
            <span class="rowLine"></span>
        </div>



        <div class="row  filterBody" style="margin-top: -10px;">
            <div class="col-md-1">
                <label class="container">
                    <input type="checkbox">
                    <span class="checkmark"></span>
                </label>
            </div>

            <div class="col-md-3">
                <img src="assets/avatar.jpg" alt="" width="15%" style="border-radius: 50%;">
                <label style="padding-left: 40px;"> Jane Doster lll</label>
            </div>

            <div class="col-md-2">
                jdoester3&#64;email.com
            </div>

            <div class="col-md-2">
                9.30am 01/01/20
            </div>

            <div class="col-md-2">
                Within a Year
                <img src="assets/avatar.jpg" alt="" width="8%" style="border-radius: 50%;">
            </div>

            <div class="col-md-1">
                <img src="assets/trash.svg" alt="" width="30%">
            </div>

            <div class="col-md-1">
                <img src="assets/trash.svg" alt="" width="30%">
            </div>
            <span class="rowLine"></span>
        </div>


        <div class="row  filterBody" style="margin-top: -10px;">
            <div class="col-md-1">
                <label class="container">
                    <input type="checkbox">
                    <span class="checkmark"></span>
                </label>
            </div>

            <div class="col-md-3">
                <img src="assets/avatar.jpg" alt="" width="15%" style="border-radius: 50%;">
                <label style="padding-left: 40px;"> Mark Miller</label>
            </div>

            <div class="col-md-2">
                mark2342&#64;email.com
            </div>

            <div class="col-md-2">
                9.30am 01/01/20
            </div>

            <div class="col-md-2">
                Immediately
                <img src="assets/avatar.jpg" alt="" width="8%" style="border-radius: 50%;">
            </div>

            <div class="col-md-1">
                <img src="assets/trash.svg" alt="" width="30%">
            </div>

            <div class="col-md-1">
                <img src="assets/trash.svg" alt="" width="30%">
            </div>
            <span class="rowLine"></span>
        </div>

        <div class="row  filterBody" style="margin-top: -10px;">
            <div class="col-md-1">
                <label class="container">
                    <input type="checkbox">
                    <span class="checkmark"></span>
                </label>
            </div>

            <div class="col-md-3">
                <img src="assets/avatar.jpg" alt="" width="15%" style="border-radius: 50%;">
                <label style="padding-left: 40px;"> James Dean</label>
            </div>

            <div class="col-md-2">
                jdoe2&#64;email.com
            </div>

            <div class="col-md-2">
                9.30am 01/01/20
            </div>

            <div class="col-md-2">
                Within a Month
                <img src="assets/avatar.jpg" alt="avatar" width="8%" style="border-radius: 50%;">
            </div>

            <div class="col-md-1">
                <img src="assets/trash.svg" alt="trash" width="30%">
            </div>

            <div class="col-md-1">
                <img src="assets/trash.svg" alt="trash1" width="30%">
            </div>
            <span class="rowLine"></span>
        </div>
    </div>
    <!-- </div> -->
</div>