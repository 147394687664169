
	<div class="content-container">
		<div class="title">
			<h1 tabindex="0" class="customheading"><span class="summeryTitle">Summary of your Property Tour Facilitated by LivePad&nbsp;|&nbsp;{{tour?.tourDate}}</span>
			<div class="filter-container-tour">
				<!-- <label for="sortCriteria" class="sort-label">Sort by:</label> -->
				<div class="custom-dropdown">
					<!-- <select id="sortCriteria" [(ngModel)]="sortCriteria" (change)="sortShowings()">
						<option value="" disabled selected hidden>Sort by</option>
						<option value="price">Price</option>
						<option value="ratings">Ratings</option>
						<option value="favorites">Favorites</option>
					</select>
					<span class="arrow">&#9662;</span> -->
					<mat-form-field appearance="fill">
						<mat-label>Sort by</mat-label>
						<mat-select [(value)]="sortCriteria" (selectionChange)="sortShowings()">
						  <mat-option value="price">Price</mat-option>
						  <mat-option value="ratings">Ratings</mat-option>
						  <mat-option value="favorites">Favorites</mat-option>
						</mat-select>
					  </mat-form-field>
				</div>
			</div>
		</h1>
		</div>
		<div class="new-tour-setup" *ngFor="let info of sectionInfo()">{{ info }} New Tour Livepad Setup</div>
		<!-- <div class="details-wrapper">
			
			<ng-container *ngFor="let info of sectionInfo()">
				<div class="details-content">
				{{ info !=='undefined'?info:'' }}
				</div>
			</ng-container>
		</div> -->
		<div class="tour-summary-details-card" *ngFor="let showing of tour?.showings; let i = index ">
			<app-tour-summary-details-card
				  [property]="showing.property"
				  [client]="getClient()"
				  [showing]="showing"
				  [propertyIndex]="i + 1">
			</app-tour-summary-details-card>
		</div>
		<div style="text-align:center;" class="email-button">
			<button tabindex="0" type="submit" (click)="onOpenModal()">EMAIL TO CLIENT</button>
		</div>
	</div>
	<div class="modal customemailpopup" *ngIf="showTourSummaryModal">
		<div class="modal-content">      
		  <div class="container px-5">
			<span (click)="onCloseModal()" class="close-clientemail-popup">&#x2715;</span>
			<img src="assets/ic_baseline-email.png" alt="Email" />
			<h1 style="font-family: var(--lp-font-family);" class="client-email" tabindex="0">Client email</h1>
			<div class="row justify-content-center">
				<div class="col-md-12">
					<mat-form-field class="mt-3">
						<mat-label>Email</mat-label>
						<!-- pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"  -->
						<input type="email" placeholder="example@email.com" matInput name="email" id="emails"  ngModel #email="ngModel"   required (input)="errorInvalidEmail=false">
					</mat-form-field>
					<br>
				</div>
				<div class="col-sm-12">
					<div *ngIf="email.touched">
						<p *ngIf="email.errors?.required"><small>Email is a required field!</small></p>
						<p *ngIf="!email.errors?.required && errorInvalidEmail"><small>This is not a valid Email!!!</small></p>
					</div>
				</div>
			</div>
			<div class="btn-wrap" style="margin-top: 5px;">
			<button type="submit" class="tour-complete-btn me-2" (click)="onSubmitGenerateLink(email.value)"  [disabled]="!email.valid||(!email.errors?.required && errorInvalidEmail)">SEND</button> 
			</div>
		  </div>
		</div>
	</div>
	<app-spinner [show]="showSpinner"></app-spinner>
	