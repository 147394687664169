export class Actions {

	static ShowingAgentConfirmRequest: any
	static ShowingAgentConfirmedNotification: any
	static ShowingAgentRepliedNotification: any
	static ShowingAgentCounteredNotification: any

	static ListingAgentConfirmRequest: any
	static ListingAgentConfirmedNotification: any
	static ListingAgentRepliedNotification: any
	static ListingAgentCounteredNotification: any

}