<div class="content-container">
	<div class="contact-form-wrapper">
		<div class="leftside-content">
			<h1 tabindex="0">Contact Us</h1>
			<p>Whether you have questions about LivePad, need advice on how to better improve your client relationships,
				or want to schedule a demo, we are happy to help. </p>

			<p>Contact us by filling out the form, and let us guide you through every step of your real estate journey
				with personalized, professional service.</p>
		</div>
		<div class="rightside-form">
			<div class="logo-section">
				<img src="./assets/logo-symbol.png" alt="Livepad logo-symbol" tabindex="0">
			</div>
			<form [formGroup]="contactForm" (ngSubmit)="onSubmit()" *ngIf="!showDone">
				<div class="row">
					<div class="col-md-6">
						<div>
							<input type="text" name="name" placeholder="Name*" class="form-control" id="name"
							formControlName="name" id="userName" aria-labelledby="userName" Username  tabindex="0" />
							<div *ngIf="getContactForm['name'].invalid && (getContactForm['name'].touched || getContactForm['name'].dirty)"
								class="error-message">
								A name with no symbols is required
							</div>
						</div>
					</div>
					<div class="col-md-6">
						<div>
							<input type="text" name="email" placeholder="Email*" class="form-control"  formControlName="email" tabindex="0" id="email"  aria-labelledby="email" />
							<div *ngIf="getContactForm['email'].invalid && (getContactForm['email'].touched || getContactForm['email'].dirty)"
								class="error-message">
								Valid email is required
							</div>
						</div>
					</div>
				</div>
				<div class="row mt-2">
					<div class="col-md-6">
						<div>
							<input type="tel" name="phone" placeholder="Phone Number" class="form-control"
							formControlName="phone" tabindex="0" id="phone"  NumberOnly 
							(keydown.Tab)="onKey($event);"
							aria-labelledby="phone" />
							<div class="error-message" *ngIf="getContactForm['phone'].invalid && (getContactForm['phone'].touched || getContactForm['phone'].dirty)" >
								Valid phone number is required
							</div>
						</div>
					</div>
					<div class="col-md-6">
						<div>
							<input type="text" tabindex="0" name="organization" #organization placeholder="Organization" class="form-control" formControlName="organization"  id="organization"  aria-labelledby="organization" />
							<div class="error-message" *ngIf="getContactForm['organization'].invalid && (getContactForm['organization'].touched || getContactForm['organization'].dirty)" >
								Valid phone number is required
							</div>
						</div>
					</div>
				</div>
				<div class="row mt-2">
					<div class="col-md-12">
						<div>
							<textarea type="text" rows="4" name="message" id="message"  aria-labelledby="message"  tabindex="0" formControlName="message" placeholder="Write your message here"
							class="form-control" ></textarea>
							<!-- <div class="error-message" *ngIf="(getContactForm['message'].dirty)" >
								Valid message is required
							</div> -->
						</div>
					</div>
				</div>
				<div class="row mt-2 contact-button">
					<div class="col-md-6">
						<button class="btn-secondary mobile-btn-mar" type="reset" tabindex="0">Clear</button></div>
					<div class="col-md-6">
						<button class="btn-primary" type="submit" tabindex="0" [disabled]="!contactForm.valid">Submit</button>
					</div>
				</div>
			</form>
			<form *ngIf="showDone">
				<div class="text-center thanks-msg">
					<h1>Thank You</h1>
					<p>Our LivePad team will contact you shortly.</p>
				</div>
			</form>
		</div>
	</div>
</div>
<app-spinner [show]="showSpinner"></app-spinner>