<div class="handleBody">
    <div class="bodyContent">
        <div class="client" style="position: relative;">
            <img src=" assets/home-icon.svg" alt="Image" class="homelog" style="position: relative;">
            <span class="floatText" style="position: relative;">My Tours</span>
            <span class="editprofile" style="position: relative;">ADD Tour <i class="bi bi-plus-square plusicon"
                    style="color:#0C4675;padding-left: 2px;"></i></span>
            <span class="line"></span>
        </div>
    </div>
    <div class="contentBox">
        <div class="filter">
            <div class="row">
                <div class="col-md-2">
                    <input type="text" class=" mt-3 inputLine form-control" placeholder="Filter"
                        style="background:transparent ;" align="right" />
                </div>
                <div class="col-md-2 actions">
                    <img src=" assets/trash.svg" alt="Image">
                </div>
            </div>
            <div class="row  filterBody">
                <div class="col-md-1" align="left">
                    <label class="container">
                        <input type="checkbox">
                        <span class="checkmark"></span>
                    </label>
                </div>
                <div class="col-md-5" align="left">
                    <img src="assets/avatar.jpg" alt="" width="10%" style="border-radius: 50%;">
                    <label style="padding-left: 40px;"> Jane Doe</label>
                    <span class="verticalLine"></span>
                    <label style="padding-left: 40px;">A tour of the Morris household</label>
                    <!-- <span class="verticalLine"></span> -->
                </div>
                <div class="col-md-3" align="left" style="float: left;color:#1279CB ;border-left: 1px solid #505050; ">
                    <label style="padding-left: 40px;"> ADD a Showing</label> <i class="bi bi-plus-square plusicon"
                        style="color:#0C4675;padding-left: 15px;"></i>
                </div>
                <div class="col-md-2" style="float: left; margin-left: -100px; border-left: 1px solid #505050; ">
                    <label style="padding-left: 20px;">9.30am 01/01/20</label>
                </div>
                <div class="col-md-1" align="left" style="margin-left: -30px;">
                    <img src="assets/trash.svg" alt="" width="30%">
                </div>
                <span class="rowLine"></span>
            </div>
            <div class="row  filterBody">
                <div class="col-md-1" align="left">
                    <label class="container">
                        <input type="checkbox">
                        <span class="checkmark"></span>
                    </label>
                </div>
                <div class="col-md-5" align="left">
                    <img src="assets/avatar.jpg" alt="" width="10%" style="border-radius: 50%;">
                    <label style="padding-left: 40px;"> Jane Doe</label>
                </div>
                <div class="col-md-3" align="left" style="float: left;color:#1279CB ;border-left: 1px solid #505050; ">
                    <label style="padding-left: 40px;"> ADD a Showing</label> <i class="bi bi-plus-square plusicon"
                        style="color:#0C4675;padding-left: 15px;"></i>
                </div>
                <div class="col-md-2" style="float: left; margin-left: -100px; border-left: 1px solid #505050; ">
                    <label style="padding-left: 20px;">9.30am 01/01/20</label>
                </div>
                <div class="col-md-1" align="left" style="margin-left: -30px;">
                    <img src="assets/trash.svg" alt="" width="30%">
                </div>
                <span class="rowLine"></span>
            </div>
            <div class="row  filterBody">
                <div class="col-md-1" align="left">
                    <label class="container">
                        <input type="checkbox">
                        <span class="checkmark"></span>
                    </label>
                </div>
                <div class="col-md-5" align="left">
                    <img src="assets/avatar.jpg" alt="" width="10%" style="border-radius: 50%;">
                    <label style="padding-left: 40px;"> Jane Doe</label>
                    <span class="verticalLine"></span>
                    <label style="padding-left: 40px;">A tour of the Morris household</label>
                </div>
                <div class="col-md-3" align="left" style="float: left;color:#1279CB ;border-left: 1px solid #505050; ">
                    <label style="padding-left: 40px;"> ADD a Showing</label> <i class="bi bi-plus-square plusicon"
                        style="color:#0C4675;padding-left: 15px;"></i>
                </div>
                <div class="col-md-2" style="float: left; margin-left: -100px; border-left: 1px solid #505050; ">
                    <label style="padding-left: 20px;">9.30am 01/01/20</label>
                </div>
                <div class="col-md-1" align="left" style="margin-left: -30px;">
                    <img src="assets/trash.svg" alt="" width="30%">
                </div>
                <span class="rowLine"></span>
            </div>
            <div class="row  filterBody">
                <div class="col-md-1" align="left">
                    <label class="container">
                        <input type="checkbox">
                        <span class="checkmark"></span>
                    </label>
                </div>
                <div class="col-md-5" align="left">
                    <img src="assets/avatar.jpg" alt="" width="10%" style="border-radius: 50%;">
                    <label style="padding-left: 40px;"> Jane Doe</label>
                </div>
                <div class="col-md-3" align="left" style="float: left;color:#1279CB ;border-left: 1px solid #505050; ">
                    <label style="padding-left: 40px;"> ADD a Showing</label> <i class="bi bi-plus-square plusicon"
                        style="color:#0C4675;padding-left: 15px;"></i>
                </div>
                <div class="col-md-2" style="float: left; margin-left: -100px; border-left: 1px solid #505050; ">
                    <label style="padding-left: 20px;">9.30am 01/01/20</label>
                </div>
                <div class="col-md-1" align="left" style="margin-left: -30px;">
                    <img src="assets/trash.svg" alt="" width="30%">
                </div>
                <span class="rowLine"></span>
            </div>
        </div>
    </div>
</div>