
export const LocalStorage = {
	
	set ( key: string, value: any ) {
		localStorage.setItem(key, value)
	},

	get ( key: string ): any {
		return localStorage.getItem(key)
	},

	clear () {
		localStorage.clear()
	}
}
