import { Component } from '@angular/core';

@Component({
  selector: 'app-view-client-list-new',
  templateUrl: './view-client-list-new.component.html',
  styleUrls: ['./view-client-list-new.component.css']
})
export class ViewClientListNewComponent {

}
