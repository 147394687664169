<div class="content-container">
    <div class="subscribe-container">
        <div class="row">
            <div class="col-md-6">
                <h4><span tabindex="0">Subscription</span></h4>
                <div class="subscibe-option-container">
                    <p align="center"><img src="./assets/subscrbe-logo.png" alt="Tours Icon"></p>
                    <p class="description-text">LivePad Monthly Subscription</p>
                    <div class="subscription-price"><div><span>$24.95</span><br><span class="perMonth">per month</span></div></div>
                    <div class="subscribeButton"><a href="https://buy.stripe.com/test_3cs02l6rJawU4IU145" target="_blank" alt="subscribe">Subscribe</a></div>
                    <p class="supportText">Supported payment methods</p>
                    <p align="center" class="carddetails"><img src="./assets/americanExpress-image.png" alt="american" tabindex="0"><img src="./assets/masterCard-image.png" alt="mastercard" tabindex="0"><img src="./assets/visa-image.png" alt="visa" tabindex="0"></p>
                </div>
            </div>
            <div class="col-md-6 promocontent">
                <h4><span tabindex="0">Subscription & Tablet (Bundle)</span></h4>
                <div class="promo-subscibe-option-container">
                    <p align="center"><img src="./assets/subscrbe-logo.png" alt="Tours Icon"></p>
                    <p class="promo-description-text"><span class="green">Promo</span><br> LivePad Monthly Subscription...<br>+ 1 more</p>
                    <div class="promo-subscription-price"><div><span>$249</span><br><span class="perMonth">then $24.95 per month</span></div></div>
                    <div class="promo-subscribeButton"><a href="https://buy.stripe.com/test_8wM2atbM36gE8Za144" target="_blank" alt="subscribe">Tablet + Subscribe</a></div>
                    <p class="supportText">Supported payment methods</p>
                    <p align="center" class="carddetails"><img src="./assets/americanExpress-image.png" alt="american" tabindex="0"><img src="./assets/masterCard-image.png" alt="mastercard" tabindex="0"><img src="./assets/visa-image.png" alt="visa" tabindex="0"></p>
                </div>
            </div>
        </div>
    </div>
</div>