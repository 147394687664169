import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-save-membership-profile',
  templateUrl: './save-membership-profile.component.html',
  styleUrls: ['./save-membership-profile.component.css'],
})
export class SaveMembershipProfileComponent {
  constructor(private router: Router) {}
  gotoForm() {
    this.router.navigate(['//member-licence']);
  }
}
