import { Component, Input, OnInit, ChangeDetectorRef, SimpleChanges,OnChanges, SimpleChange } from '@angular/core';
import { environment } from '@env/environment';
import * as mapboxgl from 'mapbox-gl';
import * as MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import { MapsService } from '@app/services/maps.service';
interface mapBoxConfig {
  style: any;
  lng: any;
  lat: any;
  container: string;
  zoom: number;
  message: string;
}
interface mapCustomControl {
  isSearch: boolean;
  isMarker: boolean;
  isMarkerDraggable:boolean;
}
@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css'],
})
export class MapComponent  implements OnChanges{
  private map: mapboxgl.Map;
  private flyToMarker: mapboxgl.Marker;
  @Input() config: mapBoxConfig;
  @Input() controls: mapCustomControl;
  @Input() latitude:number;
  @Input() longitude:number;
  constructor(private mapsService: MapsService) {
  }

  // mapboxgl.baseApiUrl = 'https://api.mapbox.com';
  ngOnInit() {
    (mapboxgl as typeof mapboxgl).accessToken = environment.map.mapbox.apiKey;
    this.map = new mapboxgl.Map({
      attributionControl: false,
      container: this.config.container,
      style: this.config.style,
      zoom: this.config.zoom,
      center: [this.config.lng, this.config.lat],
      // pitch: 60,
      // bearing: -60,
    });
    // Add map controls
    this.map.addControl(new mapboxgl.NavigationControl(), 'top-right');

    if (this.controls.isSearch) {
      this.map.addControl(
        new MapboxGeocoder({
          accessToken: mapboxgl.accessToken,
          mapboxgl: mapboxgl,
        })
      );
    }
    this.addMarker()
    let data = false;
    if (data) {
      this.map.addSource('customMarker', {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: [],
        },
      });
      // this.map.on('load', (event) => {
      // add the real time map data
      this.map.addLayer({
        id: 'customPoiId',
        source: 'customMarker',
        type: 'symbol',
        layout: {
          'text-field': '{message}',
          'text-size': 24,
          'text-transform': 'uppercase',
          'icon-image': 'marker-15',
          'text-offset': [0, 1.5],
        },
        paint: {
          'text-color': '#f16624',
          'text-halo-color': '#fff',
          'text-halo-width': 2,
        },
      });

      // const data = {
      //   type: 'FeatureCollection',
      //   features: markers
      // };
      // this.map.getSource('customMarker').setData(data);
      this.map.addLayer({
        id: 'customMarketid',
        source: 'customMarker',
        type: 'symbol',
        layout: {
          'text-field': '{message}',
          'text-size': 24,
          'text-transform': 'uppercase',
          'icon-image': 'marker-15',
          'text-offset': [0, 1.5],
        },
        paint: {
          'text-color': '#f16624',
          'text-halo-color': '#fff',
          'text-halo-width': 2,
        },
      });
      // });
    }
  }

  ngOnChanges(changes: {[latitude:number]: SimpleChange}) {
		// Detect changes to the address input and update the userInput accordingly
    this.addMarker()
	}
  addMarker(){
    if (this.controls.isMarker) {
      let airportIcon = document.createElement('div');
      airportIcon.classList.add('airport');

      let airportPopup = new mapboxgl.Popup({
        anchor: 'bottom',
        offset: [0, -6], // height - shadow
      }).setText(this.config.message);
      // .setHTML(
      //   `<h3>${feature.properties.title}</h3><p>${feature.properties.description}</p>`
      // )

      // // create a HTML element for each feature
      // const el = document.createElement('div');
      // el.setAttribute('id','marker');
      // <div id="marker" class="marker"><p>data</p></div> // for custom marker
      // el.setAttribute('style','cursor:pointer;border-radius:50%;');
      // const customImage = document.createElement('img');
      // customImage.src  = 'assets/myClients.png';
      // customImage.setAttribute('alt','markerIcon')
      // customImage.setAttribute('width','50');
      // customImage.setAttribute('height','50');
      
      // el.appendChild(customImage);

      // const markers = this.getMarkers()
      new mapboxgl.Marker({
        color: '#FF0000',
        anchor: 'bottom',
        offset: [0, 6],
        draggable: this.controls.isMarkerDraggable
      })
        .setLngLat([this.longitude, this.latitude])
        .setPopup(airportPopup)
        .addTo(this.map);
    }
  }
  getMarkers() {
    const geoJson = [
      {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: ['80.20929129999999', '13.0569951'],
        },
        properties: {
          message: 'Chennai',
        },
      },
      {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: ['77.350048', '12.953847'],
        },
        properties: {
          message: 'bangulare',
        },
      },
    ];
    return geoJson;
  }
  async flyToPosition(location: mapboxgl.LngLatLike, showMarker = false) {
    await this.map.flyTo({
      center: location,
    });
    if (showMarker) {
      this.removeFlyToMarker();
      this.flyToMarker = new mapboxgl.Marker({
        color: 'blue',
      });
      this.flyToMarker.setLngLat(location).addTo(this.map);
    } else {
      this.removeFlyToMarker();
    }
  }
  private removeFlyToMarker() {
    if (this.flyToMarker) {
      this.flyToMarker.remove();
    }
  }
  /*** map functionality  */
  // showAddress(address: string,lat:number,lng:number) {
  //   (mapboxgl as typeof mapboxgl).accessToken = environment.map.mapbox.apiKey;
  //   // let monument =[lat,lng]
  //   // Create a new map instance
  //   const map = new mapboxgl.Map({
  //     container: 'map',
  //     style: 'mapbox://styles/mapbox/streets-v11', // Use your preferred map style
  //     center: [0, 0], // Set the initial center of the map
  //     zoom: 12, // Set the initial zoom level
  //   });
  //   //create the popup
  //   const popup = new mapboxgl.Popup({offset:25}).setText(
  //     "address"
  //   )
  //   // .setLngLat([lat,lng])
  //   .addTo(map);
  //   // create DOM element for the marker
  //   const el = document.getElementById('marker')
  //   // const el = document.createElement('div');
  //   // el.id = 'marker';
  //   // create the marker
  //   new mapboxgl.Marker(el)
  //   .setLngLat([lat,lng])
  //   .setPopup(popup) // sets a popup on this marker
  //   .addTo(map);
  //   // Add the Mapbox Geocoder control for address search
  //   map.addControl(
  //     new MapboxGeocoder({
  //       accessToken: mapboxgl.accessToken,
  //       mapboxgl: mapboxgl,
  //     })
  //   );
  //     if(address){
  //   // Geocode the address to get the coordinates
  //   fetch(
  //     `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
  //       address
  //     )}.json?access_token=${mapboxgl.accessToken}`
  //   )
  //     .then((response) => response.json())
  //     .then((data) => {
  //       const coordinates = data.features[0].center;

  //       // Add a marker at the address coordinates
  //       new mapboxgl.Marker().setLngLat(coordinates).addTo(map);

  //       // Update the map center and zoom to show the marker
  //       map.setCenter(coordinates);
  //       map.setZoom(14);
  //     })
  //     .catch((error) => {
  //       // console.log('Geocoding error:', error);
  //     });
  //   }
  // }

  /**
	 When the map needs to load, first check if the property object has lat/long ( Trestle provides this ).
		If not, check for a cached google geooding response, and barring that we'll go off and fetch the geocoding info from Google.
	*/
  // onLoadMap(address: string, property: Property) {
  //   if (property.center != null) {
  //     return;
  //   }

  //   // Use Trestle provided lat/lng
  //   if (
  //     property.center == null &&
  //     property.propertyFeatures['featureData']['Latitude'] != null
  //   ) {
  //     property.center = {
  //       lat: Number(property.propertyFeatures['featureData']['Latitude']),
  //       lng: Number(property.propertyFeatures['featureData']['Longitude']),
  //     };

  //     return;
  //   }

  //   // Otherwise use Google geocoding and check if already fetched once
  //   if (property.geocode != null) {
  //     return;
  //   }

  //   this.mapsService.geocode(address).subscribe((res) => {
  //     if (res['results'].length > 0) {
  //       property.geocode = res['results'][0];
  //       property.center = property.geocode.geometry.location;
  //     }
  //   });
  // }
  ngOnDestroy() {
    this.map?.remove();
  }
}
