<mat-drawer-container class="sidenav-container" autosize *ngIf="hideWebView"> 

	<mat-drawer #drawer class="sidenav" mode="over">

		<app-sidenav [drawer]="drawer"></app-sidenav>

	</mat-drawer>

	<div class="sidenav-content">

		<app-header-nav-bar [drawer]="drawer"></app-header-nav-bar>



		<div class="content" role="main">

			<router-outlet></router-outlet>

		</div>

	</div>
	<app-footer-bar></app-footer-bar>


</mat-drawer-container>
<router-outlet *ngIf="!hideWebView"></router-outlet>