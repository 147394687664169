
<!-- One single row for a tour summary viewing -->
<mat-card>
	<div class="tour-summary-card-container">
		<div class="favorites-container">

			<app-favorite-property-card 
				[client]="client"
				[property]="property">	
			</app-favorite-property-card>
		</div>

		<div class="comments-container">


			<!-- Top three comments -->
			<div class="visit-summary-section">
				<div class="comments-title">Your Comments</div>
				<div class="visit-comment" *ngFor="let comment of comments()">
					{{ comment }}
					<mat-divider class="comment-divider"></mat-divider>
				</div>
			</div>

		</div>
	</div>
</mat-card>
