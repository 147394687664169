import { Component, EventEmitter, Output } from '@angular/core';
// import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LoginService } from '../services/login.service';
import { MatSnackBar } from '@angular/material/snack-bar'
import { MatDialogRef } from '@angular/material/dialog';


@Component({
  selector: 'app-forgot-password-modal',
  templateUrl: './forgot-password-modal.component.html',
  styleUrls: ['./forgot-password-modal.component.css']
})
export class ForgotPasswordModalComponent {

  // @Output() close: EventEmitter<void> = new EventEmitter<void>();
  email: string = '';
  errorMessage: any;

  constructor(
    // public activeModal: NgbActiveModal,
    public dialogRef: MatDialogRef<any>,
    private loginService: LoginService,
    private  snackBar: MatSnackBar) {}

    submitEmail(): void {
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

      // Check if email is empty or not valid
      if (this.email === '' || !emailRegex.test(this.email)) {
        // Display an error message if the email field is empty or not valid
        this.errorMessage = 'Valid email is required';
        return;
      }
      this.errorMessage = '';
        this.loginService.forgetPassword(this.email)
        .subscribe(
          response => {
            let message = '';
            if (response["errors"] != undefined) {
              message = response["errors"][0].message;
            } else {
              const responseData = response['data'].forgetPassword;
              const email = responseData.email;
              message = `A password reset link has been sent to ${email}. Please check your email to proceed with the password reset.`;
            }
    
            this.snackBar.open(message, 'Close', {
              duration: 5000,
              horizontalPosition: 'center',
              verticalPosition: 'top'
            });
    
            this.onNoClick()
            window.location.reload();
          },
        );
      }
  onNoClick(): void {
    this.dialogRef.close({ data: { submitted: true } });
  }  

  // closeModal(): void {
    // event.preventDefault();
    // this.activeModal.dismiss('Cross click'); // Close the modal without submitting the email
  // }
  
}
