
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// const home = "http://localhost:4000"
// const home = "https://api-livepad.webdemoapps.com" //dev environment
// const home = "https://stage-api-livepad.webdemoapps.com"   //stage environment 
// const home = "https://uat-api-livepad.webdemoapps.com"
const home = "https://api.livepad.pro" //prod environment
// export const environment = {
//   production: false,
//   graphApi: home
// };

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.




export const environment = {
	production: false,
	devProd:true,
	originatingMlsList: ['recolorado', 'ppar','pcmls','park city realtors'], // Add more as needed
	domMlsList: ['ppar','pcmls','park city realtors'],
	// agentNamePlaceholder: 'Admin',
	agentNamePlaceholder : ['Admin','Guru'],
	excludedListingKeys: [
        "20231110211650564556000000",
        "20231110211914885023000000",
        "20240430171849362940000000",
        "20231110212212834649000000",
        "20231110212057848510000000",
        "20231110212521867319000000"
    ],
	host: home,
	graphApi: home,
	env:'Prod',
	aws:{
		AWS_REGION:'us-east-1',
		AWS_KEY:'AKIA2AJHIM4LQQAWOZW6',
		AWS_SECRET_KEY:'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',
		IdentityPoolId:"us-east-1:db89f006-9540-4624-acae-908c0fb45546",
		pool_id:'us-east-1:db89f006-9540-4624-acae-908c0fb45546',
		bucketNameVideo:'livepad-source',
		bucketNameImage:'livepad-images',
		bucket:'https://d3o40wwl2vh905.cloudfront.net'

	},
	imagePlaceholder: "./assets/avatar.jpg",
	restApi: { 
		agentAvatarUpload: `${home}/agentAvatar`,
		clientAvatarUpload: `${home}/clientAvatar`,
		propertyPhotoUpload: home + "/propertyPhoto",
		orgAvatarUpload: `${home}/orgAvatar`,
		docUpload: `${home}/masterDocuments`,
		agentDocUpload: `${home}/docUploadLogs`,
		getAllDocsStatus: `${home}/getLogsDocStatus`
	},
	maps: {
		google: {
			apiKey: "AIzaSyC8Q6s5m_EMZzBQCLpmgmVJngVB7SpWVEE"
		}
	},
	map: {
		mapbox: {
			apiKey: "pk.eyJ1IjoibGl2ZXNvbHV0aW9ucyIsImEiOiJjbGZjb25jZ3MwODg4NDJubXJsdGtxYXNtIn0.juKSL4czqbrFYyPJtRT5rg"
		}
	}
};