import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-public-landing',
  templateUrl: './public-landing.component.html',
  styleUrls: ['./public-landing.component.css'],
})
export class PublicLandingComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit(): void {}
  gotoLogin() {
   
    this.router.navigate(['/login']);
  }
}
