import { Component, OnInit, Input, Output, Inject, HostListener } from '@angular/core';
import { PropertyPhoto } from '../propertyPhoto'
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { isImage } from '../utils'

export interface ImageViewerData {
	images: PropertyPhoto[];
	activeImage: number;
}

@Component({
  selector: 'app-image-viewer',
  templateUrl: './image-viewer.component.html',
  styleUrls: ['./image-viewer.component.css'],
  host: {
    '(document:keydown)': 'handleKeyboardEvent($event)'
  }
})
export class ImageViewerComponent {

	viewerData: ImageViewerData

	// Scope access
	isImage = isImage

	constructor(@Inject(MAT_DIALOG_DATA) public data: ImageViewerData) {

		this.viewerData = data
	}

	/**
 	 Listen for arrow keys for navigation.
 	 For `keydown` event, we're looking for our left/right arrows:
 		left = 37
		right = 39
	*/
	handleKeyboardEvent(event: KeyboardEvent) { 

		// Left / previous
		if ( event.keyCode == 37 || event.key == "ArrowLeft" ) {

			this.previous()

		// Right / next
		} else if ( event.keyCode == 39 || event.key == "ArrowRight" ) {

			this.next()

		}

	}

	/**
 	 Get the main showing image by index
	*/
	getActiveImage() {
		let idx = Number(this.viewerData.activeImage)
		return this.viewerData.images[idx]
	}

	/**
 	 Decrement active image index to rotate through
 	 the image carousel
	*/
	previous() {

		let idx = Number(this.viewerData.activeImage)
		if ( idx == 0 ) {
			idx = this.viewerData.images.length - 1
		} else {
			idx -= 1
		}

		this.viewerData.activeImage = idx
		return this.viewerData.images[idx].url

	}

	/**
	 Increment active image index to rotate through
 	 the image carousel
	*/
	next() {

		let idx = Number(this.viewerData.activeImage)
		if ( idx == this.viewerData.images.length - 1 ) {
			idx = 0
		} else {
			idx += 1
		}

		this.viewerData.activeImage = idx
		return this.viewerData.images[idx].url

	}

}