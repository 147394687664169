import { Component, Inject } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.css']
})
export class ConfirmComponent {

	list: string[] = []
	message: string = "Are you sure?"
	confirmButtonText = "YES"
	cancelButtonText = "CANCEL"

	constructor( 
		@Inject(MAT_DIALOG_DATA) private data: any,
		private dialogRef: MatDialogRef<ConfirmComponent>) {
    

		if (data) {
		    this.message = data.message || this.message;
		    this.list = data.list || this.list

			if (data.buttonText) {
				this.confirmButtonText = data.buttonText.ok || this.confirmButtonText;
				this.cancelButtonText = data.buttonText.cancel || this.cancelButtonText;
			}
		}
  }

  onConfirmClick(): void {
    this.dialogRef.close( { confirmed: true } );
  }
  onCloseClick(){
	this.dialogRef.close();
  }

}