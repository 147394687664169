<div style="position: relative;">
    <div class="client">
        <img src=" assets/home-icon.svg" alt="Image" class="homelog" tabindex="0">
        <span class="floatText" tabindex="0"> Properties</span>
        <span class="line"></span>
    </div>

    <div class="row handleForm">
        <button class="btn btn-text btnAgent" tabindex="0"> Search</button>

        <div class="row">
            <div class="col-md-4 Colpad">
                <div class="form-group" align="left">
                    <label class="mt-3 labelName" tabindex="0">Square Feet</label>
                    <input type="text" class=" mt-3 inputLine form-control" id="squareFeet" aria-labelledby="squareFeet" style="background:transparent ;" tabindex="0" />
                    <label class="mt-5 labelName" tabindex="0">Price Range</label>
                    <input type="text" class=" mt-3 inputLine form-control" id="PriceRange" aria-labelledby="PriceRange" style="background:transparent ;" tabindex="0" />
                    <label class="mt-5 labelName">MLS#</label>
                    <input type="text" class=" mt-3 inputLine form-control" id="MLS" aria-labelledby="MLS" style="background:transparent ;" tabindex="0" />
                </div>
            </div>
            <div class="col-md-4 Colpad ">
                <div class="form-group" align="left">
                    <label class="mt-3 labelName" tabindex="0">Bedrooms</label>
                    <input type="text" class=" mt-3 inputLine form-control" id="bedrooms" aria-labelledby="bedrooms" style="background:transparent;" tabindex="0" />
                    <label class="mt-5 labelName" tabindex="0">Address</label>
                    <input type="text" class=" mt-3 inputLine form-control" id="address" aria-labelledby="address" style="background:transparent;" tabindex="0" />
                    <!-- <label class="mt-5 labelName" tabindex="0">View</label>
                    <input type="text" class=" mt-3 inputLine form-control" id="view" aria-labelledby="view" style="background:transparent;" tabindex="0" /> -->
                </div>
            </div>
            <div class="col-md-4">
                <div class="form-group" align="left">
                    <label class="mt-3 labelName" tabindex="0">Bathrooms</label>
                    <input type="text" class=" mt-3 inputLine form-control" id="bathrooms" aria-labelledby="bathrooms" style="background:transparent;" tabindex="0" />
                    <label class="mt-5 labelName" tabindex="0">Year Built</label>
                    <input type="text" class=" mt-3 inputLine form-control" id="yearBuilt" aria-labelledby="yearBuilt" style="background:transparent;" tabindex="0" />
                </div>
            </div>
        </div>


        <div class="row signBtn" align="center">
            <div class="col-md-6">
                <button type="submit" class="btn btnColor form-control" tabindex="0"> SEARCH </button>
            </div>
            <div class="col-md-6">
                <button type="submit" class="btn  signup form-control" tabindex="0"> CREATE FILTERS </button>
            </div>
        </div>
    </div>


    <div class="propSearch">
        <button class="btn btn-text search" tabindex="0">Listing Results</button>
        <span class="searchLine"></span>

        <div class="popUp">
            <div class="rightTour">
                <span class="editprofile" style="position: relative;" tabindex="0">ADD To Tour <i class="bi bi-plus-square plusicon"
                        style="color:#0C4675;padding-left: 2px;"></i></span>
            </div>
        </div>

        <div class="popupResults">
            <div class="contentBox">

                <table style="width:100%;padding-bottom: 40px;" aria-describedby="search new properties">

                    <tr class="filterHead">
                        <th>
                            <input type="checkbox">
                        </th>
                        <th>Address</th>
                        <th>Status</th>
                        <th>Price</th>
                        <th>$Change</th>
                        <th>Beds/Baths</th>
                        <th>Sqft</th>
                        <th>Lot</th>
                        <th>Year Built</th>
                        <th>Dom</th>
                        <th>Agrnt</th>
                        <th>Firm</th>
                        <th>MLS#</th>
                    </tr>


                    <tr class="filterBody">
                        <td>
                            <input type="checkbox">
                        </td>
                        <td>250 Village Boulevard</td>
                        <td>A</td>
                        <td>$4,500,000</td>
                        <td>-</td>
                        <td>0/NaN</td>
                        <td>10152</td>
                        <td>.0826</td>
                        <td>1975</td>
                        <td>432</td>
                        <td>Chris Downton</td>
                        <td>Lakeshore Realty</td>
                        <td>1009796</td>
                        <span class="tableLine"></span>
                    </tr>

                    <tr class="filterBody">
                        <td>
                            <input type="checkbox">
                        </td>
                        <td>250 Village Boulevard</td>
                        <td>A</td>
                        <td>$4,500,000</td>
                        <td>-</td>
                        <td>0/NaN</td>
                        <td>10152</td>
                        <td>.0826</td>
                        <td>1975</td>
                        <td>432</td>
                        <td>Chris Downton</td>
                        <td>Lakeshore Realty</td>
                        <td>1009796</td>
                        <span class="tableLine"></span>
                    </tr>


                    <tr class="filterBody">
                        <td>
                            <input type="checkbox">
                        </td>
                        <td>250 Village Boulevard</td>
                        <td>A</td>
                        <td>$4,500,000</td>
                        <td>-</td>
                        <td>0/NaN</td>
                        <td>10152</td>
                        <td>.0826</td>
                        <td>1975</td>
                        <td>432</td>
                        <td>Chris Downton</td>
                        <td>Lakeshore Realty</td>
                        <td>1009796</td>
                        <span class="tableLine"></span>
                    </tr>

                    <tr class="filterBody">
                        <td>
                            <input type="checkbox">
                        </td>
                        <td>250 Village Boulevard</td>
                        <td>A</td>
                        <td>$4,500,000</td>
                        <td>-</td>
                        <td>0/NaN</td>
                        <td>10152</td>
                        <td>.0826</td>
                        <td>1975</td>
                        <td>432</td>
                        <td>Chris Downton</td>
                        <td>Lakeshore Realty</td>
                        <td>1009796</td>
                        <span class="tableLine"></span>
                    </tr>


                    <tr class="filterBody">
                        <td>
                            <input type="checkbox">
                        </td>
                        <td>250 Village Boulevard</td>
                        <td>A</td>
                        <td>$4,500,000</td>
                        <td>-</td>
                        <td>0/NaN</td>
                        <td>10152</td>
                        <td>.0826</td>
                        <td>1975</td>
                        <td>432</td>
                        <td>Chris Downton</td>
                        <td>Lakeshore Realty</td>
                        <td>1009796</td>
                        <span class="tableLine"></span>
                    </tr>

                    <tr class="filterBody">
                        <td>
                            <input type="checkbox">
                        </td>
                        <td>250 Village Boulevard</td>
                        <td>A</td>
                        <td>$4,500,000</td>
                        <td>-</td>
                        <td>0/NaN</td>
                        <td>10152</td>
                        <td>.0826</td>
                        <td>1975</td>
                        <td>432</td>
                        <td>Chris Downton</td>
                        <td>Lakeshore Realty</td>
                        <td>1009796</td>
                        <span class="tableLine"></span>
                    </tr>


                    <tr class="filterBody">
                        <td>
                            <input type="checkbox">
                        </td>
                        <td>250 Village Boulevard</td>
                        <td>A</td>
                        <td>$4,500,000</td>
                        <td>-</td>
                        <td>0/NaN</td>
                        <td>10152</td>
                        <td>.0826</td>
                        <td>1975</td>
                        <td>432</td>
                        <td>Chris Downton</td>
                        <td>Lakeshore Realty</td>
                        <td>1009796</td>
                        <span class="tableLine"></span>
                    </tr>

                    <tr class="filterBody">
                        <td>
                            <input type="checkbox">
                        </td>
                        <td>250 Village Boulevard</td>
                        <td>A</td>
                        <td>$4,500,000</td>
                        <td>-</td>
                        <td>0/NaN</td>
                        <td>10152</td>
                        <td>.0826</td>
                        <td>1975</td>
                        <td>432</td>
                        <td>Chris Downton</td>
                        <td>Lakeshore Realty</td>
                        <td>1009796</td>
                        <span class="tableLine"></span>
                    </tr>

                    <tr class="filterBody">
                        <td>
                            <input type="checkbox">
                        </td>
                        <td>250 Village Boulevard</td>
                        <td>A</td>
                        <td>$4,500,000</td>
                        <td>-</td>
                        <td>0/NaN</td>
                        <td>10152</td>
                        <td>.0826</td>
                        <td>1975</td>
                        <td>432</td>
                        <td>Chris Downton</td>
                        <td>Lakeshore Realty</td>
                        <td>1009796</td>
                        <span class="tableLine"></span>
                    </tr>

                    <tr class="filterBody">
                        <td>
                            <input type="checkbox">
                        </td>
                        <td>250 Village Boulevard</td>
                        <td>A</td>
                        <td>$4,500,000</td>
                        <td>-</td>
                        <td>0/NaN</td>
                        <td>10152</td>
                        <td>.0826</td>
                        <td>1975</td>
                        <td>432</td>
                        <td>Chris Downton</td>
                        <td>Lakeshore Realty</td>
                        <td>1009796</td>
                        <span class="tableLine"></span>
                    </tr>

                    <tr class="filterBody">
                        <td>
                            <input type="checkbox">
                        </td>
                        <td>250 Village Boulevard</td>
                        <td>A</td>
                        <td>$4,500,000</td>
                        <td>-</td>
                        <td>0/NaN</td>
                        <td>10152</td>
                        <td>.0826</td>
                        <td>1975</td>
                        <td>432</td>
                        <td>Chris Downton</td>
                        <td>Lakeshore Realty</td>
                        <td>1009796</td>
                        <span class="tableLine"></span>
                    </tr>
                </table>
            </div>
        </div>


        <div class="row showmore" align="center">
            <div class="col-md-6 ">
                <button type="submit" class="btn  signup form-control"> SHOW MORE </button>
            </div>
        </div>
    </div>





    <!-- <div class="contentBox">
    <div class="filter">

        <div class="row">
            <div class="col-md-2">
                <input type="text" class=" mt-3 inputLine form-control" placeholder="Filter"
                    style="background:transparent ;" />
            </div>
            <div class="col-md-2 actions">
                <img src=" assets/trash.svg" alt="Image">
                <img src=" assets/trash.svg" style="padding-left: 10px;" alt="Image">
            </div>
        </div>


        <div class="row filterHead">
            <div class="col-md-1">
                <label class="container">
                    <input type="checkbox">
                    <span class="checkmark"></span>
                </label>
            </div>

            <div class="col-md-3">
                User Name
                <img src="assets/arrow.svg" alt="" style="padding-left: 40px;">
            </div>

            <div class="col-md-2">
                Email ID
                <img src="assets/arrow.svg" alt="" style="padding-left: 40px;">
            </div>

            <div class="col-md-2">
                Effective Date
                <img src="assets/arrow.svg" alt="" style="padding-left: 40px;">
            </div>

            <div class="col-md-2">
                Timframe
                <img src="assets/arrow.svg" alt="" style="padding-left: 40px;">
            </div>

            <div class="col-md-1">
                Tour
            </div>

            <div class="col-md-1">
                Delete
            </div>
        </div>


        <div class="row  filterBody" style="margin-top: -10px;">
            <div class="col-md-1">
                <label class="container">
                    <input type="checkbox">
                    <span class="checkmark"></span>
                </label>
            </div>

            <div class="col-md-3">
                <img src="assets/avatar.jpg" alt="" width="15%" style="border-radius: 50%;">
                <label style="padding-left: 40px;"> Jane Doe</label>
            </div>

            <div class="col-md-2">
                jdoe2@email.com
            </div>

            <div class="col-md-2">
                9.30am 01/01/20
            </div>

            <div class="col-md-2">
                Immediately
                <img src="assets/avatar.jpg" alt="" width="8%" style="border-radius: 50%;">
            </div>

            <div class="col-md-1">
                <img src="assets/trash.svg" alt="" width="30%">
            </div>

            <div class="col-md-1">
                <img src="assets/trash.svg" alt="" width="30%">
            </div>
            <span class="rowLine"></span>
        </div>



        <div class="row  filterBody" style="margin-top: -10px;">
            <div class="col-md-1">
                <label class="container">
                    <input type="checkbox">
                    <span class="checkmark"></span>
                </label>
            </div>

            <div class="col-md-3">
                <img src="assets/avatar.jpg" alt="" width="15%" style="border-radius: 50%;">
                <label style="padding-left: 40px;"> Jane Doster lll</label>
            </div>

            <div class="col-md-2">
                jdoester3@email.com
            </div>

            <div class="col-md-2">
                9.30am 01/01/20
            </div>

            <div class="col-md-2">
                Within a Year
                <img src="assets/avatar.jpg" alt="" width="8%" style="border-radius: 50%;">
            </div>

            <div class="col-md-1">
                <img src="assets/trash.svg" alt="" width="30%">
            </div>

            <div class="col-md-1">
                <img src="assets/trash.svg" alt="" width="30%">
            </div>
            <span class="rowLine"></span>
        </div>


        <div class="row  filterBody" style="margin-top: -10px;">
            <div class="col-md-1">
                <label class="container">
                    <input type="checkbox">
                    <span class="checkmark"></span>
                </label>
            </div>

            <div class="col-md-3">
                <img src="assets/avatar.jpg" alt="" width="15%" style="border-radius: 50%;">
                <label style="padding-left: 40px;"> Mark Miller</label>
            </div>

            <div class="col-md-2">
                mark2342@email.com
            </div>

            <div class="col-md-2">
                9.30am 01/01/20
            </div>

            <div class="col-md-2">
                Immediately
                <img src="assets/avatar.jpg" alt="" width="8%" style="border-radius: 50%;">
            </div>

            <div class="col-md-1">
                <img src="assets/trash.svg" alt="" width="30%">
            </div>

            <div class="col-md-1">
                <img src="assets/trash.svg" alt="" width="30%">
            </div>
            <span class="rowLine"></span>
        </div>

        <div class="row  filterBody" style="margin-top: -10px;">
            <div class="col-md-1">
                <label class="container">
                    <input type="checkbox">
                    <span class="checkmark"></span>
                </label>
            </div>

            <div class="col-md-3">
                <img src="assets/avatar.jpg" alt="" width="15%" style="border-radius: 50%;">
                <label style="padding-left: 40px;"> James Dean</label>
            </div>

            <div class="col-md-2">
                jdoe2@email.com
            </div>

            <div class="col-md-2">
                9.30am 01/01/20
            </div>

            <div class="col-md-2">
                Within a Month
                <img src="assets/avatar.jpg" alt="" width="8%" style="border-radius: 50%;">
            </div>

            <div class="col-md-1">
                <img src="assets/trash.svg" alt="" width="30%">
            </div>

            <div class="col-md-1">
                <img src="assets/trash.svg" alt="" width="30%">
            </div>
            <span class="rowLine"></span>
        </div>
    </div>
</div> -->