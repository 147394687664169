<mat-dialog-content>
	<!-- <span  mat-raised-button mat-dialog-close tabindex="-1" class="popup-cancel-button" (click)="onCancelClick($event)">&#x2715;</span> -->
	<img _ngcontent-esa-c420="" src="assets/close-icon.png" alt="Close Icon" title="Close" class="popup-cancel-button close-icon" (click)="onCancelClick($event)">
	<p class="message-heading">
		{{message}}
	</p>
	<div class="selection-content">
	<mat-selection-list #selection [multiple]="false" [(ngModel)]="selectionChoice">
	  <mat-list-option *ngFor="let item of list" [value]="item">
	    {{item}}
	  </mat-list-option>
	</mat-selection-list>
	</div>
</mat-dialog-content>

<mat-dialog-actions align="center">
	<button mat-raised-button [disabled]="positiveButtonDisabled()" color="primary" (click)="onConfirmClick(selection)" tabindex="1" class="btn-primary">{{confirmButtonText}}</button>

	<button mat-raised-button mat-dialog-close *ngIf="extraButtonText != null" (click)="onExtraButton()" tabindex="-2">{{extraButtonText}}</button>
</mat-dialog-actions>