import { Injectable, OnInit } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

import { User } from '../user'
import { HttpClient,  HttpErrorResponse } from '@angular/common/http';
import { Authorization } from '../authorization'
import { PropertySearch, PropertyFilter, Property } from '../propertySearch'
import { LoginService } from './login.service'
import { BasicObject } from '../basicObject'
import { environment } from '../../environments/environment'
import { ServiceErrors } from './service-errors'

@Injectable({
  providedIn: 'root'
})
export class PhotosService {

	private graphApi:string = environment.graphApi;

	authorization: Authorization



	constructor( private loginService:LoginService, private http:HttpClient ) { 
		this.checkAuth()
	}

	checkAuth () {
		this.authorization = this.loginService.getAuthorization()
		if ( !this.authorization?.token ) { console.error("No auth token present") }
	}
	
	/**
 	 Send an avatar image to the server
	*/
	uploadAvatar(data, clientId, agentId) {


		const fileExtension = (s) => {

			const lastDot = s.lastIndexOf('.');

			const filename = s.substring(0, lastDot);
			const ext = s.substring(lastDot + 1);

			return {
				filename : filename,
				extension: ext
			}
		}

		if ( data == null ) {
			return
		}
		
		this.checkAuth()

		let path = clientId == null ? environment.restApi.agentAvatarUpload : environment.restApi.clientAvatarUpload
		let idKey = clientId == null ? "x-metadata-agent-id" : "x-metadata-client-id"
		let idValue = clientId == null ? agentId : clientId

	  	let options = { 
	  		headers : { 
  				"Authorization" : `Bearer ${this.authorization.token}`,
  				"x-metadata-file-extension" : fileExtension(data.name).extension
	  		}
	  	}

	  	options.headers[idKey] = idValue

		const formData: FormData = new FormData();

		formData.append('image', data);
	  	
	  	return this.http.post(path, formData, options )
	  		.pipe(
	  			catchError(ServiceErrors.handleError)
	  		)
	}

}
