import { Component, OnInit, Input } from '@angular/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';

import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { LoginService } from '../services/login.service'

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.css']
})


export class SidenavComponent implements OnInit {

	@Input()
	drawer: any

	constructor(
		private router: Router, 
		private loginService: LoginService,
		private route: ActivatedRoute
		) { 

	}

	ngOnInit(): void {

	}

	onDashboard() {
		this.drawer.close()
		this.router.navigate(['/dashboard'], { queryParams: this.route.snapshot.queryParams })
	}

	onClients() {
		this.drawer.close() 
		this.router.navigate(['/clients'], { queryParams: this.route.snapshot.queryParams })
	}
	onReports() {
		this.drawer.close()
		this.router.navigate(['/reports'], { queryParams: this.route.snapshot.queryParams })
	}

	onTours() {
		this.drawer.close() 
		this.router.navigate(['/tours'], { queryParams: this.route.snapshot.queryParams })
	}

	onProperties() {
		this.drawer.close() 
		this.router.navigate(['/properties'], { queryParams: this.route.snapshot.queryParams })
	}

	onSignOut() {

		this.loginService.logout()

		this.drawer.close()
		this.router.navigate(['/login'], { queryParams: this.route.snapshot.queryParams })
	}

}
