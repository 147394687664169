import { Input, Component, OnInit, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { ImageViewerData } from '../image-viewer/image-viewer.component'
import { PropertyPhoto } from '../propertyPhoto'
import { isImage } from '../utils'

@Component({
  selector: 'app-image-viewer-element',
  templateUrl: './image-viewer-element.component.html',
  styleUrls: ['./image-viewer-element.component.css']
})
export class ImageViewerElementComponent implements OnInit, OnChanges {

  // For use as an element and not a dialog
  @Input()
  images: [PropertyPhoto]

  @Input()
  thumbnailIndex: number

  viewerData: ImageViewerData

  enlargedImage: string | null = null;

  enlargedImageSlider: string | null = null;
  popupIndex: number;

  isImage = isImage
 
  constructor() { 
  }



  ngOnInit(): void {
    this.viewerData = {
      images: this.images,
      activeImage: 0
    }

    

  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.viewerData != null && this.viewerData != undefined) {
      
      if (!Number.isNaN(Number(changes['thumbnailIndex']?.currentValue))) {
        this.viewerData.activeImage = Number(changes['thumbnailIndex'].currentValue)
      }


      if (changes['images']?.currentValue != null) {
        this.viewerData.images = changes['images'].currentValue
      }

    }

    if (this.viewerData != null && this.viewerData != undefined) {
      this.viewerData.images
    }
  }
  currentSlide(index:number){
    this.popupIndex =index
  }

  /**
   Listen for arrow keys for navigation.
   For `keydown` event, we're looking for our left/right arrows:
    left = 37
    right = 39
  */
  handleKeyboardEvent(event: KeyboardEvent) { 

    // Left / previous
    if ( event.keyCode == 37 || event.key == "ArrowLeft" ) {

      this.previous()

    // Right / next
    } else if ( event.keyCode == 39 || event.key == "ArrowRight" ) {

      this.next()

    }

  }

  /**
   Get the main showing image by index
  */
  getActiveImage() {

    if ( this.viewerData.images.length < 1 ) {
      return {
        mediaKey: null,
        url: "https://livepad.pro/livepad/assets/livepad-logo-black-flat.png",
        displayOrder: 1,
        type: "png",
        longDescription: null,
        category: null
      }
    }

    let idx = Number(this.viewerData.activeImage)
    return this.viewerData.images[idx]
  }

  enlargeImage(imageUrl: string): void {
    this.enlargedImage = imageUrl;
  }
  enlargeImageSlider(imageUrl: string, i:number): void {
    this.enlargedImageSlider = imageUrl;
    this.popupIndex = Number(i); 
  }

  closeEnlargedImage(): void {
    this.enlargedImage = null;
  }
  closeEnlargedImageSlider(): void {
    this.enlargedImageSlider = null;
    this.popupIndex = null;
  }

  /**
   Decrement active image index to rotate through
   the image carousel
  */
  previous() {

    let idx = Number(this.viewerData.activeImage)
    if ( idx == 0 ) {
      idx = this.viewerData.images.length - 1
    } else {
      idx -= 1
    }

    this.viewerData.activeImage = idx
    return this.viewerData.images[idx].url

  }

  /**
   Increment active image index to rotate through
   the image carousel
  */
  next() {

    let idx = Number(this.viewerData.activeImage)
    if ( idx == this.viewerData.images.length - 1 ) {
      idx = 0
    } else {
      idx += 1
    }

    this.viewerData.activeImage = idx
    return this.viewerData.images[idx].url

  }
}

 