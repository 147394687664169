import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PropertyPhoto } from '../propertyPhoto'
import { isImage } from '../utils'
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-image-row',
  templateUrl: './image-row.component.html',
  styleUrls: ['./image-row.component.css']
})
export class ImageRowComponent implements OnInit {

  @Input()
  images: PropertyPhoto[]

  // @Output()
  // onImageClick: EventEmitter<number> = new EventEmitter<number>()

  @Output() onImageClick: EventEmitter<string> = new EventEmitter<string>();

  @Input()
  selected: false

  enlargedImageSlider: string | null = null;
  popupIndex: number;

  enlargedVideo: string | null = null;
  popupVideoIndex:number;

  // Scope access
  isImage = isImage

  constructor() { }

  ngOnInit(): void {
  }
  currentSlide(index:number){
    this.popupIndex =index
  }
  // imageClick(index) {
  //   this.onImageClick.emit(index)
  // }
  imageClick(index: number): void {
    const clickedImage = this.images[index];
    if (clickedImage.type === 'jpg') {
      this.onImageClick.emit(clickedImage.url);
    }
  }
  enlargeImageSlider(imageUrl: string, i:number): void {
    this.enlargedImageSlider = imageUrl;
    this.popupIndex = Number(i); 
  }
  enlargeVideo(imageUrl: string, j:number): void {
    this.enlargedVideo = imageUrl;
    this.popupVideoIndex = Number(j);
  }
  closeEnlargedImageSlider(): void {
    this.enlargedImageSlider = null;
    this.popupIndex = null;
  }
  closeEnlargedvideo(): void {
    this.enlargedVideo = null;
    this.popupVideoIndex = null;
  }

}

