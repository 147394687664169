<div class="content-container">
  <div class="title">
    <img src=" assets/dashboard-icon.svg" alt="Image" width="40px">
    <h1 tabindex="0">My Tours</h1>
  </div>
 <div class="tourContent">
  <p class="mt-4 mb-4" tabindex="0">Create, manage and schedule tour times here!</p>
  <div align="center">
  <div class="content-wrapper">
   <div class="row">
  <p class="createTour" tabindex="0">Create a Tour</p>
         <div class="form-group" align="left">
          <label class="mt-3 label" tabindex="0">Tour Name <span style="color: red">*</span></label>
           <input type="text" [(ngModel)]="newTour.name" name="name" placeholder="Tour name" class="inputLine form-control transparent-bg" id="tourName" aria-labelledby="tourName" tabindex="0">
            <label class="mt-3 label" tabindex="0">Description <span style="color: red">*</span></label>
          <input type="text" [(ngModel)]="newTour.description" name="description" placeholder="Description" value="" class="inputLine form-control transparent-bg" id="description" aria-labelledby="description" tabindex="0">
          <span class="error">{{error}}</span>
         </div>
   </div>
        <div class="twoBtns mt-4"> 
          <button type="submit" class="custom-primary-btn" (click)="onConfirmCreation()" tabindex="0">CREATE</button>
         <button type="submit" class="custom-cancel-btn" (click)="onCancelCreation()" tabindex="0">CANCEL</button>
         </div>
         </div>
     </div>
    </div>
 
</div>
<app-property-list *ngIf="showTrailingProperties()" [properties]="trailingProperties" [showCheckbox]="false" [showAddToTours]="false">
</app-property-list>
<app-spinner [show]="showSpinner"></app-spinner>
<app-checkmark *ngIf="showDone"></app-checkmark>
