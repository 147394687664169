<div class="handleBody">
    <div class="bodyContent">
        <div class="client" style="position: relative;">
            <img src=" assets/home-icon.svg" alt="Image" class="homelog" style="position: relative;">
            <span class="floatText" style="position: relative;">Michael Matten</span>
            <span class="editprofile" style="position: relative;">EDIT <i class="bi bi-pencil-square"
                    style="color:#0C4675;padding-left: 2px;"></i></span>
            <span class="line"></span>
        </div>
    </div>
    <div class="clientForm">
        <div class="row" style="position: relative;">

            <div class="col-md-2">
                <div class="imagePlacing" align="center">
                    place here image logo
                </div>
                <p class="mt-2 image">Upload Image</p>
            </div>


            <div class="col-md-10 formText">
                <div class="row firstrow ">

                    <div class="col-md-4 ">
                        <div class="form-group" align="left">
                            <label class="mt-3">First Name</label>
                            <input type="text" class=" mt-3 inputLine form-control" style="background:transparent ;" />
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="form-group" align="left">
                            <label class="mt-3">Last Name</label>
                            <input type="text" class=" mt-3 inputLine form-control" style="background:transparent ;" />
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="form-group" align="left">
                            <label class="mt-3">Phone</label>
                            <input type="text" class=" mt-3 inputLine form-control" style="background:transparent ;" />
                        </div>
                    </div>
                </div>


                <div class="row firstrow mt-4">
                    <div class="col-md-4 ">
                        <div class="form-group" align="left">
                            <label class="mt-3">Emial</label>
                            <input type="text" class=" mt-3 inputLine form-control" style="background:transparent ;" />
                        </div>

                        <div class="col-md-6">
                            <label class="mt-3">ZIP Code</label>
                            <input type="text" class=" mt-3 inputLine form-control" style="background:transparent ;" />
                        </div>
                    </div>


                    <div class="col-md-4">
                        <div class="form-group" align="left">
                            <label class="mt-3">Street Address</label>
                            <input type="text" class=" mt-3 inputLine form-control" style="background:transparent ;" />
                        </div>
                    </div>

                    <div class="col-md-4 ">
                        <div class="row">
                            <div class="col-md-6">
                                <label class="mt-3">City</label>
                                <input type="text" class=" mt-3 inputLine form-control"
                                    style="background:transparent ;" />
                            </div>

                            <div class="col-md-6">
                                <label class="mt-3">State</label>
                                <input type="text" class=" mt-3 inputLine form-control"
                                    style="background:transparent ;" />
                            </div>

                        </div>
                    </div>
                </div>

                <div class="row" style="position: relative;">
                    <span class="touring" style="position: relative;">Memberships
                        <i class="bi bi-plus-square plusicon"></i></span>
                    <span class="lineProp"></span>


                    <div class="row location">
                        <div style="margin-bottom: 20px;">
                            <img src="assets/locate-icon.svg" alt="Image" width="2%" style="opacity: 1;">
                            <span class="verticalLine"></span>

                            <img src="assets/locate-icon.svg" alt="Image" width="2%"
                                style="opacity: 1;margin-left: 20px;">
                            <span class="verticalLine"></span>
                        </div>

                        <div style="margin-bottom: 20px;">
                            <span>Incline Village Realtors</span>
                            <span class="verticalLine"></span>
                            <span style="opacity: 1;margin-left: 20px;">Vigilante Realtors</span>
                            <span class="verticalLine"></span>
                            <span style="opacity: 1;margin-left: 20px;">FSBO Realtors</span>
                            <span class="verticalLine"></span>
                        </div>
                    </div>
                </div>


                <div class="row" style="position: relative;">
                    <span class="touring" style="position: relative;">License
                        <i class="bi bi-plus-square plusicon"></i></span>
                    <span class="lineProp"></span>
                </div>

                <div class="row location">
                    <div style="margin-bottom: 20px;">
                        <span>#125478M23456</span>
                        <span class="verticalLine"></span>

                        <span style="opacity: 1;margin-left: 20px;">State of MIchigan</span>
                        <span class="verticalLine"></span>
                    </div>

                    <div class="row  twoBtns">
                        <div class="col-md-6">
                            <button type="submit" class="btn btnColor form-control" (click)="saveProfile()">
                                SAVE </button>
                        </div>
                        <div class="col-md-6">
                            <button type="submit" class="btn signup form-control">
                                CANCEL
                            </button>

                        </div>
                    </div>

                </div>

            </div>
        </div>
    </div>