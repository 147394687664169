import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
  selector: 'app-snackbar-message',
  template: `
  <div class="d-flex align-items-center" style="font-size: 14px;">
    <div class="text-center">
      File format not supported. <br>
      You can upload only jpg, jpeg, png extension file.
    </div>
    <div (click)="closeSnackbar()" style="color:#df3079" class="btn btn-sm px-3">Close</div>
  </div>
    `,
  standalone: true
})
export class SnackbarMessageComponent {
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
    private snackBarRef: MatSnackBarRef<SnackbarMessageComponent>
  ) {}

  closeSnackbar(): void {
    this.snackBarRef.dismiss();
  }
}