<ngx-spinner 
	bdColor = "rgba(0, 0, 0, 0.8)" 
	color = "rgb(0,128,0)"
	size = "large" 
	type = "timer" 
	[fullScreen] = "true">
		<p style="color: white" > Loading... </p>
</ngx-spinner>

<!-- 
	template="<img width='750px' src='./assets/livepad_loading_750.gif' />" -->