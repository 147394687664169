import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LoginService } from '../services/login.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import {  PasswordValidator } from '../validators/password.validator';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
  newPassword: string;
  confirmPassword: string;
  token: string;
  showSpinner = true;
  passwordForm: FormGroup;
  tokenValid: boolean = false;
  showPassword:boolean = false;
  showNewPassword:boolean = false;
  constructor(
    private route: ActivatedRoute,
    private loginService: LoginService,
    private snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    private router: Router,
  ) {
    this.route.params.subscribe(params => {
      this.token = params['token'];
    });
    this.loginService.resetPassword('', this.token).subscribe(response => {
      this.tokenValid = !!response['data']?.updatePassword;
      this.showSpinner = false;
    });
    this.passwordForm = this.formBuilder.group({
      newPassword: ['', [Validators.required, Validators.minLength(8),PasswordValidator.validate]],
      confirmPassword: ['', Validators.required],
      // confirmPasswordValidator
    });
  }

  ngOnInit() {
   
   
  }
  get getPassForm() :{ [key: string]: AbstractControl }{
    return this.passwordForm.controls;
  }

  toggleFieldTextType(){
    this.showPassword=!this.showPassword;
  }
  toggleNewFieldType(){
    this.showNewPassword=!this.showNewPassword;
  }
  onSubmit() {
    this.showSpinner = true;
    const confirmPassword = this.passwordForm.get('confirmPassword').value;
    const newPassword = this.passwordForm.get('newPassword').value;

    if (newPassword !== confirmPassword) {
      this.showSpinner = false;
      const mismatchMessage = 'Passwords do not match!';
      this.snackBar.open(mismatchMessage, 'Close', {
        duration: 5000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
      return;
    }
    this.loginService.resetPassword(newPassword, this.token)
      .subscribe(response => {
        this.showSpinner = false;
        let message = '';
        if (response["errors"] !== undefined) {
          message = response["errors"][0].message;
        } else {
          // message = `${response['data'].updatePassword.message}\nPlease log in again with your new credentials`;
          message = `Password Changed Successfully!\nPlease login again with your new credentials.`;

                    // message += 'Please log in again with your new credentials';
        }
  
        this.snackBar.open(message, 'Close', {
          duration: 5000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
          panelClass: ['multiline-snackbar']
        });

        this.router.navigate(['/login'], { queryParams: this.route.snapshot.queryParams });
      });
  }
}