import { ShowingRequestStatus, ShowingRequestInterface, Showing, ShowingConfirmationRequest } from './showing'
import { Actions } from './actions'
import { LoginService } from './services/login.service'
import { Authorization } from './authorization'


/**
	 Compare against video or image types. 
*/
function isImage(tile):boolean {

	let videoTypes = [
		"mov",
		"mp4",
		"mpeg",
		"quicktime",
		"wmv",
		"m4v"
	]

	// Check for video
	if ( videoTypes.includes(tile.type.toLowerCase()) ) {
		return false
	} 

	if ( tile.category?.toLowerCase().includes('video') ) {
		return false
	}
		
	if ( tile.isTour == 1 ) {

		return false
	}

	return true

}

function dateFromEpochTime ( epochTime: string ): Date {
	return new Date( Number(epochTime) )
}
function epochTimeFromDate ( date: Date ): string {
	return `${Number(date)}`
}

function formControlValueFromDate ( date: Date ): string {
	return date.getFullYear() +
	    '-' + pad(date.getMonth() + 1) +
	    '-' + pad(date.getDate()) +
	    'T' + pad(date.getHours()) +
	    ':' + pad(date.getMinutes())
}

function pad(number) {
  if (number < 10) {
    return '0' + number;
  }
  return number;
}


function checkForAuthError( errors, router ) {

	let ret = errors[0].message ?? "Unknown error."

	// Check for auth issues
	if ( ret?.includes("malform") || ret?.includes("auth") ) {
		router.navigate(['/login'])
		return
	}

	return ret
}

/**
	 Format the timestamp into a date string of type YYYY-MM-DD
*/
function formatTourDate(date) {

	if ( date == null ) { return "" }

	let today = new Date()

	// Convert based on type provided from database. Either a timestamp or a date string.
	if ( !isValidDate(new Date(date)) ) {
		date = new Date(Number(date))
	} else {
		date = new Date(date)
	}

	let d = date

	// Local time
	d.setMinutes(date.getMinutes() + today.getTimezoneOffset())

	return d
}

function formatConfirmationTime(date) {

	return formatShowingDate(formatTourDate(date))

}

/**
	 Format out date and time for printing
*/
function appointmentDateTime(showingTime) {

	let date = new Date(Number(showingTime))

	var timeOptions = { timeZoneName: 'short', hour: '2-digit', minute:'2-digit', month: 'short', day: '2-digit' } as const;

	return`${date.toLocaleString('en-US',timeOptions)}`

}

/**
	 Format the month / day only
*/
function appointmentDate(showingTime) {


	let date = new Date(Number(showingTime))
	// Fallback for Safari and older browsers
	if (date.toString()=='NaN'||isNaN(date.getTime())|| date.toString() == "Invalid Date") {
		const parts = showingTime.split(' ');
		const dateParts = parts[0].split('-');
		const timePart = parts[1];
		// Construct the date in a YYYY-MM-DD format
		date = new Date(`${dateParts[2]}-${dateParts[0]}-${dateParts[1]} ${timePart}`);
	}
	var timeOptions = { month: 'short', day: '2-digit' } as const;

	return`${date.toLocaleString('en-US',timeOptions)}`

}

function appointmentDate1(showingTime) {

	const userLocale = navigator.language || 'en-US';
// Default to 'en-US' if the language is not available

	let date = new Date(showingTime)
	// Fallback for Safari and older browsers
	if (isNaN(date.getTime())|| date.toString() == "Invalid Date") {
		const parts = showingTime.split(' ');
		const dateParts = parts[0].split('-');
		const timePart = parts[1];
		// Construct the date in a YYYY-MM-DD format
		date = new Date(`${dateParts[2]}-${dateParts[0]}-${dateParts[1]} ${timePart}`);
		// date = new Date(Date.parse(showingTime.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$3-$2-$1')));
	}
	var timeOptions = { month: 'short', day: '2-digit' } as const;

	return`${date.toLocaleString(userLocale,timeOptions)}`

}

/**
	 Format the month / day / year only
*/
function appointmentDateWithYear(showingTime) {


	let date = new Date(Number(showingTime))

	var timeOptions = { month: 'short', day: '2-digit', year: 'numeric' } as const;

	return`${date.toLocaleString('en-US',timeOptions)}`

}

/**
	 Just the time
*/
function appointmentTime(showingTime) {

	let date = new Date(Number(showingTime))

	var timeOptions = { timeZoneName: 'short', hour: '2-digit', minute:'2-digit' } as const;

	return`${date.toLocaleTimeString('en-US',timeOptions)}`
}

/**
	 Format the timestamp into a date string of type HH:MM
*/
function formatShowingDate(date) {


	if ( date == null ) { return "" }

	// Convert based on type provided from database. Either a timestamp or a date string.
	if ( !isValidDate(new Date(date)) ) {
		date = new Date(Number(date))
	} else {
		date = new Date(date)
	}

	let d = date
	return d

	// Pad with leading zero's to fulfull the contracted format
	let hours = `${d.getHours()}`
	if ( hours.length < 2 ) { hours = `0${hours}`}

	let minutes = `${d.getMinutes()}`
	if ( minutes.length < 2 ) { minutes = `0${minutes}`}

	let dateString = `${hours}:${minutes}`

	return dateString
}

function isValidDate(d) {
  return d instanceof Date && !isNaN(d.getTime());
}

function unixTimestampToDate( timestamp: string ): Date {

	if ( timestamp == null ) { throw new Error("Timestamp parameter is required") }

	let asNumber = Number(timestamp)

	return new Date(asNumber * 1000)
}

/**
 Add commas to a number, but usually a price
*/
// function addCommasToPrice(price: string) {
// 	if (  price === undefined) { return price }
// 	if ( price.length < 4 || price === undefined) { return price }
// 	var i = price.length
// 	var counter = 0
// 	while ( i > -1 ) {		
// 		if (counter > 0 && counter % 3 == 0 ) {
// 			let end = price.slice(i,price.length)			
// 			let front = price.slice(0,i)
// 			if ( front.length == 0 ) {
// 				price = `${end}`
// 			} else {
// 				price = `${front},${end}`
// 			}
// 			counter = -1
// 		} else {
// 			i -= 1
// 		}
// 		counter += 1
// 	}
// 	return price
// }

function addCommasToPrice(price: string) {
	if (price === undefined) { return 0 }
	
	// Convert price to a number
	const numericPrice = parseFloat(price);
	
	// Check if the price is valid and non-zero
	if (isNaN(numericPrice) || numericPrice === 0) { return price }
	
	// Convert the price to a string with commas
	// const formattedPrice = numericPrice.toLocaleString();
	const formattedPrice = numericPrice.toLocaleString('en-US');
	
	return formattedPrice;
  }

/**
	 Calculate the bathrooms ( 2.5, 1, etc.)
*/
// function bathroomCalculation(property) {
//     let all = property?.propertyFeatures?.featureData?.BathroomsTotalInteger ?? 0;
//     return `${all}`;
// }
function bathroomCalculation(property) {
	const bathroomsTotal = property?.propertyFeatures?.featureData?.BathroomsTotalInteger;
	if(bathroomsTotal === null || bathroomsTotal === '********'){
		return 'N/A';
	}
	if (typeof bathroomsTotal === 'undefined') {
	  return 'N/A';
	} else if (bathroomsTotal === 0) {
	  return 'N/A';
	} else {
	  return `${bathroomsTotal}`;
	}
  }

/**
 Is this date today?
*/
function isDateToday (date) {

	let today = new Date()

	let year = date.getFullYear()
	let month = date.getMonth()
	let dayOfMonth = date.getDate()

	return today.getFullYear() == year && today.getMonth() == month && today.getDate() == dayOfMonth
}


// Returns the four-digit year corresponding to the ISO week of the date.
Date.prototype['getWeekYear'] = function() {
  var date = new Date(this.getTime());
  date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
  return date.getFullYear();
}

/**
 Returns the ISO week of the date.
 https://weeknumber.net/how-to/javascript#:~:text=To%20get%20the%20ISO%20week,getWeekYear()%20.

*/
Date.prototype['getWeek'] = function() {

	var date = new Date(this.getTime());
	date.setHours(0, 0, 0, 0);

	// Thursday in current week decides the year.
	date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);

	// January 4 is always in week 1.
	var week1 = new Date(date.getFullYear(), 0, 4);

	// Adjust to Thursday in week 1 and count number of weeks from date to week1.
	return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000 - 3 + (week1.getDay() + 6) % 7) / 7);
}

/**
 Does this date occur within this calendar week?
*/
function isDateThisWeek (date) {

	let today = new Date()

	return today['getWeekYear']() == date['getWeekYear']() && today['getWeek']() == date['getWeek']()
}

/**
 Does this date occur within the current month?
*/
function isDateThisMonth (date) {

	let today = new Date()

	let year = date.getFullYear()
	let month = date.getMonth()

	return today.getFullYear() == year && today.getMonth() == month

}

/**
 Has this date already happened?
*/
function hasDatePassed (date) {

	let today = new Date()

	let year = date.getFullYear()
	let month = date.getMonth()
	let dayOfMonth = date.getDate()

	// Earlier year
	if ( today.getFullYear() > year ) { return true }

	// Same or later year, earlier month
	if ( today.getFullYear() <= year && today.getMonth() > month ) { return true }

	// Same or later year & month, earlier day
	if ( today.getFullYear() == year && today.getMonth() == month ) {
		if (today.getDate() > dayOfMonth ) { return true }
		else { return false }
	}

	// Date hasn't passed yet
	return false
}

/**
 Capitalize the first letter of a string
*/
function capitalized (s) {

	if ( s.length == 1 ) { return s.toUpperCase() }

	return s.charAt(0).toUpperCase() + s.slice(1,s.length)
}

/**
 Calculate what is the status of the current request
*/
function showingRequestStatus(showing): ShowingRequestInterface {

	if ( showing.showingTime == null ) {
		return ShowingRequestStatus.notime
	}

	if ( showing.showingConfirmationRequest.length == 0 ) {
		return ShowingRequestStatus.none
	}

	let lastIndex = showing.showingConfirmationRequest.length - 1
	let latestRequest = showing.showingConfirmationRequest[lastIndex]
 
	if ( latestRequest.requestActions.code == Actions.ListingAgentConfirmRequest.code ) {

		return ShowingRequestStatus.countered

	} else if ( latestRequest.confirmed ) {

		return ShowingRequestStatus.confirmed

	} else if ( latestRequest.responded == 1 ) {

		return ShowingRequestStatus.countered
	} else { 

		return ShowingRequestStatus.viewed
	} 
}

/**
 Return null if array is empty instead of throwing an exception.
*/
function firstOf( array ) { 
	if ( array != null && array.length > 0 ) {
		return array[0]
	}

	return null
}


function capitalizeFirstLetter(str: string): string {
	return str.charAt(0).toUpperCase() + str.slice(1);
}

function capitalizeEachWord(str: string): string {
	return str
	  .split(' ')
	  .map(word => word.charAt(0).toUpperCase() + word.slice(1))
	  .join(' ');
}


function formatDate(date) {
	const pad = (num) => (num < 10 ? '0' + num : num);
   
	const year = date.getFullYear();
	const month = pad(date.getMonth() + 1);  // JavaScript months are 0-based.
	const day = pad(date.getDate());
	const hour = pad(date.getHours());
	const minute = pad(date.getMinutes());
	const second = pad(date.getSeconds());
   
	return `${month}-${day}-${year} ${hour}:${minute}:${second}`;
}

function formatDateT(date) {
	const pad = (num) => (num < 10 ? '0' + num : num);
   
	const year = date.getFullYear();
	const month = pad(date.getMonth() + 1);  // JavaScript months are 0-based.
	const day = pad(date.getDate());
	const hour = pad(date.getHours());
	const minute = pad(date.getMinutes());
	const second = pad(date.getSeconds());
   
	return `${year}-${month}-${day}T${hour}:${minute}:${second}`;
}

function validateInput(inputStr) {
	if(!inputStr){
		return ''
	}
    // Define regular expression patterns
    const dateTimePattern = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$|^\d{2}-\d{2}-\d{4} \d{2}:\d{2}:\d{2}$/;
    const numericPattern = /^\d+$/;
 
    // Determine the type of input
    if (dateTimePattern.test(inputStr)) {
        return 'datetime';
    } else if (numericPattern.test(inputStr)) {
        return 'numeric';
    } else {
        return 'invalid';
    }
}

function formatTime(dateTimeString) {
	//let dateTimeString = "03-23-2024 18:24:00";
	  let [date, time] = dateTimeString.split(" ");
	  console.log(time);
	  const [hourString, minute] = time.split(":");
	  const hour = +hourString % 24;
	  let time12 = (hour % 12 || 12) + ":" + minute +" " +(hour < 12 ? "AM" : "PM");
	  let formatDate = date +" "+ time12;
	  return formatDate;
}
function splitOnCapitalLetters(input) {
    // Check if input is null, undefined, or an empty string
    if (!input) return '';
    // Check if all letters in input are already in capital case
    if (input === input.toUpperCase()) return input;
	// Use regex to split the string at capital letters
	return input.replace(/([A-Z])/g, ' $1').trim();
}
function convertToValidFormat(dateStr) {
	let tourDate:Date;//new Date(dateStr);
    // Handle 'MM-DD-YYYY HH:mm:ss' format by converting it to ISO 8601
    if (/^\d{2}-\d{2}-\d{4} \d{2}:\d{2}:\d{2}$/.test(dateStr)) {
	// if (tourDate.toString()=="Invalid Date") {
        const parts = dateStr.split(' ');
        const dateParts = parts[0].split('-');
        const timePart = parts[1];
        // tourDate = new Date(`${dateParts[2]}-${dateParts[0]}-${dateParts[1]} ${timePart}.000Z`);
		tourDate = new Date(`${dateParts[2]}-${dateParts[0]}-${dateParts[1]} ${timePart}`);
		// return `${dateParts[2]}-${dateParts[0]}-${dateParts[1]} ${timePart}`;
    }
    // Handle 'YYYY-MM-DD HH:mm:ss' format directly
    if (/^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/.test(dateStr)) {
        // return new Date(dateStr.replace(' ', 'T') + '.000Z');
		// return dateStr;
		tourDate = new Date(tourDate)
    }
    return tourDate;
}
export { 
	unixTimestampToDate, 
	isValidDate,
	formatTourDate,
	formatShowingDate,
	addCommasToPrice,
	bathroomCalculation,
	isDateToday,
	isDateThisWeek,
	isDateThisMonth,
	capitalized,
	hasDatePassed,
	formatConfirmationTime,
	showingRequestStatus,
	appointmentDateTime,
	appointmentDate,
	appointmentTime,
	dateFromEpochTime,
	epochTimeFromDate,
	formControlValueFromDate,
	firstOf,
	checkForAuthError,
	appointmentDateWithYear,
	isImage,
	capitalizeFirstLetter,
	capitalizeEachWord,
	appointmentDate1,
	formatDate,
	validateInput,
	formatDateT,
	formatTime,
	splitOnCapitalLetters,
	convertToValidFormat
	
}