import { BasicObject } from './basicObject'

export let TrestleKeys: any[] = [
	{
		display: "HOA",
		searchKey: "AssociationYN",
		values: [
			{ 
				display: "Yes",
				searchValue: "true",
			},
			{	
				display: "No",
				searchValue: "false",
			}
		]
	},
	{
		display: "Basement",
		searchKey: "BasementYN",
		values: [
			{ 
				display: "Yes",
				searchValue: "true",
			},
			{	
				display: "No",
				searchValue: "false",
			}
		]
	},
	{
		display: "Cooling",
		searchKey: "Cooling",
		values: [
			{
				"searchValue": "Item1Unit",
				"display": "1 Unit"
			}, {
				"searchValue": "Item2Units",
				"display": "2 Units"
			}, {
				"searchValue": "Item3Units",
				"display": "3+ Units"
			}, {
				"searchValue": "AtticFan",
				"display": "Attic Fan"
			}, {
				"searchValue": "CeilingFans",
				"display": "Ceiling Fan(s)"
			}, {
				"searchValue": "CentralAir",
				"display": "Central Air"
			}, {
				"searchValue": "Common",
				"display": "Common"
			}, {
				"searchValue": "Dual",
				"display": "Dual"
			}, {
				"searchValue": "Ductless",
				"display": "Ductless"
			}, {
				"searchValue": "Electric",
				"display": "Electric"
			}, {
				"searchValue": "ENERGYSTARQualifiedEquipment",
				"display": "ENERGY STAR Qualified Equipment"
			}, {
				"searchValue": "EvaporativeCooling",
				"display": "Evaporative Cooling"
			}, {
				"searchValue": "EvaporativeSwamp",
				"display": "Evaporative/Swamp"
			}, {
				"searchValue": "ExhaustFan",
				"display": "Exhaust Fan"
			}, {
				"searchValue": "ForcedAir",
				"display": "Forced Air"
			}, {
				"searchValue": "Gas",
				"display": "Gas"
			}, {
				"searchValue": "Geothermal",
				"display": "Geothermal"
			}, {
				"searchValue": "HeatPump",
				"display": "Heat Pump"
			}, {
				"searchValue": "HighEfficiency",
				"display": "High Efficiency"
			}, {
				"searchValue": "HumidityControl",
				"display": "Humidity Control"
			}, {
				"searchValue": "MultiUnits",
				"display": "Multi Units"
			}, {
				"searchValue": "None",
				"display": "None"
			}, {
				"searchValue": "Other",
				"display": "Other"
			}, {
				"searchValue": "Refrigerated",
				"display": "Refrigerated"
			}, {
				"searchValue": "ReverseCycle",
				"display": "Reverse Cycle"
			}, {
				"searchValue": "RoofTurbines",
				"display": "Roof Turbine(s)"
			}, {
				"searchValue": "SeeRemarks",
				"display": "See Remarks"
			}, {
				"searchValue": "SeparateMeters",
				"display": "Separate Meters"
			}, {
				"searchValue": "VariesbyUnit",
				"display": "Varies by Unit"
			}, {
				"searchValue": "WallUnits",
				"display": "Wall Unit(s)"
			}, {
				"searchValue": "WallWindowUnits",
				"display": "Wall/Window Unit(s)"
			}, {
				"searchValue": "WholeHouseFan",
				"display": "Whole House Fan"
			}, {
				"searchValue": "WindowUnits",
				"display": "Window Unit(s)"
			}, {
				"searchValue": "Zoned",
				"display": "Zoned"
			}
		]
	},
	{
		display: "Appliances",
		searchKey: "Appliances",
		values: [{
			"searchValue": "Item6BurnerStove",
			"display": "6 Burner Stove"
		}, {
			"searchValue": "AppliancesNegotiable",
			"display": "Appliances Negotiable"
		}, {
			"searchValue": "BarFridge",
			"display": "Bar Fridge"
		}, {
			"searchValue": "Barbecue",
			"display": "Barbecue"
		}, {
			"searchValue": "BuiltIn",
			"display": "Built-In"
		}, {
			"searchValue": "BuiltinCoffeeMaker",
			"display": "Built-in Coffee Maker"
		}, {
			"searchValue": "BuiltInElectricOven",
			"display": "Built-In Electric Oven"
		}, {
			"searchValue": "BuiltInElectricRange",
			"display": "Built-In Electric Range"
		}, {
			"searchValue": "BuiltInFreezer",
			"display": "Built-In Freezer"
		}, {
			"searchValue": "BuiltInGasOven",
			"display": "Built-In Gas Oven"
		}, {
			"searchValue": "BuiltInGasRange",
			"display": "Built-In Gas Range"
		}, {
			"searchValue": "BuiltInOven",
			"display": "Built-In Oven"
		}, {
			"searchValue": "BuiltInRange",
			"display": "Built-In Range"
		}, {
			"searchValue": "BuiltInRefrigerator",
			"display": "Built-In Refrigerator"
		}, {
			"searchValue": "CentralVacuum",
			"display": "Central Vacuum"
		}, {
			"searchValue": "CoalWaterHeater",
			"display": "Coal Water Heater"
		}, {
			"searchValue": "CommonWaterHeater",
			"display": "Common Water Heater"
		}, {
			"searchValue": "ConvectionOven",
			"display": "Convection Oven"
		}, {
			"searchValue": "Cooktop",
			"display": "Cooktop"
		}, {
			"searchValue": "CounterTop",
			"display": "Counter Top"
		}, {
			"searchValue": "Dishwasher",
			"display": "Dishwasher"
		}, {
			"searchValue": "Disposal",
			"display": "Disposal"
		}, {
			"searchValue": "DoubleOven",
			"display": "Double Oven"
		}, {
			"searchValue": "DownDraft",
			"display": "Down Draft"
		}, {
			"searchValue": "Dryer",
			"display": "Dryer"
		}, {
			"searchValue": "ElectricCooking",
			"display": "Electric Cooking"
		}, {
			"searchValue": "ElectricCooktop",
			"display": "Electric Cooktop"
		}, {
			"searchValue": "ElectricOven",
			"display": "Electric Oven"
		}, {
			"searchValue": "ElectricRange",
			"display": "Electric Range"
		}, {
			"searchValue": "ElectricWaterHeater",
			"display": "Electric Water Heater"
		}, {
			"searchValue": "ENERGYSTARQualifiedAppliances",
			"display": "ENERGY STAR Qualified Appliances"
		}, {
			"searchValue": "ENERGYSTARQualifiedDishwasher",
			"display": "ENERGY STAR Qualified Dishwasher"
		}, {
			"searchValue": "ENERGYSTARQualifiedDryer",
			"display": "ENERGY STAR Qualified Dryer"
		}, {
			"searchValue": "ENERGYSTARQualifiedFreezer",
			"display": "ENERGY STAR Qualified Freezer"
		}, {
			"searchValue": "ENERGYSTARQualifiedRefrigerator",
			"display": "ENERGY STAR Qualified Refrigerator"
		}, {
			"searchValue": "ENERGYSTARQualifiedWasher",
			"display": "ENERGY STAR Qualified Washer"
		}, {
			"searchValue": "ENERGYSTARQualifiedWaterHeater",
			"display": "ENERGY STAR Qualified Water Heater"
		}, {
			"searchValue": "ExhaustFan",
			"display": "Exhaust Fan"
		}, {
			"searchValue": "FreeStanding",
			"display": "Free Standing"
		}, {
			"searchValue": "FreeStandingElectricOven",
			"display": "Free-Standing Electric Oven"
		}, {
			"searchValue": "FreeStandingElectricRange",
			"display": "Free-Standing Electric Range"
		}, {
			"searchValue": "FreeStandingFreezer",
			"display": "Free-Standing Freezer"
		}, {
			"searchValue": "FreeStandingGasOven",
			"display": "Free-Standing Gas Oven"
		}, {
			"searchValue": "FreeStandingGasRange",
			"display": "Free-Standing Gas Range"
		}, {
			"searchValue": "FreeStandingRange",
			"display": "Free-Standing Range"
		}, {
			"searchValue": "FreeStandingRefrigerator",
			"display": "Free-Standing Refrigerator"
		}, {
			"searchValue": "Freezer",
			"display": "Freezer"
		}, {
			"searchValue": "GasCooking",
			"display": "Gas Cooking"
		}, {
			"searchValue": "GasCooktop",
			"display": "Gas Cooktop"
		}, {
			"searchValue": "GasGrillConnection",
			"display": "Gas Grill Connection"
		}, {
			"searchValue": "GasOven",
			"display": "Gas Oven"
		}, {
			"searchValue": "GasRange",
			"display": "Gas Range"
		}, {
			"searchValue": "GasWaterHeater",
			"display": "Gas Water Heater"
		}, {
			"searchValue": "Heater",
			"display": "Heater"
		}, {
			"searchValue": "HighEfficiencyWaterHeater",
			"display": "High Efficiency Water Heater"
		}, {
			"searchValue": "HotWaterCirculator",
			"display": "Hot Water Circulator"
		}, {
			"searchValue": "Humidifier",
			"display": "Humidifier"
		}, {
			"searchValue": "IceMaker",
			"display": "Ice Maker"
		}, {
			"searchValue": "IndoorGrill",
			"display": "Indoor Grill"
		}, {
			"searchValue": "InductionCooktop",
			"display": "Induction Cooktop"
		}, {
			"searchValue": "InstantHotWater",
			"display": "Instant Hot Water"
		}, {
			"searchValue": "Microwave",
			"display": "Microwave"
		}, {
			"searchValue": "MultipleDishwashers",
			"display": "Multiple Dishwashers"
		}, {
			"searchValue": "MultipleRefrigerators",
			"display": "Multiple Refrigerators"
		}, {
			"searchValue": "MultipleWaterHeaters",
			"display": "Multiple Water Heaters"
		}, {
			"searchValue": "NoHotWater",
			"display": "No Hot Water"
		}, {
			"searchValue": "None",
			"display": "None"
		}, {
			"searchValue": "OilWaterHeater",
			"display": "Oil Water Heater"
		}, {
			"searchValue": "Other",
			"display": "Other"
		}, {
			"searchValue": "Oven",
			"display": "Oven"
		}, {
			"searchValue": "PlumbedForGas",
			"display": "Plumbed For Gas"
		}, {
			"searchValue": "PlumbedForIceMaker",
			"display": "Plumbed For Ice Maker"
		}, {
			"searchValue": "PortableDishwasher",
			"display": "Portable Dishwasher"
		}, {
			"searchValue": "PropaneCooking",
			"display": "Propane Cooking"
		}, {
			"searchValue": "PropaneCooktop",
			"display": "Propane Cooktop"
		}, {
			"searchValue": "PropaneOven",
			"display": "Propane Oven"
		}, {
			"searchValue": "PropaneRange",
			"display": "Propane Range"
		}, {
			"searchValue": "PropaneWaterHeater",
			"display": "Propane Water Heater"
		}, {
			"searchValue": "Range",
			"display": "Range"
		}, {
			"searchValue": "RangeHood",
			"display": "Range Hood"
		}, {
			"searchValue": "Refrigerator",
			"display": "Refrigerator"
		}, {
			"searchValue": "SelfCleaningOven",
			"display": "Self Cleaning Oven"
		}, {
			"searchValue": "SeparateIceMachine",
			"display": "Separate Ice Machine"
		}, {
			"searchValue": "SmartAppliances",
			"display": "Smart Appliances"
		}, {
			"searchValue": "SmoothCooktop",
			"display": "Smooth Cooktop"
		}, {
			"searchValue": "SolarHotWater",
			"display": "Solar Hot Water"
		}, {
			"searchValue": "SomeCommercialGrade",
			"display": "Some Commercial Grade"
		}, {
			"searchValue": "SomeElectricAppliances",
			"display": "Some Electric Appliances"
		}, {
			"searchValue": "SomeGasAppliances",
			"display": "Some Gas Appliances"
		}, {
			"searchValue": "SomePropaneAppliances",
			"display": "Some Propane Appliances"
		}, {
			"searchValue": "StainlessSteelAppliances",
			"display": "Stainless Steel Appliance(s)"
		}, {
			"searchValue": "Stove",
			"display": "Stove"
		}, {
			"searchValue": "SumpPump",
			"display": "Sump Pump"
		}, {
			"searchValue": "TanklessWaterHeater",
			"display": "Tankless Water Heater"
		}, {
			"searchValue": "TrashCompactor",
			"display": "Trash Compactor"
		}, {
			"searchValue": "VentedExhaustFan",
			"display": "Vented Exhaust Fan"
		}, {
			"searchValue": "WalkInCooler",
			"display": "Walk-In Cooler"
		}, {
			"searchValue": "WarmingDrawer",
			"display": "Warming Drawer"
		}, {
			"searchValue": "Washer",
			"display": "Washer"
		}, {
			"searchValue": "WasherDryerAllInOne",
			"display": "Washer Dryer All In One"
		}, {
			"searchValue": "WasherDryer",
			"display": "Washer/Dryer"
		}, {
			"searchValue": "WasherDryerStacked",
			"display": "Washer/Dryer Stacked"
		}, {
			"searchValue": "WaterHeater",
			"display": "Water Heater"
		}, {
			"searchValue": "WaterPurifier",
			"display": "Water Purifier"
		}, {
			"searchValue": "WaterPurifierOwned",
			"display": "Water Purifier Owned"
		}, {
			"searchValue": "WaterPurifierRented",
			"display": "Water Purifier Rented"
		}, {
			"searchValue": "WaterSoftener",
			"display": "Water Softener"
		}, {
			"searchValue": "WaterSoftenerOwned",
			"display": "Water Softener Owned"
		}, {
			"searchValue": "WaterSoftenerRented",
			"display": "Water Softener Rented"
		}, {
			"searchValue": "WaterToRefrigerator",
			"display": "Water To Refrigerator"
		}, {
			"searchValue": "WineCooler",
			"display": "Wine Cooler"
		}, {
			"searchValue": "WineRefrigerator",
			"display": "Wine Refrigerator"
		}, {
			"searchValue": "WoodWaterHeater",
			"display": "Wood Water Heater"
		}]
	},
	{ 
		display: "Garage",
		searchKey: "GarageYN",
		values: [
			{ 
				display: "Yes",
				searchValue: "true",
			},
			{	
				display: "No",
				searchValue: "false",
			}
		]
		
	},
	{ 
		display: "Furnished",
		searchKey: "Furnished",
		values: [
			{ 
				display: "Furnished",
				searchValue: "Furnished",
			},
			{	
				display: "Unfurnished",
				searchValue: "Unfurnished",
			},
			{	
				display: "Negotiable",
				searchValue: "Negotiable",
			},
			{	
				display: "Partially",
				searchValue: "Partially",
			},
			{	
				display: "Furnished Or Unfurnished",
				searchValue: "FurnishedOrUnfurnished",
			}
		]
	},
	{
		display: "Days On Market",
		searchKey: "DaysOnMarket",
		values: [
			{ 
				display: "One week",
				searchValue: "-8"
			},
			{	
				display: "1 to 2 weeks",
				searchValue: "7-14"
			},
			{	
				display: "2 to 3 weeks",
				searchValue: "14-21"
			},
			{	
				display: "3 weeks to 1 month",
				searchValue: "21-31"
			},
			{	
				display: "1 to 2 months",
				searchValue: "31-62"
			},
			{	
				display: "2 to 6 months",
				searchValue: "62-186"
			},
			{	
				display: "6 months to 1 year",
				searchValue: "186-365"
			},
			{	
				display: "1 year or more",
				searchValue: "365+"
			}

		]
	},
	{
		display: "Architectural Style",
		searchKey: "ArchitecturalStyle",
		values: [
			{
				"searchValue": "Item2UnitCondo",
				"display": "2 Unit Condo"
			}, {
				"searchValue": "AFrame",
				"display": "A-Frame"
			}, {
				"searchValue": "AirLite",
				"display": "AirLite"
			}, {
				"searchValue": "ArtDeco",
				"display": "Art Deco"
			}, {
				"searchValue": "Backsplit",
				"display": "Backsplit"
			}, {
				"searchValue": "BiLevel",
				"display": "Bi-Level"
			}, {
				"searchValue": "Bungalow",
				"display": "Bungalow"
			}, {
				"searchValue": "BungalowRaised",
				"display": "Bungalow Raised"
			}, {
				"searchValue": "CabinCottage",
				"display": "Cabin/Cottage"
			}, {
				"searchValue": "Camelback",
				"display": "Camelback"
			}, {
				"searchValue": "Camp",
				"display": "Camp"
			}, {
				"searchValue": "CapeCod",
				"display": "Cape Cod"
			}, {
				"searchValue": "ChaletAlpine",
				"display": "Chalet/Alpine"
			}, {
				"searchValue": "ClusterHome",
				"display": "Cluster Home"
			}, {
				"searchValue": "CoachCarriage",
				"display": "Coach/Carriage"
			}, {
				"searchValue": "Coastal",
				"display": "Coastal"
			}, {
				"searchValue": "Colonial",
				"display": "Colonial"
			}, {
				"searchValue": "Contemporary",
				"display": "Contemporary"
			}, {
				"searchValue": "ContemporaryModern",
				"display": "Contemporary/Modern"
			}, {
				"searchValue": "Conventional",
				"display": "Conventional"
			}, {
				"searchValue": "Cottage",
				"display": "Cottage"
			}, {
				"searchValue": "Courtyard",
				"display": "Courtyard"
			}, {
				"searchValue": "Craftsman",
				"display": "Craftsman"
			}, {
				"searchValue": "Custom",
				"display": "Custom"
			}, {
				"searchValue": "Dallas",
				"display": "Dallas"
			}, {
				"searchValue": "Detached",
				"display": "Detached"
			}, {
				"searchValue": "DomeGeodesic",
				"display": "Dome/Geodesic"
			}, {
				"searchValue": "Duplex",
				"display": "Duplex"
			}, {
				"searchValue": "DutchColonial",
				"display": "Dutch Colonial"
			}, {
				"searchValue": "EarlyAmerican",
				"display": "Early American"
			}, {
				"searchValue": "English",
				"display": "English"
			}, {
				"searchValue": "European",
				"display": "European"
			}, {
				"searchValue": "Farmhouse",
				"display": "Farmhouse"
			}, {
				"searchValue": "Federalist",
				"display": "Federalist"
			}, {
				"searchValue": "Flat",
				"display": "Flat"
			}, {
				"searchValue": "Florida",
				"display": "Florida"
			}, {
				"searchValue": "Fourplex",
				"display": "Fourplex"
			}, {
				"searchValue": "French",
				"display": "French"
			}, {
				"searchValue": "FrenchProvincial",
				"display": "French/Provincial"
			}, {
				"searchValue": "GambrelBarn",
				"display": "Gambrel/Barn"
			}, {
				"searchValue": "GarageApartment",
				"display": "Garage Apartment"
			}, {
				"searchValue": "GardenApartment",
				"display": "Garden Apartment"
			}, {
				"searchValue": "GardenHome",
				"display": "Garden Home"
			}, {
				"searchValue": "GarrisonFrontier",
				"display": "Garrison/Frontier"
			}, {
				"searchValue": "Georgian",
				"display": "Georgian"
			}, {
				"searchValue": "GreekRevival",
				"display": "Greek Revival"
			}, {
				"searchValue": "HighRise",
				"display": "High Rise"
			}, {
				"searchValue": "HillCountry",
				"display": "Hill Country"
			}, {
				"searchValue": "HistoricAntique",
				"display": "Historic/Antique"
			}, {
				"searchValue": "HousewCottage",
				"display": "House w/Cottage"
			}, {
				"searchValue": "Italianate",
				"display": "Italianate"
			}, {
				"searchValue": "Loft",
				"display": "Loft"
			}, {
				"searchValue": "LogHome",
				"display": "Log Home"
			}, {
				"searchValue": "LowRise",
				"display": "Low Rise"
			}, {
				"searchValue": "Mansion",
				"display": "Mansion"
			}, {
				"searchValue": "ManufacturedHome",
				"display": "Manufactured Home"
			}, {
				"searchValue": "Mediterranean",
				"display": "Mediterranean"
			}, {
				"searchValue": "MidEntry",
				"display": "Mid Entry"
			}, {
				"searchValue": "MidRise",
				"display": "Mid Rise"
			}, {
				"searchValue": "MidCenturyModern",
				"display": "Mid-Century Modern"
			}, {
				"searchValue": "MobileHome",
				"display": "Mobile Home"
			}, {
				"searchValue": "Modern",
				"display": "Modern"
			}, {
				"searchValue": "ModularPrefab",
				"display": "Modular/Prefab"
			}, {
				"searchValue": "Mountain",
				"display": "Mountain"
			}, {
				"searchValue": "MultiFamily",
				"display": "Multi-Family"
			}, {
				"searchValue": "MultiLevel",
				"display": "Multi-Level"
			}, {
				"searchValue": "Multiplex",
				"display": "Multiplex"
			}, {
				"searchValue": "NewEngland",
				"display": "New England"
			}, {
				"searchValue": "None",
				"display": "None"
			}, {
				"searchValue": "NorthernNewMexico",
				"display": "Northern New Mexico"
			}, {
				"searchValue": "OneStory",
				"display": "One Story"
			}, {
				"searchValue": "Other",
				"display": "Other"
			}, {
				"searchValue": "PatioHome",
				"display": "Patio Home"
			}, {
				"searchValue": "Penthouse",
				"display": "Penthouse"
			}, {
				"searchValue": "PostandBeam",
				"display": "Post and Beam"
			}, {
				"searchValue": "Prairie",
				"display": "Prairie"
			}, {
				"searchValue": "Pueblo",
				"display": "Pueblo"
			}, {
				"searchValue": "RaisedBeach",
				"display": "Raised Beach"
			}, {
				"searchValue": "RaisedRanch",
				"display": "Raised Ranch"
			}, {
				"searchValue": "Ranch",
				"display": "Ranch"
			}, {
				"searchValue": "RowHouse",
				"display": "Row House"
			}, {
				"searchValue": "Rustic",
				"display": "Rustic"
			}, {
				"searchValue": "SaltBox",
				"display": "Salt Box"
			}, {
				"searchValue": "Sidesplit",
				"display": "Sidesplit"
			}, {
				"searchValue": "Southwestern",
				"display": "Southwestern"
			}, {
				"searchValue": "Spanish",
				"display": "Spanish"
			}, {
				"searchValue": "SpanishMediterranean",
				"display": "Spanish/Mediterranean"
			}, {
				"searchValue": "SplitFoyer",
				"display": "Split-Foyer"
			}, {
				"searchValue": "SplitLevel",
				"display": "Split-Level"
			}, {
				"searchValue": "SquareDesign",
				"display": "Square Design"
			}, {
				"searchValue": "Stilt",
				"display": "Stilt"
			}, {
				"searchValue": "StraightThru",
				"display": "Straight Thru"
			}, {
				"searchValue": "Studio",
				"display": "Studio"
			}, {
				"searchValue": "Territorial",
				"display": "Territorial"
			}, {
				"searchValue": "ThreeStory",
				"display": "Three Story"
			}, {
				"searchValue": "TimberFrame",
				"display": "Timber Frame"
			}, {
				"searchValue": "Traditional",
				"display": "Traditional"
			}, {
				"searchValue": "Transitional",
				"display": "Transitional"
			}, {
				"searchValue": "TriLevel",
				"display": "Tri-Level"
			}, {
				"searchValue": "Triplex",
				"display": "Triplex"
			}, {
				"searchValue": "Tudor",
				"display": "Tudor"
			}, {
				"searchValue": "TwinHome",
				"display": "Twin Home"
			}, {
				"searchValue": "TwoStory",
				"display": "Two Story"
			}, {
				"searchValue": "UndergroundBermEarthship",
				"display": "Underground/Berm/Earthship"
			}, {
				"searchValue": "Victorian",
				"display": "Victorian"
			}, {
				"searchValue": "Williamsburg",
				"display": "Williamsburg"
			}
		]
	},


]

let TrestleKeysLong : string[] = [
	"AboveGradeFinishedArea",
	"AboveGradeFinishedAreaSource",
	"AboveGradeFinishedAreaUnits",
	"AccessCode",
	"AccessibilityFeatures",
	"AdditionalParcelsDescription",
	"AdditionalParcelsYN",
	"AnchorsCoTenants",
	"Appliances",
	"ArchitecturalStyle",
	"AssociationAmenities",
	"AssociationFee",
	"AssociationFee2",
	"AssociationFee2Frequency",
	"AssociationFeeFrequency",
	"AssociationFeeIncludes",
	"AssociationName",
	"AssociationName2",
	"AssociationPhone",
	"AssociationPhone2",
	"AssociationYN",
	"AttachedGarageYN",
	"AvailabilityDate",
	"Basement",
	"BasementYN",
	"BathroomsFull",
	"BathroomsHalf",
	"BathroomsOneQuarter",
	"BathroomsPartial",
	"BathroomsThreeQuarter",
	"BathroomsTotalInteger",
	"BedroomsPossible",
	"BedroomsTotal",
	"BelowGradeFinishedArea",
	"BelowGradeFinishedAreaSource",
	"BelowGradeFinishedAreaUnits",
	"BodyType",
	"BuilderModel",
	"BuilderName",
	"BuildingAreaSource",
	"BuildingAreaTotal",
	"BuildingAreaUnits",
	"BuildingFeatures",
	"BuildingName",
	"BusinessName",
	"BusinessType",
	"BuyerAgencyCompensation",
	"BuyerAgencyCompensationType",
	"BuyerAgentAOR",
	"BuyerAgentDesignation",
	"BuyerAgentDirectPhone",
	"BuyerAgentEmail",
	"BuyerAgentFax",
	"BuyerAgentFirstName",
	"BuyerAgentFullName",
	"BuyerAgentHomePhone",
	"BuyerAgentKey",
	"BuyerAgentKeyNumeric",
	"BuyerAgentLastName",
	"BuyerAgentMiddleName",
	"BuyerAgentMlsId",
	"BuyerAgentMobilePhone",
	"BuyerAgentNamePrefix",
	"BuyerAgentNameSuffix",
	"BuyerAgentOfficePhone",
	"BuyerAgentOfficePhoneExt",
	"BuyerAgentPager",
	"BuyerAgentPreferredPhone",
	"BuyerAgentPreferredPhoneExt",
	"BuyerAgentStateLicense",
	"BuyerAgentTollFreePhone",
	"BuyerAgentURL",
	"BuyerAgentVoiceMail",
	"BuyerAgentVoiceMailExt",
	"BuyerFinancing",
	"BuyerOfficeAOR",
	"BuyerOfficeEmail",
	"BuyerOfficeFax",
	"BuyerOfficeKey",
	"BuyerOfficeKeyNumeric",
	"BuyerOfficeMlsId",
	"BuyerOfficeName",
	"BuyerOfficePhone",
	"BuyerOfficePhoneExt",
	"BuyerOfficeURL",
	"BuyerTeamKey",
	"BuyerTeamKeyNumeric",
	"BuyerTeamName",
	"CLIP",
	"CableTvExpense",
	"CancellationDate",
	"CapRate",
	"CarportSpaces",
	"CarportYN",
	"CarrierRoute",
	"City",
	"CityRegion",
	"CloseDate",
	"ClosePrice",
	"CoBuyerAgentAOR",
	"CoBuyerAgentDesignation",
	"CoBuyerAgentDirectPhone",
	"CoBuyerAgentEmail",
	"CoBuyerAgentFax",
	"CoBuyerAgentFirstName",
	"CoBuyerAgentFullName",
	"CoBuyerAgentHomePhone",
	"CoBuyerAgentKey",
	"CoBuyerAgentKeyNumeric",
	"CoBuyerAgentLastName",
	"CoBuyerAgentMiddleName",
	"CoBuyerAgentMlsId",
	"CoBuyerAgentMobilePhone",
	"CoBuyerAgentNamePrefix",
	"CoBuyerAgentNameSuffix",
	"CoBuyerAgentOfficePhone",
	"CoBuyerAgentOfficePhoneExt",
	"CoBuyerAgentPager",
	"CoBuyerAgentPreferredPhone",
	"CoBuyerAgentPreferredPhoneExt",
	"CoBuyerAgentStateLicense",
	"CoBuyerAgentTollFreePhone",
	"CoBuyerAgentURL",
	"CoBuyerAgentVoiceMail",
	"CoBuyerAgentVoiceMailExt",
	"CoBuyerOfficeAOR",
	"CoBuyerOfficeEmail",
	"CoBuyerOfficeFax",
	"CoBuyerOfficeKey",
	"CoBuyerOfficeKeyNumeric",
	"CoBuyerOfficeMlsId",
	"CoBuyerOfficeName",
	"CoBuyerOfficePhone",
	"CoBuyerOfficePhoneExt",
	"CoBuyerOfficeURL",
	"CoListAgentAOR",
	"CoListAgentDesignation",
	"CoListAgentDirectPhone",
	"CoListAgentEmail",
	"CoListAgentFax",
	"CoListAgentFirstName",
	"CoListAgentFullName",
	"CoListAgentHomePhone",
	"CoListAgentKey",
	"CoListAgentKeyNumeric",
	"CoListAgentLastName",
	"CoListAgentMiddleName",
	"CoListAgentMlsId",
	"CoListAgentMobilePhone",
	"CoListAgentNamePrefix",
	"CoListAgentNameSuffix",
	"CoListAgentOfficePhone",
	"CoListAgentOfficePhoneExt",
	"CoListAgentPager",
	"CoListAgentPreferredPhone",
	"CoListAgentPreferredPhoneExt",
	"CoListAgentStateLicense",
	"CoListAgentTollFreePhone",
	"CoListAgentURL",
	"CoListAgentVoiceMail",
	"CoListAgentVoiceMailExt",
	"CoListOfficeAOR",
	"CoListOfficeEmail",
	"CoListOfficeFax",
	"CoListOfficeKey",
	"CoListOfficeKeyNumeric",
	"CoListOfficeMlsId",
	"CoListOfficeName",
	"CoListOfficePhone",
	"CoListOfficePhoneExt",
	"CoListOfficeURL",
	"CommonInterest",
	"CommonWalls",
	"CommunityFeatures",
	"Concessions",
	"ConcessionsAmount",
	"ConcessionsComments",
	"ConstructionMaterials",
	"ContinentRegion",
	"Contingency",
	"ContingentDate",
	"ContractStatusChangeDate",
	"Cooling",
	"CoolingYN",
	"CopyrightNotice",
	"Country",
	"CountryRegion",
	"CountyOrParish",
	"CoveredSpaces",
	"CropsIncludedYN",
	"CrossStreet",
	"CultivatedArea",
	"CumulativeDaysOnMarket",
	"CurrentFinancing",
	"CurrentUse",
	"DOH1",
	"DOH2",
	"DOH3",
	"DaysOnMarket",
	"DaysOnMarketReplication",
	"DaysOnMarketReplicationDate",
	"DaysOnMarketReplicationIncreasingYN",
	"DevelopmentStatus",
	"DirectionFaces",
	"Directions",
	"Disclaimer",
	"Disclosures",
	"DistanceToBusComments",
	"DistanceToBusNumeric",
	"DistanceToBusUnits",
	"DistanceToElectricComments",
	"DistanceToElectricNumeric",
	"DistanceToElectricUnits",
	"DistanceToFreewayComments",
	"DistanceToFreewayNumeric",
	"DistanceToFreewayUnits",
	"DistanceToGasComments",
	"DistanceToGasNumeric",
	"DistanceToGasUnits",
	"DistanceToPhoneServiceComments",
	"DistanceToPhoneServiceNumeric",
	"DistanceToPhoneServiceUnits",
	"DistanceToPlaceofWorshipComments",
	"DistanceToPlaceofWorshipNumeric",
	"DistanceToPlaceofWorshipUnits",
	"DistanceToSchoolBusComments",
	"DistanceToSchoolBusNumeric",
	"DistanceToSchoolBusUnits",
	"DistanceToSchoolsComments",
	"DistanceToSchoolsNumeric",
	"DistanceToSchoolsUnits",
	"DistanceToSewerComments",
	"DistanceToSewerNumeric",
	"DistanceToSewerUnits",
	"DistanceToShoppingComments",
	"DistanceToShoppingNumeric",
	"DistanceToShoppingUnits",
	"DistanceToStreetComments",
	"DistanceToStreetNumeric",
	"DistanceToStreetUnits",
	"DistanceToWaterComments",
	"DistanceToWaterNumeric",
	"DistanceToWaterUnits",
	"DocumentsAvailable",
	"DocumentsChangeTimestamp",
	"DocumentsCount",
	"DoorFeatures",
	"DualVariableCompensationYN",
	"Electric",
	"ElectricExpense",
	"ElectricOnPropertyYN",
	"ElementarySchool",
	"ElementarySchoolDistrict",
	"Elevation",
	"ElevationUnits",
	"EntryLevel",
	"EntryLocation",
	"EstimatedActiveDate",
	"Exclusions",
	"ExistingLeaseType",
	"ExpirationDate",
	"ExteriorFeatures",
	"FarmCreditServiceInclYN",
	"FarmLandAreaSource",
	"FarmLandAreaUnits",
	"Fencing",
	"FinancialDataSource",
	"FireplaceFeatures",
	"FireplaceYN",
	"FireplacesTotal",
	"Flooring",
	"FoundationArea",
	"FoundationDetails",
	"FrontageLength",
	"FrontageType",
	"FuelExpense",
	"Furnished",
	"FurnitureReplacementExpense",
	"GarageSpaces",
	"GarageYN",
	"GardenerExpense",
	"GrazingPermitsBlmYN",
	"GrazingPermitsForestServiceYN",
	"GrazingPermitsPrivateYN",
	"GreenEnergyEfficient",
	"GreenEnergyGeneration",
	"GreenIndoorAirQuality",
	"GreenLocation",
	"GreenSustainability",
	"GreenWaterConservation",
	"GrossIncome",
	"GrossScheduledIncome",
	"HabitableResidenceYN",
	"Heating",
	"HeatingYN",
	"HighSchool",
	"HighSchoolDistrict",
	"HomeWarrantyYN",
	"HorseAmenities",
	"HorseYN",
	"HoursDaysOfOperation",
	"HoursDaysOfOperationDescription",
	"Inclusions",
	"IncomeIncludes",
	"InsuranceExpense",
	"InteriorFeatures",
	"InternetAddressDisplayYN",
	"InternetAutomatedValuationDisplayYN",
	"InternetConsumerCommentYN",
	"InternetEntireListingDisplayYN",
	"IrrigationSource",
	"IrrigationWaterRightsAcres",
	"IrrigationWaterRightsYN",
	"LaborInformation",
	"LandLeaseAmount",
	"LandLeaseAmountFrequency",
	"LandLeaseExpirationDate",
	"LandLeaseYN",
	"Latitude",
	"LaundryFeatures",
	"LeasableArea",
	"LeasableAreaUnits",
	"LeaseAmount",
	"LeaseAmountFrequency",
	"LeaseAssignableYN",
	"LeaseConsideredYN",
	"LeaseExpiration",
	"LeaseRenewalCompensation",
	"LeaseRenewalOptionYN",
	"LeaseTerm",
	"Levels",
	"License1",
	"License2",
	"License3",
	"LicensesExpense",
	"ListAOR",
	"ListAgentAOR",
	"ListAgentDesignation",
	"ListAgentDirectPhone",
	"ListAgentEmail",
	"ListAgentFax",
	"ListAgentFirstName",
	"ListAgentFullName",
	"ListAgentHomePhone",
	"ListAgentKey",
	"ListAgentKeyNumeric",
	"ListAgentLastName",
	"ListAgentMiddleName",
	"ListAgentMlsId",
	"ListAgentMobilePhone",
	"ListAgentNamePrefix",
	"ListAgentNameSuffix",
	"ListAgentOfficePhone",
	"ListAgentOfficePhoneExt",
	"ListAgentPager",
	"ListAgentPreferredPhone",
	"ListAgentPreferredPhoneExt",
	"ListAgentStateLicense",
	"ListAgentTollFreePhone",
	"ListAgentURL",
	"ListAgentVoiceMail",
	"ListAgentVoiceMailExt",
	"ListOfficeAOR",
	"ListOfficeEmail",
	"ListOfficeFax",
	"ListOfficeKey",
	"ListOfficeKeyNumeric",
	"ListOfficeMlsId",
	"ListOfficeName",
	"ListOfficePhone",
	"ListOfficePhoneExt",
	"ListOfficeURL",
	"ListPrice",
	"ListPriceLow",
	"ListTeamKey",
	"ListTeamKeyNumeric",
	"ListTeamName",
	"ListingAgreement",
	"ListingContractDate",
	"ListingId",
	"ListingKey",
	"ListingKeyNumeric",
	"ListingService",
	"ListingTerms",
	"LivingArea",
	"LivingAreaSource",
	"LivingAreaUnits",
	"LockBoxLocation",
	"LockBoxSerialNumber",
	"LockBoxType",
	"Longitude",
	"LotDimensionsSource",
	"LotFeatures",
	"LotSizeAcres",
	"LotSizeArea",
	"LotSizeDimensions",
	"LotSizeSource",
	"LotSizeSquareFeet",
	"LotSizeUnits",
	"MLSAreaMajor",
	"MLSAreaMinor",
	"MainLevelBathrooms",
	"MainLevelBedrooms",
	"MaintenanceExpense",
	"MajorChangeTimestamp",
	"MajorChangeType",
	"Make",
	"ManagerExpense",
	"MapCoordinate",
	"MapCoordinateSource",
	"MapURL",
	"MiddleOrJuniorSchool",
	"MiddleOrJuniorSchoolDistrict",
	"MlsStatus",
	"MobileDimUnits",
	"MobileHomeRemainsYN",
	"MobileLength",
	"MobileWidth",
	"Model",
	"ModificationTimestamp",
	"NetOperatingIncome",
	"NewConstructionYN",
	"NewTaxesExpense",
	"NumberOfBuildings",
	"NumberOfFullTimeEmployees",
	"NumberOfLots",
	"NumberOfPads",
	"NumberOfPartTimeEmployees",
	"NumberOfSeparateElectricMeters",
	"NumberOfSeparateGasMeters",
	"NumberOfSeparateWaterMeters",
	"NumberOfUnitsInCommunity",
	"NumberOfUnitsLeased",
	"NumberOfUnitsMoMo",
	"NumberOfUnitsTotal",
	"NumberOfUnitsVacant",
	"OccupantName",
	"OccupantPhone",
	"OccupantType",
	"OffMarketDate",
	"OffMarketTimestamp",
	"OnMarketDate",
	"OnMarketTimestamp",
	"OpenParkingSpaces",
	"OpenParkingYN",
	"OperatingExpense",
	"OperatingExpenseIncludes",
	"OriginalEntryTimestamp",
	"OriginalListPrice",
	"OriginatingSystemID",
	"OriginatingSystemKey",
	"OriginatingSystemName",
	"OriginatingSystemSubName",
	"OtherEquipment",
	"OtherExpense",
	"OtherParking",
	"OtherStructures",
	"OwnerName",
	"OwnerPays",
	"OwnerPhone",
	"Ownership",
	"OwnershipType",
	"ParcelNumber",
	"ParkManagerName",
	"ParkManagerPhone",
	"ParkName",
	"ParkingFeatures",
	"ParkingTotal",
	"PastureArea",
	"PatioAndPorchFeatures",
	"PendingTimestamp",
	"Permission",
	"PermissionPrivate",
	"PestControlExpense",
	"PetsAllowed",
	"PhotosChangeTimestamp",
	"PhotosCount",
	"PoolExpense",
	"PoolFeatures",
	"PoolPrivateYN",
	"Possession",
	"PossibleUse",
	"PostalCity",
	"PostalCode",
	"PostalCodePlus4",
	"PreviousListPrice",
	"PriceChangeTimestamp",
	"PrivateOfficeRemarks",
	"PrivateRemarks",
	"ProfessionalManagementExpense",
	"PropertyAttachedYN",
	"PropertyCondition",
	"PropertySubType",
	"PropertySubTypeAdditional",
	"PropertyType",
	"PublicRemarks",
	"PublicSurveyRange",
	"PublicSurveySection",
	"PublicSurveyTownship",
	"PurchaseContractDate",
	"RVParkingDimensions",
	"RangeArea",
	"RentControlYN",
	"RentIncludes",
	"RoadFrontageType",
	"RoadResponsibility",
	"RoadSurfaceType",
	"Roof",
	"RoomsTotal",
	"SeatingCapacity",
	"SecurityFeatures",
	"SeniorCommunityYN",
	"SerialU",
	"SerialX",
	"SerialXX",
	"Sewer",
	"ShowingAdvanceNotice",
	"ShowingAttendedYN",
	"ShowingContactName",
	"ShowingContactPhone",
	"ShowingContactPhoneExt",
	"ShowingContactType",
	"ShowingDays",
	"ShowingEndTime",
	"ShowingInstructions",
	"ShowingRequirements",
	"ShowingStartTime",
	"SignOnPropertyYN",
	"Skirt",
	"SourceSystemID",
	"SourceSystemKey",
	"SourceSystemName",
	"SpaFeatures",
	"SpaYN",
	"SpecialLicenses",
	"SpecialListingConditions",
	"StandardStatus",
	"StateOrProvince",
	"StateRegion",
	"StatusChangeTimestamp",
	"Stories",
	"StoriesTotal",
	"StreetAdditionalInfo",
	"StreetDirPrefix",
	"StreetDirSuffix",
	"StreetName",
	"StreetNumber",
	"StreetNumberNumeric",
	"StreetSuffix",
	"StreetSuffixModifier",
	"StructureType",
	"SubAgencyCompensation",
	"SubAgencyCompensationType",
	"SubdivisionName",
	"SuppliesExpense",
	"SyndicateTo",
	"SyndicationRemarks",
	"TaxAnnualAmount",
	"TaxAssessedValue",
	"TaxBlock",
	"TaxBookNumber",
	"TaxLegalDescription",
	"TaxLot",
	"TaxMapNumber",
	"TaxOtherAnnualAssessmentAmount",
	"TaxParcelLetter",
	"TaxStatusCurrent",
	"TaxTract",
	"TaxYear",
	"TenantPays",
	"Topography",
	"TotalActualRent",
	"Township",
	"TransactionBrokerCompensation",
	"TransactionBrokerCompensationType",
	"TrashExpense",
	"UnitNumber",
	"UnitsFurnished",
	"UniversalPropertyId",
	"UniversalPropertySubId",
	"UnparsedAddress",
	"Utilities",
	"VacancyAllowance",
	"VacancyAllowanceRate",
	"Vegetation",
	"VideosChangeTimestamp",
	"VideosCount",
	"View",
	"ViewYN",
	"VirtualTourURLBranded",
	"VirtualTourURLUnbranded",
	"WalkScore",
	"WaterBodyName",
	"WaterSewerExpense",
	"WaterSource",
	"WaterfrontFeatures",
	"WaterfrontYN",
	"WindowFeatures",
	"WithdrawnDate",
	"WoodedArea",
	"WorkmansCompensationExpense",
	"X_GeocodeSource",
	"X_LandTenure",
	"X_LivingAreaRange",
	"X_Location",
	"YearBuilt",
	"YearBuiltDetails",
	"YearBuiltEffective",
	"YearBuiltSource",
	"YearEstablished",
	"YearsCurrentOwner",
	"Zoning",
	"ZoningDescription"]