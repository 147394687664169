<!-- <carousel class="mt-2">
    <slide *ngFor="let tile of tiles | keyvalue">
      <img *ngIf="isImage(tile.value) == true" [src]="tile.value.url" alt="{{ tile.value.longDescription }}" (click)="imageClick(tile)" (error)="handleImageError(tile)" alt="Images"/>
      <video *ngIf="isImage(tile.value) == false" (click)="imageClick(tile)">
        <source [src]="tile.value.url" type="video/{{ tile.value.type }}">
      </video>
    </slide>
</carousel> -->

<div [ngClass] = "(config?.showThumbnails)?'gallery-container':'gallery-container livepadMobileMain'">
  <div [ngClass] = "(config?.showThumbnails)?'thumbnails-wrapper productgallery':'thumbnails-wrapper'"><!-- *ngIf="!config?.showThumbnails"-->
    <div class="slider__prev d-none d-md-flex">
      <button (click)="scrollUp($event)" *ngIf="tiles.length>1" style="border: none; background: transparent;">
        <div class="arrow-up"><b class="adafix">.</b></div>
      </button>
   </div>
    <div class="thumbnails-container">
      <div class="thumbnail-group">
        <div
          *ngFor="let tile of tiles; let i = index"
          class="thumbnail"
          (click)="selectImage(i);ctaClickEvent($event,'thumbnail')"
          [class.selected]="i === selectedImageIndex"
        >
          <img [src]="tile.url" alt="{{ tile.longDescription }}" class="thumbnail-image" (error)="handleImageError(tile)">
        </div>
      </div>
    </div>
    <div class="slider__next d-none d-md-flex">
      <button (click)="scrollDown($event);ctaClickEvent($event,'arrow_down')" *ngIf="tiles.length>1" style="border: none; background: transparent;">
        <div class="arrow-down"><b class="adafix">.</b></div>
      </button>
    </div>
  </div>

  <div class="main-image">
    <div class="gallery-controls" [ngClass]="(styleClass!=''?styleClass:'gallery-controls')+ ' ' + (config?.showThumbnails?'gallery-images':'')" >
      <button class="prev-button" *ngIf="tiles.length>1" (click)="prevImage();ctaClickEvent($event,'arrow_previous')">
        <i class="bi bi-chevron-left"><b class="adafix">.</b></i>
      </button>      
      <img
        [src]="tiles[selectedImageIndex]?.url"
        [alt]="tiles[selectedImageIndex]?.longDescription"
        *ngIf="selectedImageIndex !== null"
        (error)="handleImageError(tile)" [ngClass]="config?.showThumbnails?'main-image property-image':'main-image LivepadMobileImage'"
      >
      <button class="next-button" *ngIf="tiles.length>1" (click)="nextImage();ctaClickEvent($event,'arrow_next')">
        <i class="bi bi-chevron-right"><b class="adafix">.</b></i>
      </button>    
    </div>
  </div>
  <div class="wish-list-button" *ngIf="config?.showThumbnails">
    <span *ngIf="!isAddToWishList" class="fav-off"><img src="assets/fav-blue-icon.png" width="22" height="20" alt="fav icon" (click)="addToWishList.emit(property)" tabindex="0" (keyup.enter)="addToWishList.emit(property)"></span>
    <span *ngIf="isAddToWishList" class="fav-on"><img src="assets/ph_heart-fill.png" width="22" height="22" alt="fav icon" (click)="removeFromWishList.emit(property)" tabindex="0" (keyup.enter)="removeFromWishList.emit(property)"></span>
  </div>
  <div style="margin-top: 30px;" [ngClass]="(config?.showThumbnails)?'mobile_thumbnail_slider':'hideMeInAll'">
    <div class="mobile_slider__prev">
      <button (click)="scrollRight($event)" *ngIf="tiles.length>1" style="border: none; background: transparent;">
        <div class="arrow-left"><b class="adafix">.</b></div>
      </button>
    </div>
    <div class="horizontal-thumbnails-container">
      <div class="horizontal-thumbnail-group">
        <div
          *ngFor="let tile of tiles; let i = index"
          class="horizontal-thumbnail"
          (click)="selectImage(i);ctaClickEvent($event, 'horizontal_thumbnail')"
          [class.selected]="i === selectedImageIndex"
        >
          <img [src]="tile.url" alt="{{ tile.longDescription }}" class="horizontal-thumbnail-image" (error)="handleImageError(tile)">
        </div>
      </div>
    </div>
    <div class="mobile_slider__next">
      <button (click)="scrollLeft($event);ctaClickEvent($event,'arrow_down')" *ngIf="tiles.length>1" style="border: none; background: transparent;">
        <div class="arrow-right"><b class="adafix">.</b></div>
      </button>
    </div>
  </div>
</div>