import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { StrictNumberOnlyDirective } from '../utils/StrictNumbersOnlyDirective';
import { UsernameDirective } from '../utils/UserNameDirective';
import { FirstNameDirective } from '../utils/FirstNameDirective';
import { LastNameDirective } from '../utils/LastNameDirective';
import { MapComponent } from '../map/map.component';
import { ImageGridComponent } from '@app/image-grid/image-grid.component';
import { SpinnerComponent } from '@app/spinner/spinner.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { FormPhoneDirective } from '@app/utils/form-phone.directive';
@NgModule({
 imports:      [ CommonModule ,FormsModule,NgxSpinnerModule],
 declarations: [ StrictNumberOnlyDirective, FirstNameDirective, LastNameDirective, MapComponent,UsernameDirective,ImageGridComponent,SpinnerComponent,FormPhoneDirective],
 exports:      [ CommonModule,FormsModule,StrictNumberOnlyDirective,FirstNameDirective,LastNameDirective,MapComponent,UsernameDirective,ImageGridComponent,NgxSpinnerModule,SpinnerComponent,FormPhoneDirective],
})
export class SharedModule { }
