<a routerLink="{{favoriteLink()}}">
	<mat-card class="favorite-card">		
			<div class="heart-span">
				<div class="heart-container">
					<!-- <mat-icon class="heart heart-outline" prefix (click)="onToggleFav()">{{ favoriteStatus() }}</mat-icon> -->
					<mat-icon class="heart" prefix >{{ favoriteStatus( ) }}</mat-icon>
				</div>
				<div class="star-set">
					<mat-icon *ngFor="let i of [1,2,3,4,5]" class="{{ getStarStatus(i) }}">{{ getStarStatus(i) }}</mat-icon>
				</div>
			</div>
			<div class="address truncate">{{property.address.fullAddress}}</div>
			<img class="property-thumbnail" [src]="property.propertyPhotos.length>0?property.propertyPhotos[0]?.url:'./assets/no_photo_455x340.jpg'" [alt]="'Property img'" (error)="handleImageError(property.propertyPhotos[0])" width="200" height="149"/>
			<div class="small-details price">
				${{ addCommasToPrice(property.propertyFeatures['featureData'].ListPrice) }}
			</div>
			<div class="small-details bottom">
				<div class="detail1">
					<div class="detail-wrap-mb">
						<div>
							<div class="detail-label"><img alt="bed-icon" src="assets/material-symbols_bed.png"> Beds</div>
							<div>{{property.propertyFeatures['featureData'].BedroomsTotal && property.propertyFeatures['featureData'].BedroomsTotal !== '********'?property.propertyFeatures['featureData'].BedroomsTotal:'N/A'}}</div>
						</div>
						<div>
							<div class="detail-label"><img alt="bath-icon" src="assets/solar_bath-bold.png"> Bath</div>
							<div>{{ bathroomCalculation(property) }}</div>
						</div>
						<div>
							<div class="detail-label"><img alt="sq-ft-icon" src="assets/fluent_ruler-16-filled.png"> Sq&nbsp;Ft</div>
							<div>
								{{ (environment.originatingMlsList.includes(property?.originatingMls?.toLowerCase())) 
									? (property?.propertyFeatures['featureData'].BuildingAreaTotal !== '********' && property?.propertyFeatures['featureData'].BuildingAreaTotal || 'N/A')
									: (property?.propertyFeatures['featureData'].LivingArea !== '********' && property?.propertyFeatures['featureData'].LivingArea || 'N/A') }}
							</div>
						</div>
					</div>
     			</div>
				<!-- {{ property.propertyFeatures['featureData'].BedroomsTotal }}bd / {{ bathroomCalculation(property) }}ba
				<div class="divider"></div>
				{{ property.propertyFeatures['featureData'].LivingArea }}sqft -->
			</div>
	</mat-card>
</a>