<div class="container">
	
	<img class="agent-thumbnail" src="{{ agentAvatar() }} "/>
	<div class="agent-info-container">
		
		<div class="agent-info-item">{{ agent.fullName }}</div>
		<div class="agent-info-item">{{ firstLicense() }}</div>
		<div class="agent-info-item">{{ agent.email }}</div>
		<div class="agent-info-item">{{ agent.phone }}</div>

	</div>
</div>