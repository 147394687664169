import { Component } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-property-new',
  templateUrl: './property-new.component.html',
  styleUrls: ['./property-new.component.css'],
})
export class PropertyNewComponent {
  constructor(private router: Router) {}

  propertySearch() {
    this.router.navigate(['/search-prop']);
  }
}
