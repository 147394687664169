import { Component, OnInit } from '@angular/core'; 
import {UntypedFormGroup, UntypedFormControl} from '@angular/forms';

@Component({
  selector: 'app-data-visualization',
  templateUrl: './data-visualization.component.html',
  styleUrls: ['./data-visualization.component.css']
})
export class DataVisualizationComponent implements OnInit {

   /**
   Error if create client or other server request failed
  */
   error: string = null

   constructor() { }
 
   ngOnInit(): void {
   }

   range = new UntypedFormGroup({
    start: new UntypedFormControl(null),
    end: new UntypedFormControl(null),
  });

  onGetRange(){
  }
}
