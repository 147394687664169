
<!-- <div class="container" [attr.selected]="selected">
	<div class="image-tiles" *ngFor="let image of images; index as i">

			<img *ngIf="image.type=='jpg'" 
				[src]="image.url" 
				(click)="imageClick(i)"
				height="150px"
				width="150px"
			/>
			<video width="150" height="150" controls="" *ngIf="image.type=='mp4'" >
				<source [src]="image.url" type="video/mp4"> 
				Your browser does not support the video tag.
			</video> 
	</div>
</div> -->


<div [attr.selected]="selected">
	<div class="image">
	  <ng-container *ngFor="let image of images; index as i">
		<img *ngIf="image.type=='jpg'" [src]="image.url" (click)="enlargeImageSlider(image.url, i)" height="150px"	width="150px" alt="Images" />	
		<video *ngIf="!isImage(image)" (click)="enlargeVideo(image.url, i)" >
			<source [src]="image.url" [type]="'video/' + image.type">
		</video>
	  </ng-container>
	</div>
  </div>
  
  <div style="position: relative;">
<div *ngIf="enlargedImageSlider" class="enlarged-image popupSlider">	
	<div style="position: relative;">
	<carousel [(activeSlide)]="popupIndex" class="slider" [showIndicators]="false" id="carouselCaptions1">
		<slide *ngFor="let image of images">
			<img [src]="image.url" [alt]="'Images'+i" />
		</slide>
	</carousel>
	<div class="carousel-indicators">
		<ng-container *ngFor="let image of images;let i=index">
			<span class="dot" [class.active]="i===popupIndex" type="button" data-bs-target="#carouselCaptions1"
				[data-bs-slide-to]="i" [aria-current]="i==popupIndex?true:false" (click)="currentSlide(i)"><b
					class="adafix">.</b></span>
		</ng-container>
	</div>
	<div (click)="closeEnlargedImageSlider()" class="closeButton" title="Close" style="position:absolute;">X</div>
	</div>
</div>

<div *ngIf="enlargedVideo" class="enlarged-image popupSlider">	
	<div style="position: relative;">
		<video controls>
			<source [src]="enlargedVideo" [type]="'video/mp4'">
		</video>
		<div (click)="closeEnlargedvideo()" class="closeButton" title="Close" style="position:absolute;">X</div>
	</div>
</div>
</div>
