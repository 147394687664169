<div class="content-container">
  <div class="title">
    <h1 tabindex="0"> Properties<!--<span class="welcomeTitleText">Welcome {{capitalizeName(user?.name)}}</span>--></h1>
  </div>
  <div class="row propertiesFields">
    <div class="col-md-12 search-text">Search</div>
    <div class="col-md-8">
      <div class="row">
    <div *ngFor="let field of searchFields; let index = index;" [ngClass]="((field.searchKey == 'LivingArea' || field.searchKey == 'BedroomsTotal' || field.searchKey == 'BathroomsTotalInteger')?'changeMargin Colpad':'Colpad') + ' ' + ((field.searchKey == 'BedroomsTotal' || field.searchKey == 'BathroomsTotalInteger')?'col-md-2 mobwidth':'col-md-4') + ' ' + ((field.searchKey == 'BathroomsTotalInteger')?'LastMargin':'')+' '+((field.searchKey == 'UnparsedAddress')?'widthIncrease':'')+' '+((field.searchKey == 'YearBuilt')?'widthDecrease':'')">
     <label #input2 class="labelName" *ngIf="field.searchKey == 'LivingArea' || field.searchKey == 'BedroomsTotal' || field.searchKey == 'BathroomsTotalInteger'" tabIndex="0" [attr.for]="'search-field-' + index">{{field.name}}</label>
        <!-- <mat-select *ngIf="field.isDropdown" class="mt-3 inputLine form-control" (selectionChange)="onUpdateSearchFieldValue($event, field.selection, field)" 
        [(value)]="field.selection.selectedField.value" [attr.id]="'search-field-' + index" [attr.aria-label]="field.name.replace(' ', '-') + 'search field'" 
        placeholder="{{field.placeholder}}" style="background: transparent; opacity: 0.7;">
          <mat-form-field>
            <input matInput [(ngModel)]="field.selection.searchText" (ngModelChange)="filterSearchFieldValuesList($event, field.selection)" name="search" placeholder="Search">
          </mat-form-field>
          <mat-option *ngFor="let fieldValue of field.selection.filteredValues" [value]="fieldValue">
            {{fieldValue.display}}
          </mat-option>
        </mat-select> -->
      <ng-container *ngIf="field.searchKey=='MlsSelection'">
        <mat-select  #selectMlsSelectionElement [disableOptionCentering]="true"  *ngIf="field.isDropdown" class="inputLine form-control" (selectionChange)="onUpdateSearchFieldValue($event, field.selection, field)" 
        [(value)]="field.selection.selectedField.value" [attr.id]="'search-field-' + index" [attr.aria-label]="field.name.replace(' ', '-') + 'search field'" 
        placeholder="{{field.placeholder}}" style="background: transparent;"  [panelClass]="'testClass'">
        <div class="input-group col positionSticky" >
          <input type="search" tabindex="0"   class="form-control search-input-field" autocomplete="off" [(ngModel)]="field.selection.searchText" (ngModelChange)="filterSearchFieldValuesList($event, field.selection,field.searchKey)" (keydown)="onSpaceKeyDown($event)" name="search" placeholder="Search">
        </div>
        <div *ngIf="(field.selection.filteredValues).length == 0" class="mat-option">
          <div class="mat-option-text text-center" style="opacity: 0.5;font-size:16px;">No results found!</div>
        </div>
          <mat-option *ngFor="let fieldValue of field.selection.filteredValues" [value]="fieldValue">
            {{fieldValue.display}}
          </mat-option>
        </mat-select>
      </ng-container>
      <ng-container *ngIf="field.searchKey=='mlsStatus'">
        <mat-select  #selectMlsStatusElement
        [disableOptionCentering]="true" 
        *ngIf="field.isDropdown" class="inputLine form-control" 
        (ngModelChange)="onUpdateSearchFieldValue($event, field.selection, field)"
        [(ngModel)]="field.selection.selectedField.value" 
        [attr.id]="'mlsSearch'"
        [attr.aria-label]="field.name.replace(' ', '-') + 'search field'" 
        placeholder="{{field.placeholder}}" 
        style="background: transparent;" 
        [multiple]="true"
        [panelClass]="'test2Class'"
        >
          <div class="input-group col positionSticky" >
            <input type="search" tabindex="0" [attr.id]="field.searchKey" 
            [(ngModel)]="field.selection.searchText"  #searchTextMlsStatus
            class="form-control search-input-field" autocomplete="off" 
            (ngModelChange)="filterSearchFieldValuesList($event, field.selection,field.searchKey)"
            (keydown)="onSpaceKeyDown($event)"  name="mlsSearch" placeholder="Search" />
          </div>
          <div *ngIf="(field.selection.filteredValues).length == 0" class="mat-option">
            <div class="mat-option-text text-center" style="opacity: 0.5;font-size:16px;">No results found!</div>
          </div>
          <mat-option *ngFor="let fieldValue of field.selection.filteredValues;let i=index" 
          [value]="fieldValue.value" [attr.checked]="fieldValue.checked"
          #selectMlsStatusOption
          [class.mat-active]="selectMlsStatusOption._active"
          >
            {{fieldValue.display}}
          </mat-option>
        </mat-select>
      </ng-container>
      <ng-container *ngIf="field.searchKey=='View'">
        <mat-select  #selectViewElement [disableOptionCentering]="true"  *ngIf="field.isDropdown" class="inputLine form-control" (selectionChange)="onUpdateSearchFieldValue($event, field.selection, field)" 
        [(value)]="field.selection.selectedField.value" [attr.id]="'search-field-' + index" [attr.aria-label]="field.name.replace(' ', '-') + 'search field'" 
        placeholder="{{field.placeholder}}" style="background: transparent;"  [panelClass]="'testClass'">
        <div class="input-group col positionSticky" >
          <input type="search" tabindex="0"   class="form-control search-input-field" autocomplete="off" [(ngModel)]="field.selection.searchText" (ngModelChange)="filterSearchFieldValuesList($event, field.selection,field.searchKey)" (keydown)="onSpaceKeyDown($event)" name="search" placeholder="Search">
        </div>
        <div *ngIf="(field.selection.filteredValues).length == 0" class="mat-option">
          <div class="mat-option-text text-center" style="opacity: 0.5;font-size:16px;">No results found!</div>
        </div>
          <mat-option *ngFor="let fieldValue of field.selection.filteredValues" [value]="fieldValue">
            {{fieldValue.display}}
          </mat-option>
        </mat-select>
      </ng-container>
      <input type="text" class="inputLine form-control" *ngIf="!field.isDropdown" placeholder="{{field.placeholder}}" 
        [(ngModel)]="field.value" name="search value" (keydown)="onKeyDown($event, field, index)" (keyup.enter)="onSearch()" [attr.id]="'search-field-' + index" [attr.aria-label]="field.name.replace(' ', '-') + ' search field'" style="background:transparent; opacity: 0.7;" (keyup.enter)="onEnter()" autocomplete="off" (focus)="fromStartFrom()">
    </div>
  </div>
    <div class="button-group mt-2 mobile-margin">
      <button class="big-ass-button" (click)="onSearch()" tabindex="0">SEARCH</button>
      <button class="cancel-button" (click)="onClearFilters()" tabindex="0">CLEAR FILTERS</button>
    </div>
  </div>
  </div>
  <app-property-list *ngIf="properties.length > 0" [properties]="properties" (onAppendToTour)="onAddToTour($event)" (onConfirmTour)="confirmTour($event)"></app-property-list>
  <app-no-results *ngIf="properties.length < 1 && didSearch == true"></app-no-results>
  <app-spinner [show]="showSpinner"></app-spinner>
  <app-checkmark *ngIf="showDone"></app-checkmark>
</div>
