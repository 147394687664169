import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BasicObject } from '../basicObject'
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { epochTimeFromDate } from '../utils'
// import { GoogleAnalyticsService } from '@app/services/google-analytics.service';

@Component({
  selector: 'app-confirm-input',
  templateUrl: './confirm-input.component.html',
  styleUrls: ['./confirm-input.component.css']
})
export class ConfirmInputComponent {


	inputs: BasicObject[] = []
	message: string = "Are you sure?"
	confirmButtonText = "YES"
	cancelButtonText = "CANCEL"

	/**
 	 Selected list item
	*/
	selectionChoice: string = null

	/**
 	 Can the main affirmative button be used or not
	*/
	positiveButtonDisabled() {

		if ( this.inputs?.length > 0 && (this.inputs[0].value == null || this.inputs[0].value?.length < 1)) {
			return true
		}

		return false
	}

	constructor( 
		@Inject(MAT_DIALOG_DATA) private data: any,
		// private gaService: GoogleAnalyticsService,
		private dialogRef: MatDialogRef<ConfirmInputComponent>) {
    
		if (data) {

		    this.message = data.message ?? this.message;
		    this.inputs = data.inputs ?? this.inputs
		    
			if (data.buttonText) {
				this.confirmButtonText = data.buttonText.ok ?? this.confirmButtonText;
				this.cancelButtonText = data.buttonText.cancel ?? this.cancelButtonText;
			}
		}
	}
	typingStarted:boolean = false;
	startWritingForm(event:Event){
		event.stopPropagation();
		if (!this.typingStarted) {
		// this.gaService.sendFormStartEvent({formType:`${this.data?.message.toLowerCase().split(" ").join("_")}`,disType:'pop_up'});
		this.typingStarted = true;
		}
	}
	onEnter() {

		let tourName = this.inputs.filter( x => { return x.name == "Tour Name" } ).shift().value
		// this.gaService.sendFormSubmitEvent({formType:`${this.data?.message.toLowerCase().split(" ").join("_")}`,disType:'pop_up'});
		if ( tourName == null ) {
			return
		}

		this.onConfirmClick()
	}

  	/**
 	 Action for affirmative confirmation
	*/
	onConfirmClick(): void {
		// this.gaService.sendFormSubmitEvent({formType:`${this.data?.message.toLowerCase().split(" ").join("_")}`,disType:'pop_up'});
		let tourName = this.inputs.filter( x => { return x.name == "Tour Name" } ).shift().value
		let tourDescription = this.inputs.filter( x => { return x.name == "Tour Description" } ).shift().value
		let tourDate = new Date()
		console.log("tour date is",tourDate);

		this.dialogRef.close( { 
			confirmed: true, 
			tour: { name: tourName, description: tourDescription, tourDate: tourDate }
		});
	}
	pageCtaClick(event:Event){
		event.stopPropagation();
		const element = (event?.target as HTMLElement);
		const roleValue = 'button';
		const elementName =element?.innerText.includes(' ')?element?.innerText?.trim().replace(' ','_').toLowerCase():element?.innerText?.toLowerCase();
    	const locationName ="create_a_tour_popup"
    	console.log({page_name:this.data?.currentRoute??''},{cta_type:roleValue,cta_location:locationName,cta_description:elementName})
   		// return;
		// this.gaService.sendCtaClickEvent({page_name:this.data?.currentRoute??''},{cta_type:roleValue,cta_location:locationName,cta_description:elementName})
	}

}


