import { Component, OnInit, Output, Input, EventEmitter, OnChanges, SimpleChanges, ViewChild, ElementRef, HostListener } from '@angular/core';
import { MapsService } from '../services/maps.service'
import { fromEvent } from 'rxjs';
import {
	filter,
	debounceTime,
	distinctUntilChanged,
	tap,
  } from 'rxjs/operators';

@Component({
  selector: 'app-address-autocomplete',
  templateUrl: './address-autocomplete.component.html',
  styleUrls: ['./address-autocomplete.component.css']
})

/**
 For auto-populating addresses using Google Maps API
*/
export class AddressAutocompleteComponent implements OnChanges {

	/**
 	 User's search terms
	*/
	userInput: string = null

	suggestionListOpen: boolean = false;

	/**
 	 @Input / @Output underlying variable for our final value.
	*/
	_address: string

	// @Input()
	// set address(val:string) {
	// 	this.addressChange.emit(val)
	// 	this._address = val
	// }

	@Input()
	set address(val: string) {
	  this._address = val;
	  this.userInput = val; // Set the userInput to the updated address value
	}

	get address() {
		return this._address
	}

	@Output() cityChange = new EventEmitter<string>();

	@Output() zipCodeChange = new EventEmitter<string>();

	@Output() stateChange = new EventEmitter<string>();

	@Output() addressChange = new EventEmitter<string>()

	@Input()
	disabled: boolean

	/**
 	 Places that Google has predicted for us
	*/
	predictedPlaces = []

	constructor(private mapsService: MapsService) { 

	}

	ngOnInit(): void {
	}

	ngOnChanges(changes: SimpleChanges): void {
		// Detect changes to the address input and update the userInput accordingly
		if (changes.address && !changes.address.isFirstChange()) {
		  this.userInput = changes.address.currentValue;
		}
	}


	/**
 	 Changes when a user types a key
	*/
	@ViewChild('addressInput', { static: true }) addressInput: ElementRef;
	ngAfterViewInit() {
		fromEvent(this.addressInput.nativeElement, 'keyup')
		.pipe(
		  filter(Boolean),
		  debounceTime(300),
		  distinctUntilChanged(),
		  tap((text) => {
			let eventVal = (text['target'] as HTMLInputElement).value;
			if( eventVal == '' && !(/^[0-9a-zA-Z., ]+$/i.test(eventVal))){
				this.predictedPlaces = [];
				// return;
			}
			this.mapsService.autocomplete(this.addressInput.nativeElement.value, (err, results) => {
				if (err) {
				  console.error(err);
				  return;
				}
				this.predictedPlaces = results;
			  });
			this.userInput = this.addressInput.nativeElement.value;
			this.addressChange.emit(this.userInput);
		  })
		)
		.subscribe(() => {
			// Open the suggestion list
			this.suggestionListOpen = true;
		  });
	  }

	  @HostListener('document:click', ['$event'])
		onDocumentClick(event: MouseEvent): void {
		if (this.suggestionListOpen) {
			this.suggestionListOpen = false;
		}
	}
	// onChange(event:KeyboardEvent) {
	
	// 	// const isAndroidMobile = /Android/i.test(navigator.userAgent) && /Mobile/i.test(navigator.userAgent);
	// 	// let keyCodeRanges = [
	// 	// 	{ 
	// 	// 		start: 0,
	// 	// 		end: 57,
	// 	// 		name: "digits"
	// 	// 	},
	// 	// 	{
	// 	// 		start: 65,
	// 	// 		end: 90,
	// 	// 		name: "lowercase"
	// 	// 	},
	// 	// 	{
	// 	// 		start: 96,
	// 	// 		end: 105,
	// 	// 		name: "numpad"
	// 	// 	},
	// 	// 	{
	// 	// 		start: 32,
	// 	// 		end: 32,
	// 	// 		name: "space"
	// 	// 	}
	// 	// ]

	// 	// Check if it's an Android mobile device and filter keycodes accordingly.
	// 	// if (isAndroidMobile) {
	// 	// 	keyCodeRanges = keyCodeRanges.filter(range => range.name !== "space"); // Remove the space range for Android mobile
	// 	// }
	// 	// // Don't search unless valid keys are actually typed.
	// 	// if ( keyCodeRanges.filter( x => { return event.keyCode >= x.start && event.keyCode <= x.end } ).length < 1 ) {
	// 	// 	return
	// 	// }
	// 	let eventVal = (event.target as HTMLInputElement).value;
	// 	if( eventVal == '' && !(/^[0-9a-zA-Z., ]+$/i.test(eventVal))){
	// 		event.preventDefault();
	// 		this.predictedPlaces = [];
	// 		return;
	// 	}
	// 	let searchTerms = eventVal;
	// 	if ( searchTerms.length == 0 ) {
	// 		this.predictedPlaces = []
	// 		return
	// 	}
	// 	this.mapsService.autocomplete(searchTerms, (err, results) => {
	// 		if (err) {
	// 		  console.error(err);
	// 		  return;
	// 		}
	// 		this.predictedPlaces = results;
	// 	  });
	// 	this.addressChange.emit(this.userInput);

	// }

	/**
 	 Handler for choosing a prediction
	*/
	// onAddressSelection (event) {
	// 	this.address = event.options[0]._value.description;
	// 	this.userInput = this._address
	// 	this.predictedPlaces = []
	// }

	onAddressSelection(event) {

		const data = event.options[0]._value;
		const selectedPlace = event.options[0]._value;
		this.address = selectedPlace.address_line1;
	  
		const addressComponents = selectedPlace.place_name.split(', ');
		const placeContext = selectedPlace.context.find((context) => context.id.startsWith('place'));
		const city = placeContext ? placeContext.text : '';
		let zipCode:string;
		let state:string;

		for (const element of addressComponents) {
		const component = element;

		const zipCodeMatch = component.match(/\b\d{5}\b/);
		if (zipCodeMatch) {
			zipCode = zipCodeMatch[0];
			state = component.replace(zipCode, "").trim();
			break;
		}
		}

		if(zipCode) {
			this.zipCodeChange.emit(zipCode);
		}
		else {
			this.zipCodeChange.emit('');
		}
		
		this.cityChange.emit(city);	
		this.stateChange.emit(state);  
		this.userInput = this._address;
		this.predictedPlaces = [];
		this.addressChange.emit(this._address);
	  }
}
