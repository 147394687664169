import { Injectable, EventEmitter } from '@angular/core';
// import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { Router } from '@angular/router';

import { HttpClient } from '@angular/common/http';
import { ServiceErrors } from './service-errors'

import { Authorization } from '../authorization'
import { AuthResponse } from '../authResponse'
import { User, orgUserdetails } from '../user'
import { Agent } from '../agent'
import { environment } from '../../environments/environment'
import { LocalStorage } from '../localStorage'
import { StorageMap } from '@ngx-pwa/local-storage';
import { Observable, Subject, of, throwError,BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

@Injectable()
export class LoginService {

	private graphApi: string = environment.graphApi;
	private authKey: string = "authKey"
	private expired: string = "expired"
	private userKey: string = "userKey"
	private agentKey: string = "agentKey"
	private agentAvatarKey: string = "agentAvatarKey"
	private mlsListKey: string = "agentMlsKey"
	private agentOrgListKey:string = "agentOrgListKey"
	private roleId: string ="roleId"
	private orgId: string ="orgId"
	private roleLable: string ="roleLable"
	private useridKey: string ="useridKey"
	private agentName: string ="agentName"

	user:User = {

		id: null,
		name: null,
		email: null,
		password: null,
		agent: null,
		agentorglist:null,
		orguserdetails: null,
		selectedMls: null,
	}

	authorization: Authorization = {
		token: null,
		expires: null
	}

	error: string = null

	userChange: EventEmitter<any> = new EventEmitter();
	/** Subjects and Observables are used to store the current user object and notify other components when the user logs in and out of the app. BehaviorSubject is type of subject used to store current value and emits it to any new subscribers as soon as they subscribe. */
	// private userSubject$: BehaviorSubject<User | null>;
    // public user$: Observable<User | null>;
	// public get userValue() {
	//     return this.userSubject$.value;
	// }

	getAuthorization(): Authorization {

		if ( this.authorization.token ) { return this.authorization }

		const expired_time = LocalStorage.get(this.expired);
		const token = LocalStorage.get(this.authKey);
		const currentTime = Date.now();

		if (currentTime < expired_time) {
            this.authorization.token = token;
            return this.authorization;
        } else {
            // LocalStorage.clear();
            return this.authorization; // Return null or handle the expired token case as per your requirements
        }
	}
	get isUserRole():number {
		// return this.user?.orguserdetails[0]?.roleId ? this.user?.orguserdetails[0].roleId : 0;
		return this.user?.orguserdetails?.length > 0 && this.user?.orguserdetails[0]?.roleId ? this.user?.orguserdetails[0].roleId : 0;
	}

	getUser(): User  {
		return this.user
	}

 	constructor (
 		private router: Router,
 		private http: HttpClient,
 		private storage: StorageMap ) { 

	 		this.user = { 
				id: null,
	 			name: null, 
	 			email: null, password: null,
				selectedMls: null, 
	 			agent: { 
	 				link: null,
					userName: null,
	 				firstName: null,
	 				lastName: null,
	 				email: null,
	 				phone: null,
	 				fullName: null,
	 				id: null,
	 				mls: null,
					org: null,
	 				address: null,
	 				picture: null,
	 				license: null
	 			},
				 orguserdetails:[{
					roleId: null,
					orgId: null,
					organizations:[],
				}],
				agentorglist:[{
					roleId: null,
					orgId: null,
					organizations:[],
				}],
				usermls:[{
					id: null,
					mlsName: null,
				}]
	 		}

	 		// Make sure auth has been set
			this.getAuthorization()

			this.user.name = LocalStorage.get(this.userKey)
			this.user.id = LocalStorage.get(this.useridKey)
			this.user.agent.fullName = LocalStorage.get(this.agentName)
			this.user.agent.link = LocalStorage.get(this.agentKey)
			this.user.agent.picture = LocalStorage.get(this.agentAvatarKey)
			this.user.orguserdetails[0].roleId = LocalStorage.get(this.roleId)
			this.user.orguserdetails[0].orgId = LocalStorage.get(this.orgId)
			this.user.usermls = JSON.parse(LocalStorage.get(this.mlsListKey))
			this.user.agentorglist = JSON.parse(LocalStorage.get(this.agentOrgListKey))
			// this.userSubject$ = new BehaviorSubject(JSON.parse(localStorage.getItem('user')!));
			// this.user$ = this.userSubject$.asObservable();
 	}

	 private targetUrl: string;

	setTargetUrl(url: string) {
		this.targetUrl = url;
	}
	
	getTargetUrl(): string {
		return this.targetUrl;
	}
	
	clearTargetUrl() {
		this.targetUrl = null;
	}


	/**
 	 Update the user's name and email from the server, remove password
	*/
	private updateUser (user) {


		if ( user.agent?.avatar != null ) {
			user.agent.picture = user.agent.avatar.url
		}

		this.user = {
			id: user.id,
			name: user.name,
			email: user.email,
			password: null,
			agent: user.agent,
			orguserdetails: user.orguserdetails,
			usermls:user.usermls,
			agentorglist:user.orguserdetails,
			selectedMls: null,
		}

		this.userChange.emit(this.user)
	}

	/**
 	 Post event change and update cookie values
	*/	
	agentAvatarUpdated (avatar) {

		let self = this
		this.user.agent.picture = avatar

		// Cache search field values
	    LocalStorage.set(this.agentAvatarKey, avatar)
		this.userChange.emit(self.user)

	}


	/**
 	 This just flushes the tokens from the cookie store
	*/
	logout () {

		this.updateUser({

			name: null,
			email: null,
			password: null,
			agent: null
		})

		this.authorization = {
			token: null,
			expires: null
		}
		// this.userSubject$.next(null);
		// Clear all cached values in local storage
		LocalStorage.clear()
		this.storage.clear().subscribe( () => {} )
	}


	login ( next: any, error: any ) {

		let query = `mutation { login( email: "${this.user.email}" password: "${this.user.password}" ) { token user { id name email agent { fullName link avatar {url} } orguserdetails {roleId orgId organizations{id name address description status NoOflicenses NoOfadmins createdBy}} usermls{ contractedMls {id mlsName} } } expires } }`
		let body = { "query" : query } 

	  	let returnedObservable = this.http
	  		.post<AuthResponse>(this.graphApi, body )
	  		.pipe(
	  			catchError(ServiceErrors.handleError)

	  		).subscribe((auth) => { 
				// Capture server error messages
	  			if ( auth.errors != undefined ) {
	  				if (error != undefined) { error(auth.errors[0].message) }
	  				return
	  			}
				if(auth.data.login.user['deactivated']){
					let accountStatus = {message:"Error: your account has been deactivated","path": [
						"login"
					]}
					error(accountStatus.message)
					return
				}
				

	  			this.error = null
	  			this.authorization = { token: auth.data.login.token, expires: auth.data.login.expires }

	  			const expires = new Date( Number(auth.data.login.expires) )

	  			if ( auth.data.login.user.agent['avatar'] != null ) {

	  				LocalStorage.set(this.agentAvatarKey, auth.data.login.user.agent['avatar']?.url)
		  		}

	  			LocalStorage.set(this.authKey, this.authorization.token)
				LocalStorage.set(this.expired, this.authorization.expires)
	  			LocalStorage.set(this.userKey, auth.data.login.user.name)
				LocalStorage.set(this.useridKey, auth.data.login.user.id)
				LocalStorage.set(this.agentName, auth.data.login.user.agent.fullName)
	  			LocalStorage.set(this.agentKey, auth.data.login.user.agent.link)
	  			LocalStorage.set(this.mlsListKey, JSON.stringify(auth.data.login.user['usermls']))
				LocalStorage.set(this.agentOrgListKey, JSON.stringify(auth.data.login.user['orguserdetails']))
				LocalStorage.set(this.roleId, auth.data.login.user.orguserdetails[0].roleId);
				LocalStorage.set(this.orgId, auth.data.login.user.orguserdetails[0].orgId);

				this.updateUser(auth.data.login.user)
				// store user details and jwt token in local storage to keep user logged in between page refreshes
				// localStorage.setItem('user', JSON.stringify(user));
				// this.userSubject$.next(auth.data.login.user);
				

	  			if ( next != undefined ) { next() }
	  		})


	}

	/**
 	 Register a User ( not an agent )
	*/
	register ( agent: Agent, next: any, error: any ) {

		// console.log("user details",this.user);

	  	let agentCreate = ''
	  	if ( agent != null ) {
	  		agentCreate = `agent: { firstName: "${agent.firstName}" lastName: "${agent.lastName}" email: "${agent.email.toLowerCase()}" phone: "${agent.phone}" mls: [${agent.mls}] }`
	  	}

		// let query = `mutation { signup( name: "${this.user.name}" email: "${this.user.email}"  ${agentCreate}) { message }}`

		let agent_email = agent.email.toLowerCase();
		let user_email = this.user.email.toLowerCase();
		let query = `mutation{
			signup(userDetails:{email:"${user_email}",name:"${this.user.name}",usermls:{mls:[${agent.mls}]},roleId:3,agent:{ firstName:"${agent.firstName}",lastName:"${agent.lastName}",email:"${agent_email}",phone:"${agent.phone}"},orgId:${agent.org}}){
			  message
			}
		}`
		console.log("query",query);
		// user { name email agent { fullName link avatar { url } } } expires  }
	  	let body = { "query" : query } 

	  	let returnedObservable = this.http
	  		.post<AuthResponse>(this.graphApi, body )
	  		.pipe(
	  			catchError(ServiceErrors.handleError)

	  		).subscribe((auth) => { 
	  			// Capture server error messages
	  			if ( auth.errors != undefined ) {
	  				if (error != undefined) { error(auth.errors[0].message) }
	  				return
	  			}

	  			this.error = null
	  			// this.authorization.token = auth.data.signup.token
				if(auth.data.signup.user){
	  			const expires = new Date( Number(auth.data.signup.expires) )

	  			if ( auth.data.signup.user.agent != null ) {

	  				LocalStorage.set(this.agentKey, auth.data.signup.user.agent?.link)
	  				LocalStorage.set(this.agentAvatarKey, auth.data.signup.user.agent['avatar']?.url)
		  			LocalStorage.set(this.mlsListKey, JSON.stringify(auth.data.signup.user.agent.mls))

		  		}

	  			// LocalStorage.set(this.authKey, this.authorization.token)
	  			LocalStorage.set(this.userKey, auth.data.signup.user.name)
	  			this.updateUser(auth.data.signup.user)
				}

	  			if ( next != undefined ) { next(auth.data.signup) }
	  		})

	}

	addLeadRequest ( agent: Agent, next: any, error: any ) {

	  let agent_email = agent.email.toLowerCase()

	  let query = `mutation{
		addLeadRequest(userName:"${agent.userName}",firstName:"${agent.firstName}",lastName:"${agent.lastName}",email:"${agent_email}",phone:"${agent.phone}"){
		  message
		}
	  }`
	  // user { name email agent { fullName link avatar { url } } } expires  }
		let body = { "query" : query } 

		let returnedObservable = this.http
			.post<AuthResponse>(this.graphApi, body )
			.pipe(
				catchError(ServiceErrors.handleError)

			).subscribe((auth) => { 
				console.log("auth details in addLeadRequest is",auth);
				next(auth);
			})

  	}

	  licenseAvailables ( agent: Agent, next: any, error: any ) {

		console.log("agent is",agent);
		console.log("agent org is",agent.org);

		let query = `query{
			licenseAvailables(orgId:${agent.org},roleId:3){
			  message
			}
		}`;
		// user { name email agent { fullName link avatar { url } } } expires  }
		  let body = { "query" : query } 
  
		  let returnedObservable = this.http
			  .post<AuthResponse>(this.graphApi, body )
			  .pipe(
				  catchError(ServiceErrors.handleError)
  
			  ).subscribe((auth) => { 
				  console.log("auth details in addLeadRequest is",auth);
				  next(auth);
			  })
  
		}
  
	/**
 	 Make the current logged in user an agent. Currently only happens on registration.
 	 Creation is only with one mls for now. Others can be added later.
 	 WARN: REMOVE
	*/
	makeAgent( agent: Agent, next: any, error: any) {

		return

		let query = `mutation { createAgent( firstName: "${agent.firstName}" lastName: "${agent.lastName}" email: "${agent.email}" phone: "${agent.phone}" mls: ${agent.mls} ) { id fullName link } }`
	  	let body = { "query" : query } 
	  	let options = { headers : { "Authorization" : `Bearer ${this.authorization.token}`} }

	  	let returnedObservable = this.http
	  		.post(this.graphApi, body, options )
	  		.pipe(
	  			catchError(ServiceErrors.handleError)

	  		).subscribe((response) => { 

	  			// Capture server error messages
	  			if ( response['errors'] != undefined ) {
	  				if (error != undefined) { error(response['errors'][0].message) }
	  				return
	  			}

	  			this.error = null

	  			// this.cookieService.put(this.agentKey, response['data'].createAgent.link, { expires: this.authorization.expires })

	  			let user = this.user

	  			user.agent.fullName = response['data'].createAgent.fullName
	  			user.agent.link = response['data'].createAgent.link

	  			this.updateUser(user)

	  			if ( next != undefined ) { next() }
	  		})

	}

	forgetPassword(email: string) {
		const query = `mutation {
		  forgetPassword(email: "${email}") {
			email
		  }
		}`;
	  
		let body = { "query": query };
		let options = { headers: { "Authorization": `Bearer ${this.authorization?.token}` } };
	  
		return this.http.post(this.graphApi, body, options)
		  .pipe(
			catchError(ServiceErrors.handleError)
		  );
	}	  

	resetPassword(password,token) {
	const query = `mutation {
		updatePassword(newPassword: "${password}" token:"${token}") {
			message
		}
	}`;
	
	let body = { "query": query };
	let options = { headers: { "Authorization": `Bearer ${this.authorization?.token}` } };
	
	return this.http.post(this.graphApi, body, options)
		.pipe(
		catchError(ServiceErrors.handleError)
		);
	}	
	
	/** manage org get list */
	orgList(): Observable<any> {
	
		let query = `query{ getOrgsList{
		  id,  name, address,description,status, createdAt,
		  orguserdetails{roleId}
		  orgmls{
			contractedMls{
			  id,
			  mlsName
			}
			
		  }
		  } }`;
		let body = { query: query };
		return this.http
		  .post(this.graphApi, body)
		  .pipe(catchError(ServiceErrors.handleError));
	  }

}
