<mat-dialog-content [ngClass]="(message.indexOf('delete') !== -1)?'deletePop':''">
	<h2 class="modal-cus-title" tabindex="0"><img src="./assets/alert-icon1.svg" width="30px" alt="Alert Icon" class="alert-img-pad"> <span *ngIf="message.indexOf('delete') === -1">Alert</span><span *ngIf="message.indexOf('delete') !== -1">Delete</span></h2>
	<div class="my-5 marg-left">
	<p class="messageLabel">
		{{message}}
	</p>
	<!-- <p *ngFor="let item of list">
		{{item}}
	</p> -->
</div>
</mat-dialog-content>
<mat-dialog-actions>
	<button type="submit" class="btn btn-primary" color="primary" (click)="onConfirmClick()" tabindex="1">{{confirmButtonText}}</button>
	<button type="reset" class="btn btn-secondary" (click)="onCloseClick()" tabindex="1">{{cancelButtonText}}</button>
</mat-dialog-actions>