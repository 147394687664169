<mat-card>
	<mat-card-title>Reports</mat-card-title>
	<mat-card-content>


		<mat-label>Report Range</mat-label>

		<mat-form-field appearance="fill">
			<mat-label>Enter a date range</mat-label>
			<mat-date-range-input [formGroup]="range" [rangePicker]="picker">
				<input matStartDate formControlName="start" placeholder="Start date">
				<input matEndDate formControlName="end" placeholder="End date">
			</mat-date-range-input>
			<mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
			<mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
			<mat-date-range-picker #picker></mat-date-range-picker>

			<mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
			<mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
		</mat-form-field>


		<!-- <input matInput type="date" class="date-picker title-date-picker" [value]="date: 'yyyy-MM-dd' " min="{{ today | date: 'yyyy-MM-dd' }}" 
				max="{{ nextYear | date: 'yyyy-MM-dd' }}" > -->


	</mat-card-content>

	<mat-card-actions>
		<button mat-stroked-button>Clear Reports</button>
		<button mat-stroked-button (click)="onGetRange()">Get Reports</button>

	</mat-card-actions>

</mat-card>