<div style="position: relative;">
    <div class="client">
        <img src=" assets/home-icon.svg" alt="Home Icon" class="homelog" style="position: relative;" tabindex="0">
        <span class="floatText" style="position: relative;" tabindex="0"> Properties</span>
        <span class="line"></span>
    </div>

    <div class="edit" style="position: absolute;">
        <img src="assets/edit-icon-white.png" alt="Edit Icon" style="position: relative; width:5%" tabindex="0">
        <div align="center">
        </div>
    </div>

    <div class="tourContent" align="center">
        <p tabindex="0">Your property list is currently empty. </p>
        <p tabindex="0"><b>Start builing your list!</b></p>
        <p class="textBlue" tabindex="0">ADD PROPERTY <i class="bi bi-plus-square plusicon" (click)="propertySearch()"></i></p>
    </div>
</div>