import { Component, OnInit, Input } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.css'],
})
export class SpinnerComponent implements OnInit {

	_show = false

	@Input()
	set show( newValue ) {
		this._show = newValue
		if ( newValue == true ) {
			this.spinner.show()
		} else {
			this.spinner.hide()
		}
	}
	get show() {
		return this._show
	}

	constructor( private spinner: NgxSpinnerService ) { }

	ngOnInit(): void {
	}

}
