import { Component, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ConfirmListComponent } from '@app/confirm-list/confirm-list.component';
// import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-error-modal',
  templateUrl: './error-modal.component.html',
  styleUrls: ['./error-modal.component.css']
})
export class ErrorModalComponent {
  // @Input() 
  errorMessage: string[];
  // @Input() 
  isExistingPropertyError: boolean;

  constructor(@Inject(MAT_DIALOG_DATA) private data: any,
  private dialogRef: MatDialogRef<ErrorModalComponent>) {
    this.errorMessage= data?.message;
    this.isExistingPropertyError=data?.isExistingPropertyError;
  }
  onCancelClick(event:Event): void {
		event.stopPropagation();
		this.dialogRef.close( { cancel: true });
	}

}
