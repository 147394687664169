import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { MatListModule } from '@angular/material/list';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { DomSanitizer } from '@angular/platform-browser';

import { LoginService } from '../services/login.service'
import { PropertyService } from '../services/property.service'

import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Client } from '../client'
import { Tour } from '../tour'
import { Property } from '../propertySearch'

import { ClientService } from '../services/client.service'
import { ToursService } from '../services/tours.service'
import { SpinnerComponent } from '../spinner/spinner.component'
import { CheckmarkComponent } from '../checkmark/checkmark.component'
import { epochTimeFromDate } from '../utils'

@Component({
  selector: 'app-new-tour-form',
  templateUrl: './new-tour-form.component.html',
  styleUrls: ['./new-tour-form.component.css']
})
export class NewTourFormComponent implements OnInit {

tourHistory(){
this.router.navigate(['/tour-history']);
}

	error: string = null

	/**
 	 Model for creating new tours
	*/
	newTour : Tour = {
		id: null,
		createdAt: null,
		tourNumber: null,
		tourDate: new Date(),
		name: null,
		description: null,
		checked: null,
		showings: null,
		clients: null
	}


	/**
 	 A list of properties that have been passed along from an SSO login from MLS
	*/
	mlsList: [string] = null

	/**
 	 Which MLS did the list originate from?
	*/
	mlsName: string = null

	/**
 	 The properties as pulled from the mlsList query parameter
	*/
	trailingProperties: Property[] = []

	/**
 	 Which logo to use
	*/
	logo = "./assets/livepad-logo-black.png"

	/**
 	 Extra styling options for the image
	*/	
	logoStyle = { 
		"height" : "75px",
		"opacity": "0.2"
	}

	buttonStyle = {
		"height": "100px"
	}

	/**
 	 Value for when the spinner should be shown
	*/
	showSpinner = false

	/**
 	 Show the done checkmark
	*/
	showDone = false

	/**
	 Are there properties following the user from an MLS entrance?
	*/
	showTrailingProperties() {
		return this.mlsList != null
	}

	constructor (		
	  	private router: Router, 
	  	private route: ActivatedRoute,
		private loginService: LoginService,
		private propertyService: PropertyService,
		private toursService: ToursService ) { 

			// if ( this.loginService.authorization.token == null ) {
			// 	this.router.navigate(['/login'])
			// }

	}

	ngOnInit(): void {

		this.fetchTrailingProperties()
	}

	/**
 	 Route back to tours page
	*/
	returnToTours() {
		this.router.navigate(['/tours'])
	}

	navigateToClients(tour) {

		// Shallow copy using "spread". So wierd.
		let queryParams = {
			...this.route.snapshot.queryParams 
		}

		queryParams["tour"] = tour.id
		this.router.navigate(['/clients'], { queryParams: queryParams })

	}

	showCheckmark(next) {
	    this.showDone = true
	    setTimeout(() => {
	    	this.showDone = false

	    	if ( next != null ) {

	    		// Pass "self" context
	    		next(this)
	    	}

	    }, 3000)
	}

	/**
 	 Return to main page
	*/
	onCancelCreation() {
		this.returnToTours()
	}

	/**
 	 Create the new tour 
	*/
	onConfirmCreation() {

		// Reset error
		this.error = null

		// Tour name cannot be null
		if ( this.newTour.name == null || this.newTour.name?.length < 1 ) {
			this.error = "Tour name is required."
			return
		}

		let tour: any = <any>this.newTour
		tour.tourDate = epochTimeFromDate(tour.tourDate)

		this.showSpinner = true

		this.toursService
			.addTour(tour, null,null)
			.subscribe(tours => { 
	  
		  		this.showSpinner = false

	  			// Capture server error messages
	  			if ( tours["errors"] != undefined ) {
	  				this.error = tours["errors"][0].message
	  				return
	  			}

	  			this.showCheckmark( (self) => {
	  				self.navigateToClients(tours['data']['createTour'])
	  			})

		})
	}

	/**
	 If any mlsId's are present as query parameters, this will indicate we have been fed them
	 by an external MLS source
	*/
	fetchTrailingProperties() {

		// Extract any passed list of mls id's
		let map = this.route.snapshot.queryParams
		this.mlsList = map['mlsList']?.split(',')
		this.mlsName = map['mlsName']

		// Guard for no items present
		if ( this.mlsList == null || this.mlsName == null ) {
			return
		}

		this.propertyService
			.fetchPropertiesForMls( this.mlsList, this.mlsName )
			.subscribe(response => { 
	  
	  	  			// Capture server error messages
	  			if ( response["errors"] != undefined ) {
	  				this.error = response["errors"][0].message
	  				return
	  			}  


			    this.trailingProperties = response["data"]["propertiesFromMls"]

		})
	}

}
