<app-header-action-bar 
	[title]="getTitle()" 
	subtitle="Create and manage client profiles and add them to your Tours here!"
	[showSmallButtons]="clients.length > 0"
	firstSmallIconName="Add Client"
	firstButtonTitle="New Client" 
	pageLabel="client"
	(firstButtonAction)="onAddClient()" 
	>
</app-header-action-bar>

<app-property-list 
	*ngIf="showTrailingProperties()"
	[properties]="trailingProperties"
	[showCheckbox]="false"
	[showAddToTours]="false" tabindex="0">	
</app-property-list>

<app-client-list 
	*ngIf="clients.length > 0 && showClientList" 
	[clients]="clients" 
	(onDelete)="onDeleteClients($event)"
	(onDeleteClient)="onDeleteClient($event)"
	(onAddToTour)="onAddToTour($event)"
	(onClientItemTrigger)="onRowSelected($event)"
	(onsingleAddToTour)="onAddTosingleTour($event)"
	[error]="error">	
</app-client-list>

<app-spinner [show]="showSpinner"></app-spinner>
<app-checkmark *ngIf="showCheck"></app-checkmark>

