import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-footer-bar',
  templateUrl: './footer-bar.component.html',
  styleUrls: ['./footer-bar.component.css']
})
export class FooterBarComponent implements OnInit {

  isLandingPage :boolean = false;
  isLoginPage :boolean = false;
  isRegisterPage :boolean = false;
  isForgotPage :boolean = false;
  isContactUsPage :boolean = false;
  isSubscribePage :boolean = false;
 
  constructor(private router: Router) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.isLandingPage = this.router.url === '/';
        this.isLoginPage = this.router.url === '/login';
        this.isRegisterPage = this.router.url === '/register'
        this.isForgotPage = event.url.includes('/forgot-password');
        this.isContactUsPage = this.router.url === '/contact'
        this.isSubscribePage = this.router.url === '/subscription'
      }
    });
  }
  currentDate:number;
  ngOnInit(): void {
    this.currentDate=new Date().getFullYear();
  }

}
