import { Injectable, OnInit } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

import { HttpClient,  HttpErrorResponse } from '@angular/common/http';
import { CookieService } from 'ngx-cookie';
import { Authorization } from '../authorization'
import { LoginService } from '../services/login.service'
import { graphQlFormat }  from '../graphQlFormatting'
import { environment } from '../../environments/environment'
import { Agent, License } from '../agent'
import { Mls } from '../mls'
import { ServiceErrors } from './service-errors'

@Injectable({
  providedIn: 'root'
})
export class AgentService {

	private graphApi:string = environment.graphApi;

	private authorization: Authorization

	constructor(private loginService: LoginService, private http: HttpClient) { 

		this.checkAuth() 
	}


	checkAuth () {
		this.authorization = this.loginService.getAuthorization()
		if ( !this.authorization?.token ) { console.error("No auth token present") }
	}

	private agentReturnFields() {
		
		let addressFields = "address { fullAddress streetNumber streetName unitNumber zip city state country }"
		let mlsFields = "mls { id contractedMls { id mlsName } } "
		let licenseFields = "license { id licenseNumber licenseRegion }"

		return `id firstName lastName fullName phone email link avatar { url } ${addressFields} ${mlsFields} ${licenseFields}`
	}

	private agentFields() {
		
		let addressFields = "address { fullAddress streetNumber streetName unitNumber zip city state country }"
		let mlsFields = "mls { id contractedMls { id mlsName } } "
		let licenseFields = "license { id licenseNumber licenseRegion }"

		return `agent { id firstName lastName fullName phone email link avatar { url } ${addressFields} ${mlsFields} ${licenseFields}}`
	}

	fetchAgent( link: string ) {

		this.checkAuth()

		let query = `query { agent( link: "${link}" ) { ${ this.agentReturnFields() } } }`
	  	let body = { "query" : query } 
	  	let options = { headers : { "Authorization" : `Bearer ${this.authorization?.token}`} }

	  	return this.http.post(this.graphApi, body, options )
	  		.pipe(
	  			catchError(ServiceErrors.handleError)
	  		)
	}



	removeLicense( license: License, agent: Agent ) {

		this.checkAuth()

		let query = `mutation { removeLicense( license: ${license.licenseNumber} agentId: ${agent.id} ) { ${ this.agentReturnFields() } } }`
	  	let body = { "query" : query } 
	  	let options = { headers : { "Authorization" : `Bearer ${this.authorization?.token}`} }

	  	return this.http.post(this.graphApi, body, options )
	  		.pipe(
	  			catchError(ServiceErrors.handleError)
	  		)
	}

	removeMls( mls: Mls, agent: Agent ) {

		this.checkAuth()

		let query = `mutation { removeMls( mls: ${mls.id} agentId: ${agent.id} ) { ${ this.agentReturnFields() } } }`
	  	let body = { "query" : query } 
	  	let options = { headers : { "Authorization" : `Bearer ${this.authorization?.token}`} }

	  	return this.http.post(this.graphApi, body, options )
	  		.pipe(
	  			catchError(ServiceErrors.handleError)
	  		)
	}

	updateAgent( agent: Agent ) {

		this.checkAuth()

		delete agent.picture
		delete agent['avatar']
		delete agent['mls']

		let agentInput: any = {}
		for ( var key in agent ) {
			agentInput[key] = agent[key]
		}

		// agentInput.mls = agentInput.mls.map( x => { return Number(x.id) })
		agentInput.license = agentInput.license.map( x => { return `{ licenseNumber: "${x.licenseNumber}", licenseRegion: "${x.licenseRegion}" }` } )
		// agentInput.address = agentInput.address.map( x => { return `{ fullAddress: "${x.fullAddress}", zip: ${x.zip}, city: "${x.city}", state: "${x.state}" }`})

		agentInput.address = agentInput.address.map(x => {
			console.log("x.zip is",x.zip);
			const zipCode = x.zip !== null && x.zip !== "" ? `${x.zip}` : '';
			const state = x.state !== null && x.state !== "" && x.state !== undefined? `${x.state}` : '';
			const city = x.city !== null && x.city !== "" && x.city !== undefined? `${x.city}` : '';
			return `{ fullAddress: "${x.fullAddress}", zip: "${zipCode}", city: "${city}", state: "${state}" }`;
		});

		agentInput.email = agentInput.email.toLowerCase();


		const agentUpdate = graphQlFormat(agentInput).join(' ')


		let query = `mutation { updateAgent( agent: { ${agentUpdate} } ) { id, usermls{contractedMls{id,mlsName}},${ this.agentFields() } } }`
		console.log("update agent query is",query);
	  	let body = { "query" : query } 
	  	let options = { headers : { "Authorization" : `Bearer ${this.authorization?.token}`} }

	  	return this.http.post(this.graphApi, body, options )
	  		.pipe(
	  			catchError(ServiceErrors.handleError)
	  		)
	}


	/**
 	 Create an entry for a listing agent, used when creating a showing request.
	*/
	addListAgent( listAgent: Agent ) {

		let query = `mutation { createListAgent( firstName: "${listAgent.firstName}" lastName: "${listAgent.lastName}" email: "${listAgent.email}" phone: "${listAgent.phone}" mls: ${listAgent.mls} ) { id } }`
	  	let body = { "query" : query } 
	  	let options = { headers : { "Authorization" : `Bearer ${this.authorization.token}`} }

	  	return this.http.post(this.graphApi, body, options )
	  		.pipe(
	  			catchError(ServiceErrors.handleError)
	  		)

	}

	changePassword( oldPassword, newPassword, agentEmail ) {

		const query = `mutation {
			changePassword(password: "${oldPassword}", newPassword: "${newPassword}", email: "${agentEmail}") {
			  message
			}
		  }`;		
		  console.log("query");
	  	let body = { "query" : query } 
	  	// let options = { headers : { "Authorization" : `Bearer ${this.authorization?.token}`} }

	  	return this.http.post(this.graphApi, body )
	  		.pipe(
	  			catchError(ServiceErrors.handleError)
	  		)
	}

	addMlsRequest( agent, orgId, mlsId) {
		this.checkAuth()
		let userId = this.loginService.user.id;
		let query = `mutation { addMlsRequest( name: "${agent.firstName}" email:"${agent.email}" phone:"${agent.phone}" userId:${userId} orgId:${orgId} mls:[${mlsId}] ) { message } }`
		// console.log("query is",query);
	  	let body = { "query" : query } 
	  	let options = { headers : { "Authorization" : `Bearer ${this.authorization.token}`} }

	  	return this.http.post(this.graphApi, body, options )
	  		.pipe(
	  			catchError(ServiceErrors.handleError)
	  		)

	}

}
